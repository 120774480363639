import { CircularProgress } from '@mui/material';
import { useTheme } from 'styled-components';

import { Container, LoadingContainer } from './styles';

export const SectionLoading = () => {
  const theme = useTheme();

  return (
    <Container>
      <LoadingContainer>
        <CircularProgress
          size={60}
          style={{ color: theme?.colors?.primary || 'black' }}
        />
      </LoadingContainer>
    </Container>
  );
};
