import { IconsProps } from '@flash-tecnologia/hros-web-ui-v2';

type TopicItems = {
  iconName: IconsProps['name'];
  title: string;
  text: string;
};

export const topicItems: TopicItems[] = [
  {
    iconName: 'IconId',
    title: 'Ao invés de e-mail, utilize o seu CPF como credencial.',
    text: 'A regra é a mesma para pessoas administradoras ou não administradoras: CPF e senha para acessar a plataforma.',
  },
  {
    iconName: 'IconKey',
    title: 'Se você é administrador, utilize a senha de administrador!',
    text: 'No campo de senha, pode digitar aquela mesma senha de admin que você já utilizava na plataforma antiga. E não se preocupe, os dados da sua empresa já foram migrados para a nova plataforma!',
  },
  {
    iconName: 'IconShieldLock',
    title: 'Mais segurança com a dupla autenticação',
    text: 'Após a etapa de CPF e senha, enviaremos por SMS um código de confirmação. Certifique-se de que possui acesso ao número de celular mostrado na tela.',
  },
  {
    iconName: 'IconBuildingSkyscraper',
    title: 'Escolha a empresa que deseja acessar',
    text: 'Após a etapa de autenticação, todas as empresas que você faz parte aparecerão em sua tela. Basta selecionar a empresa desejada e clicar em acessar.',
  },
];
