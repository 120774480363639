import {
  segmentEventTracking,
  segmentPageTracking,
} from '@flash-tecnologia/hros-web-utility';

export const setPageTracking = (
  name: string,
  params?: Record<string, string>,
) => {
  segmentPageTracking({
    name,
    params,
    module: 'authentication',
    businessUnit: 'platform',
  });
};

export const setEventTracking = (
  name: string,
  params?: Record<string, string>,
) => {
  segmentEventTracking({
    name,
    params: { ...params, flow: 'login' },
    module: 'authentication',
    businessUnit: 'platform',
  });
};
