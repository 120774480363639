import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  /* padding: 24px 40px; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  flex: 1;
  min-width: 450px;
  max-width: 700px;
  border-radius: 12px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.neutral.light.light2};
  @media screen and (max-width: 890px) {
    margin-top: 8px;
    min-width: 0px;
  }
  @media screen and (max-width: 576px) {
    padding: 0px;
    border: none;
  }
`;

export const Header = styled.div`
  padding: 24px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  width: 100%;

  @media screen and (max-width: 576px) {
    padding: 0px;
    border: none;
  }
`;

export const QuestionBadge = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary.light.light2};
  border-radius: 24px;
  padding: 4px 12px;
  align-items: center;
  justify-content: center;
  width: fit-content;
`;

export const QuestionBadgeText = styled(Typography).attrs({ variant: 'body4' })`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  }
`;

export const Body = styled.div`
  padding: 24px 40px;

  @media screen and (max-width: 576px) {
    padding: 24px 0px;
  }
`;

export const BodyText = styled(Typography).attrs({ variant: 'body3' })`
  && {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.neutral.dark.dark1};
  }
`;

export const AnswerContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  width: 100%;
  align-items: center;
  padding: 16px 24px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-radius: 8px;
  cursor: pointer;
`;

export const AnswerText = styled(Typography).attrs({ variant: 'body3' })`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.dark.dark1};
    margin-left: 20px;
  }
`;
