import AppRouter from './routes';
import { ThemeProvider } from '@flash-tecnologia/hros-web-ui-v2';
import { AuthContext } from './auth/AuthContext';
import { trpc, trpcClient, queryClient } from './api/client';
import '../src/i18n';

import { AuthProviderImpl } from './auth/AuthProviderImpl';
import { QueryClientProvider } from '@tanstack/react-query';
import { FlagsProvider } from '@flash-tecnologia/feature-flags';
import { APP_NAME } from './utils/constants';
import { env } from './lib/env';

export default function Root() {
  const auth = new AuthProviderImpl();
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <FlagsProvider
        url={env.UNLEASH_BENEFITS_URL}
        appName={APP_NAME}
        token={env.UNLEASH_BENEFITS_PROXY_KEY}
        refreshIntervalSeconds={600}
      >
        <QueryClientProvider client={queryClient}>
          <AuthContext.Provider value={auth}>
            <ThemeProvider>
              <AppRouter />
            </ThemeProvider>
          </AuthContext.Provider>
        </QueryClientProvider>
      </FlagsProvider>
    </trpc.Provider>
  );
}
