import styled from 'styled-components';
import { Typography, Button, Dot } from '@flash-tecnologia/hros-web-ui-v2';

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
  max-width: 568px;
  margin-bottom: ${({ theme }) => theme.spacings.m};
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.xs};
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacings.m};
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
  height: 100%;
  max-width: 420px;
  max-height: 400px;
  padding: ${({ theme }) => theme.spacings.s};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const TopicText = styled(Typography)`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledDot = styled(Dot)`
  flex-shrink: 0;
  margin-top: ${({ theme }) => theme.spacings.xs4};
`;

export const StyledButton = styled(Button)`
  margin-top: 100px;
  width: 100%;
  max-width: 240px;
`;
