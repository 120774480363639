import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacings.xs1} ${theme.spacings.s}`};
  background-color: ${({ theme }) => theme.colors.secondary[99]};
`;

const InfoContainer = styled.div`
  gap: ${({ theme }) => `${theme.spacings.xs1}`};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 50%;
`;

const TextButton = styled.a`
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary[50]};
`;
export { Container, IconContainer, InfoContainer, TextButton };
