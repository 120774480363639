import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  gap: 8px;

  @media screen and (max-width: 576px) {
    justify-content: center;
    margin-right: 0px;
  }
`;

export const ConfirmIdentityText = styled(Typography).attrs({
  variant: 'headline7',
})`
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ConfirmIdentityDescriptionText = styled(Typography).attrs({
  variant: 'body3',
})`
  && {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1366px;
  padding: 60px 136px 0 136px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
  flex: 1;

  @media screen and (max-width: 1120px) {
    padding: 60px 80px 0 80px;
  }
  @media screen and (max-width: 576px) {
    padding: 24px;
    margin-bottom: 0px;
  }
  @media screen and (max-width: 890px) {
    flex-direction: column;
  }
`;

export const Spacer = styled.div`
  flex: 1;
  max-width: 100px;
  max-height: 0px;
`;
