import * as SC from './styles';
import { Footer, Header } from '../../components/Common';
import { BigDataCorpFormInfo } from './BigDataCorpFormInfo';
import { FooterActions } from './FooterActions';
import { useMediaQuery } from '@flash-tecnologia/hros-web-ui-v2';
import { BigDataCorpForm } from './BigDataCorpForm';
import { usePageCustomFirstAccessQuiz } from './usePageCustomFirstAccessQuiz';

export const PageCustomFirstAccessQuiz = () => {
  const isMobileDevice = useMediaQuery('(max-width: 576px)');
  const {
    step,
    quiz,
    answers,
    isContinueButtonDisabled,
    handleOnClickNext,
    handleOnClickBack,
    handleOnSelectAnswer,
  } = usePageCustomFirstAccessQuiz();

  return (
    <SC.Container>
      <Header />
      <SC.ContentContainer>
        {!isMobileDevice && (
          <SC.TextsContainer>
            <SC.ConfirmIdentityText>
              Confirmação de identidade
            </SC.ConfirmIdentityText>

            <SC.ConfirmIdentityDescriptionText>
              Para alterar seus dados na Flash,
              <br />
              precisamos validar a sua identidade.
            </SC.ConfirmIdentityDescriptionText>
          </SC.TextsContainer>
        )}
        <SC.Spacer />

        {step === 0 ? (
          <BigDataCorpFormInfo />
        ) : (
          <BigDataCorpForm
            step={step}
            questions={quiz!.Questions}
            answers={answers}
            onChange={handleOnSelectAnswer}
          />
        )}
      </SC.ContentContainer>
      <FooterActions
        onClickNext={handleOnClickNext}
        onClickBack={handleOnClickBack}
        currentStep={step}
        isContinueButtonDisabled={isContinueButtonDisabled}
      />
      <Footer />
    </SC.Container>
  );
};
