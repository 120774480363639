import styled from 'styled-components';
import { ButtonBase } from '@mui/material';
import { Typography, PillButton } from '@flash-tecnologia/hros-web-ui-v2';

const CardWrapper = styled.div`
  display: flex;
  gap: 25px;
`;

const CardContainer = styled(ButtonBase)<{
  $deactivated?: boolean;
  selected: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ $deactivated, selected, theme }) => {
    if ($deactivated) return theme.colors.neutral[95];
    return selected
      ? theme.colors.secondary.light.light2
      : theme.colors.secondary.light.light1;
  }};
  padding: 32px;
  gap: 24px;
  text-align: left;
  border-radius: 8px 40px 8px 8px;
  border: 1px solid
    ${({ $deactivated, selected, theme }) => {
      if ($deactivated) return theme.colors.neutral[70];

      return selected
        ? theme.colors.primary
        : theme.colors.secondary.light.light4;
    }};
`;

const CardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  gap: 24px;
`;

const IconButtonStyled = styled(PillButton)<{ $deactivated?: boolean }>`
  && {
    &.MuiButtonBase-root {
      pointer-events: none;
      border-color: ${({ $deactivated, theme }) =>
        $deactivated
          ? theme.colors.neutral[70]
          : theme.colors.secondary.light.light4};

      svg {
        stroke: ${({ $deactivated, theme }) =>
          $deactivated ? theme.colors.neutral[50] : theme.colors.primary};
      }
    }
  }
`;

const Title = styled(Typography)<{ deactivated?: boolean }>`
  && {
    color: ${({ deactivated, theme }) =>
      deactivated ? theme.colors.neutral[50] : theme.colors.primary};
  }
`;

const SubTitle = styled(Typography)<{ deactivated?: boolean }>`
  && {
    color: ${({ deactivated, theme }) =>
      deactivated ? theme.colors.neutral[40] : theme.colors.neutral.dark.dark4};
    font-weight: 600;
  }
`;

const IconContainer = styled.div`
  align-self: center;
`;

export {
  CardContainer,
  CardContent,
  CardWrapper,
  IconContainer,
  IconButtonStyled,
  Title,
  SubTitle,
};
