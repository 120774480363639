import {
  Icons,
  LinkButton,
  useMediaQuery,
} from '@flash-tecnologia/hros-web-ui-v2';
import * as SC from './styles';

interface FooterActionsProps {
  onClickNext: () => void;
  onClickBack: () => void;
  currentStep: number;
  isContinueButtonDisabled: boolean;
}

export const FooterActions = ({
  onClickBack,
  onClickNext,
  currentStep,
  isContinueButtonDisabled,
}: FooterActionsProps) => {
  const isMobileDevice = useMediaQuery('(max-width: 576px)');

  return (
    <SC.Container>
      {!isMobileDevice && (
        <LinkButton
          onClick={onClickBack}
          style={{ alignSelf: 'center', marginRight: '16px' }}
          variant="neutral"
        >
          Cancelar
        </LinkButton>
      )}
      <SC.ButtonsContainer>
        {currentStep !== 0 &&
          (isMobileDevice ? (
            <LinkButton
              onClick={onClickBack}
              style={{ alignSelf: 'center' }}
              variant="default"
            >
              Voltar
            </LinkButton>
          ) : (
            <SC.StyledButton onClick={onClickBack} variant="secondary">
              <Icons name="IconArrowLeft" /> Voltar
            </SC.StyledButton>
          ))}
        <SC.StyledButton
          onClick={onClickNext}
          variant="primary"
          disabled={isContinueButtonDisabled}
        >
          Continuar <Icons name="IconArrowRight" />
        </SC.StyledButton>
      </SC.ButtonsContainer>
    </SC.Container>
  );
};
