import { SessionUserModel } from '@auth/models/SessionUserModel';
import { SessionUserAttributesMapper } from './SessionUserAttributesMapper';

export class SessionUserMapper {
  static toDomain(cognitoUser: any): SessionUserModel {
    return {
      tokenId: '',
      ...(cognitoUser.challengeName && {
        challenge: {
          name: cognitoUser.challengeName,
          codeDeliveryDestination:
            cognitoUser.challengeParam.CODE_DELIVERY_DESTINATION,
        },
      }),
      username: cognitoUser.username,
      attributes: cognitoUser.attributes
        ? SessionUserAttributesMapper.toDomain(cognitoUser.attributes)
        : {},
      _rawUser: cognitoUser,
    };
  }

  static toCognito(user: SessionUserModel) {
    return user._rawUser;
  }
}
