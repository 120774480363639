import { PageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import { useEffect, useState } from 'react';

export const MobileCaptchaPage = () => {
  const [captchaLoaded, setCaptchaLoaded] = useState(false);
  const wafWindow = window as any;

  useEffect(() => {
    if (document.getElementById('AwsWAFScript')) return;
    const script = document.createElement('script');
    script.src = process.env.AWS_CAPTCHA_SCRIPT_URL || '';
    script.async = true;
    script.onload = () => {
      setCaptchaLoaded(true);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (captchaLoaded) {
      const onCaptchaSuccess = async (token) => {
        wafWindow.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: 'CAPTCHA_SUCCESS', token: token }),
        );
      };

      const onCaptchaError = async (error) => {
        wafWindow.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: 'CAPTCHA_ERROR', error }),
        );
      };

      wafWindow.AwsWafCaptcha?.renderCaptcha(
        document.getElementById('idCaptchaContainer'),
        {
          onSuccess: (token) => {
            onCaptchaSuccess(token);
          },
          onError: (error) => {
            onCaptchaError(error);
          },
          defaultLocale: 'pt-BR',
          skipTitle: true,
          apiKey: process.env.AWS_CAPTCHA_TOKEN,
          dynamicWidth: true,
        },
      );
    }
  }, [captchaLoaded]);

  return (
    <PageContainer>
      <div id="idCaptchaContainer"></div>
    </PageContainer>
  );
};
