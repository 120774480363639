import { useAuthContext } from '@auth/index';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

export const PageSignOut = () => {
  const auth = useAuthContext();
  useEffect(() => {
    localStorage.clear();

    (async () => {
      await auth.signOut();
    })();
  }, [auth]);

  return <Navigate to="/authentication/login" replace={true} />;
};
