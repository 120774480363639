import {
  MonitoringErrorBoundary,
  MonitoringManager,
} from '@flash-tecnologia/hros-web-utility';
import { MonitoringLevel } from '@flash-tecnologia/hros-web-utility/dist/monitoring/MonitoringLevel';

export const projectDsn =
  'https://a9ca281a13be4148acd67a373759a6ae@o266934.ingest.sentry.io/4505603193503744';

export class ErrorBoundary extends MonitoringErrorBoundary {
  project: string = projectDsn;

  constructor(props) {
    super(props);
  }

  static captureException(
    error: Error,
    severity?: MonitoringLevel,
    extras?: Record<string, any>,
  ) {
    MonitoringManager.captureException(projectDsn, error, severity, extras);
  }

  static captureMessage(
    message: string,
    severity?: MonitoringLevel,
    extras?: Record<string, any>,
  ) {
    MonitoringManager.captureMessage(projectDsn, message, severity, extras);
  }

  static identifyUser(id: string) {
    MonitoringManager.identifyUser(id);
  }

  componentDidCatch(error: any) {
    MonitoringManager.captureException(this.project, error);
  }
}
