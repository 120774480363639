import { QueryClient } from '@tanstack/react-query';
import { createTRPCReact, httpLink } from '@trpc/react-query';
import type { AppRouter } from 'bff';
import { AuthProviderImpl } from '@auth/AuthProviderImpl';

export const trpc = createTRPCReact<AppRouter>();
const auth = new AuthProviderImpl();

export const queryClient = new QueryClient();
export const trpcClient = trpc.createClient({
  links: [
    httpLink({
      headers: async () => {
        try {
          const { idToken } = await auth.currentSession();
          return {
            Authorization: `Bearer ${idToken}`,
          };
        } catch {
          return {};
        }
      },
      url: `${process.env.BFF_URL}/trpc` || '',
    }),
  ],
});
