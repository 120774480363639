import { ShapeIcon } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  max-width: 650px;
  margin: 0 auto 40px;
  padding: 100px 160px 50px;
  border-radius: 8px;
  border: 1px solid var(--neutral-neutral-90, #ebe6e9);

  @media screen and (max-width: 900px) {
    padding: 80px 70px 30px;
  }
`;

export const TextContainer = styled.div`
  max-width: 330px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const StyledShapeIcon = styled(ShapeIcon)`
  position: absolute;
  top: -36px;
  padding: 8px;
  stroke-width: 1.2;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0px;

  @media screen and (max-width: 420px) {
    flex-direction: column-reverse;
    gap: 20px;
  }
`;
