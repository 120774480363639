import { Icons, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { Footer } from '../../../../components/Common';
import { Header } from '../../../../components/Common/Header';
import {
  PageContainer,
  Wrapper,
  LeftContainer,
  RightContainer,
  ContentContainer,
  GreetingsText,
  Title,
  SubTitle,
  TextsContainer,
  ButtonsContainer,
  StyledButton,
} from '../styles';
import VerifyEmail from '../../../../assets/verify_email.png';
import { useLoginContext } from '../../loginContext';
import { useAuthContext } from '@auth/hooks/useAuthContext';
import { useAuthNavigation } from 'src/routes';

export const VerifyDataLandingStep = () => {
  const navigate = useAuthNavigation();
  const { user, setStep, setUser, adminHasToVerifyEmail } = useLoginContext();
  const auth = useAuthContext();

  const onGoBack = () => {
    auth.signOut();
    setStep('PASSWORD');
    setUser(null);
  };

  const onConfirm = () => {
    if (adminHasToVerifyEmail) {
      setStep('VERIFY_EMAIL');
    } else if (!user?.attributes?.phoneNumber) {
      setStep('UPDATE_PHONE_NUMBER');
    } else if (!user?.attributes.phoneNumberVerified) {
      setStep('VERIFY_PHONE_NUMBER');
    } else {
      navigate('ACCESS_SELECTION');
    }
  };

  return (
    <PageContainer>
      <Header />
      <ContentContainer>
        <Wrapper>
          <LeftContainer style={{ height: '100%' }}>
            <TextsContainer>
              <GreetingsText variant="body3">Boas-vindas</GreetingsText>
              <Title variant="headline5">
                Só faltam alguns passos para você aproveitar a nova plataforma
                da Flash!
              </Title>
              <SubTitle variant="body3">
                Você está a alguns cliques de facilitar ainda mais a sua rotina
                de trabalho.
              </SubTitle>
              <SubTitle variant="body3" style={{ marginTop: '8px' }}>
                Aproveite seu novo acesso para manter seu cadastro atualizado!
                Para sua segurança e da sua empresa, a seguir{' '}
                <strong>
                  vamos confirmar alguns dos seus dados cadastrais
                </strong>
                , como e-mail e número de celular.
              </SubTitle>
            </TextsContainer>
            <div style={{ flexGrow: 1 }} />
            <ButtonsContainer>
              <LinkButton variant="default" onClick={onGoBack}>
                Sair
              </LinkButton>
              <StyledButton variant="primary" size="large" onClick={onConfirm}>
                Continuar <Icons name="IconArrowRight" />
              </StyledButton>
            </ButtonsContainer>
          </LeftContainer>
          <RightContainer imageUrl={VerifyEmail} />
        </Wrapper>
      </ContentContainer>
      <Footer />
    </PageContainer>
  );
};
