import LogoFlash from '../../../../assets/logo_flash.svg';

import { Container, Content, ProgressBar } from './styles';
import { Stepper } from '@flash-tecnologia/hros-web-ui-v2';
interface HeaderProps {
  progress?: string | number;
  hasOptions?: boolean;
  activeStep?: number;
  steps?: string[];
  hasMenu?: boolean;
}

export const Desktop = ({ progress = 0, activeStep, steps }: HeaderProps) => {
  return (
    <Container>
      <Content>
        <LogoFlash />
        {steps && <Stepper activeStep={activeStep} steps={steps} />}
      </Content>
      <ProgressBar width={progress}></ProgressBar>
    </Container>
  );
};
