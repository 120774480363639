import styled, { css } from 'styled-components';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';

export const AccessContainer = styled.div<{
  selected?: boolean;
  deactivated?: boolean;
  $hidden?: boolean;
  secondary?: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ secondary }) => (secondary ? '16px 20px' : '24px')};
  gap: 12px;
  background-color: ${({
    secondary,
    deactivated,
    selected,
    $hidden,
    theme,
  }) => {
    if (deactivated) return theme.colors.neutral[95];
    if ($hidden) return theme.colors.neutral[95];
    const color = secondary
      ? theme.colors.secondary[99]
      : theme.colors.secondary[100];
    return selected ? theme.colors.secondary.light.light2 : color;
  }};
  border-radius: 12px;
  border: 1px solid
    ${({ secondary, deactivated, selected, theme }) => {
      if (deactivated) return theme.colors.neutral[70];
      const color = secondary
        ? theme.colors.secondary[95]
        : theme.colors.neutral[90];
      return selected ? theme.colors.primary : color;
    }};
`;

export const ItemContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const GenericProfileStyleWrapper = styled.div<{
  $selected?: boolean;
  $disabled?: boolean;
}>`
  && > div > div {
    ${({ $disabled, theme }) =>
      $disabled &&
      css`
        border: 1px solid ${theme.colors.neutral[70]};
        background-color: ${theme.colors.neutral[95]};
        color: ${theme.colors.secondary[90]};
      `}
    ${({ $selected, theme }) =>
      $selected &&
      css`
        border: 1px solid ${theme.colors.secondary[50]};
      `}
  }
  && span {
    ${({ $disabled, theme }) =>
      $disabled &&
      css`
        color: ${theme.colors.neutral[50]};
      `}
  }
`;

const Title = styled(Typography)<{ deactivated?: boolean }>`
  color: ${({ deactivated, theme }) =>
    deactivated ? theme.colors.neutral[50] : theme.colors.primary};
`;

const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]}
  font-weight: 600;
`;

const IconContainer = styled.div`
  align-self: center;
`;

export { IconContainer, Title, SubTitle };
