import {
  GenericProfilePicture,
  PillButton,
  Tooltip,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useState } from 'react';
import { GenericProfileStyleWrapper } from '../../access-item.styles';
import { AccessGroup, AccessOption } from '../../../../../../types';

type IconButtonProps = {
  name: string;
  hidden: boolean;
  companyId: string;
  selectedAccess?: AccessOption;
  onToggleHide: () => void;
  toolTipText: string;
  accessGroup?: AccessGroup;
};

export const HidableIconButton = ({
  name,
  hidden,
  companyId,
  selectedAccess,
  onToggleHide,
  toolTipText,
  accessGroup,
}: IconButtonProps) => {
  const [isCompanyIconHovered, setIsCompanyIconHovered] = useState(false);

  const onCompanyIconHover = () =>
    !selectedAccess && setIsCompanyIconHovered(true);
  const onCompanyIconLeave = () =>
    !selectedAccess && setIsCompanyIconHovered(false);

  return (
    <div
      data-onboarding="authentication-access-hidable-icon"
      onMouseEnter={onCompanyIconHover}
      onMouseLeave={onCompanyIconLeave}
      style={{ display: 'flex', alignSelf: 'flex-start' }}
    >
      {isCompanyIconHovered && !selectedAccess && (
        <Tooltip title={toolTipText}>
          <span>
            <PillButton
              onClick={onToggleHide}
              variant="pink"
              size="small"
              icon={hidden ? 'IconEye' : 'IconEyeOff'}
            />
          </span>
        </Tooltip>
      )}
      {!isCompanyIconHovered && !accessGroup && (
        <GenericProfileStyleWrapper
          $selected={selectedAccess?.companyId === companyId}
          $disabled={!!selectedAccess && selectedAccess.companyId !== companyId}
        >
          <GenericProfilePicture name={name} size={40} />
        </GenericProfileStyleWrapper>
      )}
      {!isCompanyIconHovered && accessGroup && (
        <div>
          <PillButton
            onClick={onToggleHide}
            variant="pink"
            size="small"
            icon={'IconBuildingCommunity'}
          />
        </div>
      )}
    </div>
  );
};
