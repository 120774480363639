import { useFormik } from 'formik';
import * as yup from 'yup';
import { dispatchToast } from '@utils';
import { useAuthContext } from '@auth/hooks/useAuthContext';
import { useState } from 'react';
import { useLoginContext } from '../../loginContext';
import { useAuthNavigation } from 'src/routes';
import { setInLS } from '@flash-tecnologia/hros-web-utility';
import { setEventTracking } from '@utils/analytics/segment';
import { hideDocumentNumber } from '@utils/masks/hideDocumentNumber';
import { trpc } from '@/api/client';

const validationSchema = yup.object({
  otp: yup.string().min(6).required('Favor preencher campos!'),
});

export const useMFAForm = () => {
  const auth = useAuthContext();
  const { user, setUser } = useLoginContext();
  const navigate = useAuthNavigation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const form = useFormik({
    initialValues: {
      otp: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);

      try {
        if (!user) return;
        setEventTracking('signin_loginmfa_submit_button_cliked', {
          code: values.otp,
          document_number: hideDocumentNumber(user.username),
        });
        const authenticatedUser = await auth.confirmSignIn({
          code: values.otp,
          mfaType: 'SMS_MFA',
          user,
        });
        setUser(authenticatedUser);
        // TODO: Rever se faz sentido salvar no LS ou se devemos deprecar
        const userAuth = {
          username: authenticatedUser._rawUser.username,
          attributes: authenticatedUser._rawUser.attributes,
          tokenId:
            authenticatedUser._rawUser.signInUserSession?.idToken?.jwtToken ||
            '',
        };
        setInLS({
          key: 'userAuth',
          value: userAuth,
        });
        dispatchEvent(new CustomEvent('user_authenticated'));
        navigate('ACCESS_SELECTION');
      } catch (error) {
        dispatchToast({
          type: 'error',
          content: error.message,
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  return { form, isLoading };
};

export const useResendMFACode = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { credentials, setUser } = useLoginContext();
  const auth = useAuthContext();
  const updateMfaMethod = trpc.updateMfaMethod.useMutation();

  const resendCode = async (mfaMethod: 'sms' | 'whatsapp') => {
    setIsLoading(true);
    try {
      await updateMfaMethod.mutateAsync({
        userIdentifier: credentials.username,
        mfaMethod,
      });
      const user = await auth.signIn(credentials);
      setEventTracking('signin_loginmfa_resendcode_button_cliked', {
        document_number: hideDocumentNumber(user.username),
      });
      setUser(user);
    } catch (error) {
      dispatchToast({
        type: 'error',
        content: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, resendCode };
};
