import styled from 'styled-components';
import { Icons, TextField } from '@flash-tecnologia/hros-web-ui-v2';
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 48px;
  max-width: 700px;
`;

export const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
  height: 48px;
  width: 95%;
  gap: 10px;
  border: ${(props) => props.theme.borders.width.xs2} solid
    ${(props) => props.theme.colors.neutral[90]};
  border-radius: 150px;
  &:before,
  &:after {
    content: none;
  }
`;

export const SearchIcon = styled(Icons)`
  margin-left: 16px;
  fill: transparent;
  circle,
  line {
    stroke: ${(props) => props.theme.colors.neutral[70]};
  }
`;

export const SearchInput = styled(TextField)`
  width: 100%;
  gap: 8px;
  color: ${(props) => props.theme.colors.neutral[30]};
  font-weight: 600;

  & .MuiInputBase-root {
    border: none !important;
  }

  & .MuiInputBase-input {
    padding: 14px 0;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  max-width: 267px;
  padding-bottom: 12px;
`;
