import { useAuthContext } from '@auth/hooks/useAuthContext';
import { parseLogin } from '@utils';
import { useLoginContext } from 'src/pages/Login/loginContext';
import { setInLS } from '@flash-tecnologia/hros-web-utility';
import { SessionUserModel } from '@auth/models/SessionUserModel';

export const useLogin = () => {
  const { setCredentials, setUser } = useLoginContext();
  const auth = useAuthContext();

  const setUserInLS = (user: SessionUserModel) => {
    const userAuth = {
      username: user._rawUser?.username || '',
      attributes: user._rawUser?.attributes || null,
      tokenId: user._rawUser.signInUserSession?.idToken?.jwtToken || '',
    };
    setInLS({
      key: 'userAuth',
      value: userAuth,
    });
  };

  const login = async ({
    login,
    password,
  }: {
    login: string;
    password: string;
  }) => {
    const parsedLogin = parseLogin({ value: login });
    setCredentials({ username: parsedLogin, password });
    const user = await auth.signIn({
      username: parsedLogin,
      password: password,
    });
    setUser(user);
    setUserInLS(user); // TODO: Rever se faz sentido ou se devemos deprecar

    return user;
  };

  return { login };
};
