import { ShapeIcon, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Container, SearchResultsText } from './styles';

export const SearchEmptyState = () => {
  return (
    <Container>
      <ShapeIcon name="IconListSearch" variant="neutral" size={32} />
      <Typography
        variant="body4"
        weight={700}
        variantColor="var(--color-neutral-40)"
        style={{ padding: '0 24px' }}
      >
        Não encontramos resultados para a sua busca
      </Typography>
      <SearchResultsText
        variant="caption"
        variantColor="var(--color-neutral-40)"
        style={{ padding: '0 12px' }}
      >
        Verifique se o texto digitado corresponde ao nome de alguma empresa em
        que você possua conta como colaborador.
      </SearchResultsText>
    </Container>
  );
};
