import { useMemo } from 'react';

import { useMediaQuery } from '@flash-tecnologia/hros-web-ui-v2';
import { Mobile } from './Mobile';
import { Desktop } from './Desktop';
interface HeaderProps {
  progress?: string | number;
  hasOptions?: boolean;
  activeStep?: number;
  steps?: string[];
  hasMenu?: boolean;
}

export const Header = ({
  progress = 0,
  activeStep,
  steps,
  hasMenu,
}: HeaderProps) => {
  const isMobile = useMediaQuery('(max-width: 900px)');

  const HeaderRender = useMemo(
    () =>
      isMobile ? (
        <Mobile
          hasMenu={hasMenu}
          progress={progress}
          activeStep={activeStep}
          steps={steps}
        />
      ) : (
        <Desktop
          hasMenu={hasMenu}
          progress={progress}
          activeStep={activeStep}
          steps={steps}
        />
      ),
    [isMobile, activeStep, progress, hasMenu, steps],
  );

  return HeaderRender;
};
