import { TextField, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { Footer } from '../../components/Common';
import { Header } from '../../components/Common/Header';
import {
  PageContainer,
  ContentsContainer,
  Wrapper,
  LeftContainer,
  RightContainer,
  TextsContainer,
  CreateAccountText,
  Title,
  SubTitle,
  ContentContainer,
  ButtonsContainer,
  LinkButtonStyled,
  StyledButton,
  FieldContainer,
} from './styles';
import Forgot_Password from '../../assets/forgot_password.png';
import { useResendInvitationForm } from './hook';
import { ResendConfirmationSuccessMessage } from './components/SuccessMessage/SuccessMessage';
import { useAuthNavigation } from 'src/routes';

export const PageResendInvite = () => {
  const navigate = useAuthNavigation();

  const { form, isLoading, success } = useResendInvitationForm();

  if (success) {
    return <ResendConfirmationSuccessMessage />;
  }

  return (
    <PageContainer>
      <Header />
      <ContentsContainer>
        <Wrapper>
          <LeftContainer>
            <TextsContainer>
              <CreateAccountText variant="body3">
                Primeira vez usando Flash
              </CreateAccountText>
              <Title variant="headline5">Reenvio do convite de acesso</Title>
              <SubTitle variant="body3">
                Para receber novamente o convite de acesso da sua empresa,
                insira seu CPF abaixo. Caso a sua empresa já tenha te
                cadastrado, você irá receber uma comunicação via e-mail ou SMS.
              </SubTitle>
            </TextsContainer>
            <form onSubmit={form.handleSubmit}>
              <ContentContainer>
                <FieldContainer>
                  <TextField
                    label="Insira seu CPF"
                    id={'cpfNumber'}
                    imaskProps={{ mask: '000.000.000-00' }}
                    name={'cpfNumber'}
                    value={form.values.cpfNumber}
                    onChange={form.handleChange}
                    error={
                      form.touched.cpfNumber && Boolean(form.errors.cpfNumber)
                    }
                    helperText={form.touched.cpfNumber && form.errors.cpfNumber}
                  />
                </FieldContainer>
              </ContentContainer>
              <ButtonsContainer>
                <LinkButtonStyled
                  variant="default"
                  onClick={() => navigate('LOGIN')}
                >
                  Voltar
                </LinkButtonStyled>
                <StyledButton
                  loading={isLoading}
                  variant="primary"
                  size="large"
                  type="submit"
                >
                  Continuar <Icons name="IconArrowRight" />
                </StyledButton>
              </ButtonsContainer>
            </form>
          </LeftContainer>
          <RightContainer imageUrl={Forgot_Password} />
        </Wrapper>
      </ContentsContainer>
      <Footer />
    </PageContainer>
  );
};
