import { useState } from 'react';
import { Header, Footer } from '@components/Common';
import { ContentContainer, PageContainer } from './styles';
import { PasswordRecoveryContext } from './passwordRecoveryContext';
import { UsernameInputStep } from './components/UsernameInputStep';
import { RECOVERY_CHANNEL } from '@auth/IAuthProvider';
import { ChooseRecoveryChannelStep } from './components/ChooseRecoveryChannelStep';
import { NewPasswordStep } from './components/NewPasswordStep';
import { CodeDeliveryDetails } from '@/auth/models/CodeDeliveryDetails';

export const PagePasswordRecovery = () => {
  const [step, setStep] = useState<number>(0);
  const [username, setUsername] = useState<string>('');
  const [recoveryChannel, setRecoveryChannel] =
    useState<RECOVERY_CHANNEL>('EMAIL');
  const [deliveryDetails, setDeliveryDetails] = useState<CodeDeliveryDetails>({
    deliveryChannel: undefined,
    destination: undefined,
  });

  return (
    <PasswordRecoveryContext.Provider
      value={{
        step,
        setStep,
        username,
        setUsername,
        setRecoveryChannel,
        recoveryChannel,
        deliveryDetails,
        setDeliveryDetails,
      }}
    >
      <PageContainer>
        <Header />
        <ContentContainer>{renderStep(step)}</ContentContainer>
        <Footer />
      </PageContainer>
    </PasswordRecoveryContext.Provider>
  );
};

const renderStep = (step) => {
  switch (step) {
    case 0:
      return <UsernameInputStep />;
    case 1:
      return <ChooseRecoveryChannelStep />;
    case 2:
      return <NewPasswordStep />;
  }
};
