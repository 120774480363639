import styled from 'styled-components';

export const ModalDescription = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: ${({ theme }) => theme.spacings.xs4};

  padding-left: ${({ theme }) => theme.spacings.xl4};
  padding-right: ${({ theme }) => theme.spacings.xl4};

  p {
    color: ${({ theme }) => theme.colors.neutral50};
  }
  margin-top: 8px;
`;

export const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacings.xl4};
  padding-right: ${({ theme }) => theme.spacings.xl4};
  padding-bottom: 10px;
  gap: 4px;
`;

export const ModalImage = styled.div<{ imageUrl: string }>`
  background-image: url(${({ imageUrl }) => imageUrl});
  width: 450px;
  height: 220px;
  min-width: 400px;
  margin: 10px 80px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;
