import {
  TextField,
  PasswordField,
  Icons,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  StepWrapper,
  LeftContainer,
  RightContainer,
  TextsContainer,
  CreateAccountText,
  Title,
  SubTitle,
  ContentContainer,
  ButtonsContainer,
  LinkButtonStyled,
  StyledButton,
  FieldContainer,
} from './styles';
import CreateAccount from '../../../../assets/create_account.png';
import { useCreateAccountForm } from './hooks';
import { useFirstAccessContext } from '../../firstAccessContext';

export const CreateAccountStep = () => {
  const { setStep, step } = useFirstAccessContext();
  const { form, isLoading } = useCreateAccountForm();

  return (
    <StepWrapper>
      <LeftContainer>
        <TextsContainer>
          <CreateAccountText variant="body3">Criar conta</CreateAccountText>
          <Title variant="headline5">
            Para começar, informe seus dados de acesso
          </Title>
          <SubTitle variant="body3">
            Você irá usar os dados abaixo para acessar sua conta Flash em todas
            as empresas em que você tiver nossos benefícios.
          </SubTitle>
        </TextsContainer>
        <form onSubmit={form.handleSubmit}>
          <ContentContainer>
            <FieldContainer>
              <TextField
                id={'name'}
                name={'name'}
                label="Nome Completo"
                autoComplete="off"
                value={form.values.name}
                onChange={form.handleChange}
                error={form.touched.name && Boolean(form.errors.name)}
                helperText={form.touched.name && form.errors.name}
              />
            </FieldContainer>
            <FieldContainer>
              <TextField
                id={'documentNumber'}
                name={'documentNumber'}
                imaskProps={{
                  mask: '000.000.000-00',
                }}
                label="CPF"
                autoComplete="off"
                value={form.values.documentNumber}
                onChange={form.handleChange}
                error={
                  form.touched.documentNumber &&
                  Boolean(form.errors.documentNumber)
                }
                helperText={
                  form.touched.documentNumber && form.errors.documentNumber
                }
              />
            </FieldContainer>
            <FieldContainer>
              <TextField
                id={'email'}
                name={'email'}
                label="E-mail (opcional)"
                autoComplete="off"
                helperText={
                  form.touched.email
                    ? form.errors.email
                    : 'Dica: insira um e-mail pessoal, pois essa conta poderá ser usada para várias empresas'
                }
                value={form.values.email}
                onChange={form.handleChange}
                error={form.touched.email && Boolean(form.errors.email)}
              />
            </FieldContainer>
            <FieldContainer>
              <TextField
                id={'phoneNumber'}
                name={'phoneNumber'}
                label="Celular"
                autoComplete="off"
                value={form.values.phoneNumber}
                onChange={form.handleChange}
                imaskProps={{
                  mask: '(00) 00000-0000',
                }}
                error={
                  form.touched.phoneNumber && Boolean(form.errors.phoneNumber)
                }
                helperText={form.touched.phoneNumber && form.errors.phoneNumber}
              />
            </FieldContainer>
            <FieldContainer>
              <PasswordField
                id={'password'}
                name={'password'}
                label="Crie sua senha"
                isCreatePassword
                autoComplete="off"
                value={form.values.password}
                onChange={form.handleChange}
                error={form.touched.password && Boolean(form.errors.password)}
              />
            </FieldContainer>
            <FieldContainer>
              <PasswordField
                id="passwordConfirmation"
                name="passwordConfirmation"
                label="Confirme sua senha"
                autoComplete="off"
                value={form.values.passwordConfirmation}
                onChange={form.handleChange}
                error={
                  form.touched.passwordConfirmation &&
                  Boolean(form.errors.passwordConfirmation)
                }
                helperText={
                  form.touched.passwordConfirmation &&
                  form.errors.passwordConfirmation
                }
              />
            </FieldContainer>
          </ContentContainer>
          <ButtonsContainer>
            <LinkButtonStyled
              variant="default"
              onClick={() => setStep(step - 1)}
            >
              Voltar
            </LinkButtonStyled>
            <StyledButton
              variant="primary"
              size="large"
              type="submit"
              disabled={isLoading}
              loading={isLoading}
            >
              Continuar <Icons name="IconArrowRight" />
            </StyledButton>
          </ButtonsContainer>
        </form>
      </LeftContainer>
      <RightContainer imageUrl={CreateAccount} />
    </StepWrapper>
  );
};
