import * as SC from './styles';
import { BigDataCorpQuiz } from '../../../api';
import { Radio } from '@mui/material';

interface BigDataCorpFormProps {
  step: number;
  questions: BigDataCorpQuiz['Questions'];
  onChange: (value: string) => void;
  answers: string[];
}

export const BigDataCorpForm = ({
  step,
  questions,
  answers,
  onChange,
}: BigDataCorpFormProps) => {
  return (
    <SC.Container>
      <SC.Header>
        <SC.QuestionBadge>
          <SC.QuestionBadgeText>
            Pergunta {step}/{questions.length}
          </SC.QuestionBadgeText>
        </SC.QuestionBadge>
      </SC.Header>

      <SC.Body>
        <SC.BodyText>{questions[step - 1].Question}</SC.BodyText>
        {questions[step - 1].Answers.map((option) => (
          <SC.AnswerContainer key={option} onClick={() => onChange(option)}>
            <Radio
              checked={answers[step - 1] === option}
              onChange={() => onChange(option)}
              style={{ margin: 0, padding: 0 }}
            />
            <SC.AnswerText>{option}</SC.AnswerText>
          </SC.AnswerContainer>
        ))}
      </SC.Body>
    </SC.Container>
  );
};
