import styled from 'styled-components';
import { ButtonBase } from '@mui/material';
import { Typography, PillButton } from '@flash-tecnologia/hros-web-ui-v2';

const CardContainer = styled(ButtonBase)<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ selected, theme }) =>
    selected
      ? theme.colors.secondary.light.light2
      : theme.colors.secondary.light.light1};
  padding: 32px;
  gap: 8px;
  text-align: left;
  border-radius: 8px 40px 8px 8px;
  border: 1px solid
    ${({ selected, theme }) =>
      selected ? theme.colors.primary : theme.colors.secondary.light.light4};
`;

const IconButtonStyled = styled(PillButton)`
  && {
    &.MuiButtonBase-root {
      pointer-events: none;
      border-color: ${({ theme }) => theme.colors.secondary.light.light4};

      svg {
        stroke: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

const Title = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const SubTitle = styled(Typography)<{ maxWidth: string }>`
  && {
    color: ${({ theme }) => theme.colors.neutral.dark.dark4};
    font-weight: 600;
    max-width: ${({ maxWidth }) => maxWidth};
  }
`;

export { CardContainer, IconButtonStyled, Title, SubTitle };
