import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1366px;
  padding: 60px 136px 0 136px;
  width: 100%;
  margin: 0 auto;
  flex: 1;

  @media screen and (max-width: 992px) {
    padding: 60px 80px 0 80px;
  }
  @media screen and (max-width: 576px) {
    padding: 60px 24px 0 24px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  @media screen and (max-width: 1200px) {
    display: block;
    width: 100%;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 620px;
  width: 100%;
  margin-bottom: 40px;

  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }
`;

export const RightContainer = styled.div<{ imageUrl: string }>`
  background-image: url(${({ imageUrl }) => imageUrl});
  width: 420px;
  height: 421px;
  min-width: 400px;
  border-radius: 0px 128px;
  margin-left: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 1200px) {
    height: 0px;
  }
`;
