import { dispatchToast } from '@utils';
import { useCallback, useEffect, useState } from 'react';
import { setEventTracking } from '@utils/analytics/segment';
import { getFromSS, setInSS } from '@flash-tecnologia/hros-web-utility';
import { dayjs } from '@flash-tecnologia/hros-web-ui-v2';
import { trpc } from '@api/client';
import { useLoginContext } from 'src/pages/Login/loginContext';

const TIMER_DURATION = 60;

export const useResendVerifyEmailCode = () => {
  const [initialTimer, setInitialTimer] = useState<number | undefined>(
    undefined,
  );
  const [email, setEmail] = useState<string | undefined>(undefined);
  const { credentials } = useLoginContext();
  const { mutateAsync: sendAdminFirstAccessToken, isLoading } =
    trpc.sendAdminFirstAccessToken.useMutation();

  const resendCode = useCallback(async () => {
    try {
      setInitialTimer(TIMER_DURATION);
      const sentTo = await sendAdminFirstAccessToken({
        cpf: credentials.username,
      });
      setEmail(sentTo);
      setInSS({
        key: 'confirmEmailSentDate',
        value: dayjs().toISOString(),
      });
      setEventTracking('signin_confirm_email_resendcode_button_cliked');
    } catch (error) {
      dispatchToast({
        type: 'error',
        content: error.message,
      });
    }
  }, [credentials.username, sendAdminFirstAccessToken]);

  useEffect(() => {
    const sendInitialCode = async () => {
      const startedData = getFromSS('confirmEmailSentDate');
      const now = dayjs();
      const startedDate = startedData ? dayjs(startedData) : now;
      const diffSeconds = Math.floor(
        (now.toDate().getTime() - startedDate.toDate().getTime()) / 1000,
      );

      if (diffSeconds > TIMER_DURATION || diffSeconds === 0) {
        setInitialTimer(TIMER_DURATION);
        setInSS({
          key: 'confirmEmailSentDate',
          value: dayjs().toISOString(),
        });
        const sentTo = await sendAdminFirstAccessToken({
          cpf: credentials.username,
        });
        setEmail(sentTo);
      } else {
        setInitialTimer(TIMER_DURATION - diffSeconds);
      }
    };

    sendInitialCode();
  }, [credentials.username, sendAdminFirstAccessToken]);

  return { initialTimer, isLoading, email, resendCode };
};
