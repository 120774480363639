import { dispatchToast, validateEmail } from '@/utils';
import { setEventTracking } from '@/utils/analytics/segment';
import { FormikErrors, useFormik } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';
import { useDynamicSSO } from '@flash-tecnologia/flashstage-auth';

const validationSchema = yup.object({
  email: yup.string().required('Favor digitar o email.'),
});

export const useSSOForm = () => {
  const sso = useDynamicSSO();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const form = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,

    validate: async (values) => {
      const errors: FormikErrors<typeof values> = {};
      if (!validateEmail(values.email)) {
        errors.email = 'Favor digitar um email válido';
      }

      return errors;
    },

    onSubmit: async (values) => {
      setIsLoading(true);
      const domain = values.email.split('@').at(1) ?? '';
      setEventTracking('signin_sso_submit_button_clicked', {
        email_domain: domain,
      });
      const error = await sso.signIn('flashos', domain);
      if (error) {
        dispatchToast({
          type: 'error',
          content: 'Não foi possível encontrar um Login SSO com este email.',
        });
        setIsLoading(false);
      }
    },
  });

  return { form, isLoading };
};
