import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { cognitoConfig } from '@/auth/cognito/CognitoConfig';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { useDynamicSSO } from '@flash-tecnologia/flashstage-auth';
import { setEventTracking } from '@/utils/analytics/segment';

export const PageExchangeSSOCode = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const sso = useDynamicSSO();

  useEffect(() => {
    async function exchangeTokens() {
      try {
        const ssoToken = params.get('code');
        const state = sso.getRedirectState<{
          ssoProviderName: string;
          redirectTo: string;
        }>();

        if (!ssoToken || !state) {
          return navigate('/authentication/login');
        }

        const tokens = await sso.retrieveSignInTokens();
        if (!tokens) {
          return navigate('/authentication/login');
        }
        setEventTracking('signin_sso_tokens_retrieved');

        // Manually set cognito session
        await setCognitoSession(tokens);

        const searchParams = buildSearchParams(state);

        navigate(`/authentication/access-selection?${searchParams.toString()}`);
      } catch (error) {
        navigate('/authentication/login');
      }
    }
    exchangeTokens();
  }, [params]);

  function buildSearchParams(state: {
    providerName: string;
    redirectTo: string;
  }): URLSearchParams {
    const searchParams = new URLSearchParams();
    state.providerName &&
      searchParams.append(
        'ssoProviderName',
        state.providerName.replace('flashos-', ''),
      );
    state.redirectTo &&
      searchParams.append('redirectTo', encodeURIComponent(state.redirectTo));
    return searchParams;
  }

  async function setCognitoSession(tokens: {
    idToken: string;
    accessToken: string;
    refreshToken: string;
  }): Promise<void> {
    const AccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
      AccessToken: tokens.accessToken,
    });

    const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
      IdToken: tokens.idToken,
    });

    const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
      RefreshToken: tokens.refreshToken,
    });

    const sessionData = {
      IdToken: IdToken,
      AccessToken: AccessToken,
      RefreshToken: RefreshToken,
    };

    // create the CognitoUserSession using the sessionData
    const session = new AmazonCognitoIdentity.CognitoUserSession(sessionData);

    const poolData = {
      UserPoolId:
        cognitoConfig[process.env.BUILD_ENV || 'staging'].Auth.userPoolId,
      ClientId:
        cognitoConfig[process.env.BUILD_ENV || 'staging'].Auth
          .userPoolWebClientId,
    };

    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

    const userData = {
      Username: AccessToken.payload.username,
      Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

    cognitoUser.setSignInUserSession(session);
  }

  return <></>;
};
