import { useAuthContext } from '@auth/index';
import { useFormik } from 'formik';
import { setEventTracking } from '@utils/analytics/segment';
import * as yup from 'yup';
import { useFirstAccessContext } from '../../firstAccessContext';
import { useCallback, useEffect, useState } from 'react';
import { dispatchToast } from '@utils';
import { Hub } from 'aws-amplify';
import { useAuthNavigation } from '../../../../routes';
import { hideDocumentNumber } from '@/utils/masks/hideDocumentNumber';

const validationSchema = yup.object({
  otp: yup.string().min(6).required('Favor preencher campos!'),
});

type ConfirmAccountFormProps = {
  disableNavigate?: boolean;
  onSuccess?: () => void;
};

export const useConfirmAccountForm = ({
  disableNavigate,
  onSuccess,
}: ConfirmAccountFormProps = {}) => {
  const navigate = useAuthNavigation();
  const { formData } = useFirstAccessContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingResendCode, setIsLoadingResendCode] =
    useState<boolean>(false);
  const auth = useAuthContext();
  const form = useFormik({
    initialValues: {
      otp: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await auth.confirmSignUpCode({
          username: formData.username,
          code: values.otp,
          preferredUsername: formData.documentNumber.replace(/\D/g, ''),
        });
      } catch (error) {
        dispatchToast({
          content: error.message,
          type: 'error',
        });
        setIsLoading(false);
      }
    },
  });

  const resendCode = useCallback(async () => {
    setIsLoadingResendCode(true);
    try {
      await auth.resendSignUpConfirmationCode(formData.username);
      dispatchToast({
        type: 'success',
        content:
          'Código reenviado com sucesso! Confira o código enviado em seu dispositivo',
      });
    } catch (error) {
      dispatchToast({
        type: 'error',
        content:
          'Ocorreu um erro ao reenviar seu código. Por favor, tente novamente.',
      });
    } finally {
      setIsLoadingResendCode(false);
    }
  }, [formData, auth]);

  useEffect(() => {
    const hubEventListener = Hub.listen('auth', async ({ payload }) => {
      const { event } = payload;
      if (event === 'autoSignIn') {
        setIsLoading(false);
        if (!disableNavigate) {
          navigate('ACCESS_SELECTION');
        }
        onSuccess?.();
      }

      if (event === 'autoSignIn_failure') {
        setEventTracking('auto_sign_in_failure', {
          document_number: hideDocumentNumber(
            formData.documentNumber.replace(/\D/g, ''),
          ),
        });
        navigate('LOGIN');
      }
    });

    return () => {
      hubEventListener();
    };
  }, [auth, navigate, formData.documentNumber, disableNavigate, onSuccess]);

  return { form, isLoading, resendCode, isLoadingResendCode };
};
