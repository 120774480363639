import { useAuthContext } from '@auth/index';
import { useState } from 'react';
import { RECOVERY_CHANNEL } from '@auth/IAuthProvider';
import { usePasswordRecoveryContext } from '../../passwordRecoveryContext';
import { dispatchToast } from '@utils';
import { setEventTracking } from '@utils/analytics/segment';
import { hideDocumentNumber } from '@utils/masks/hideDocumentNumber';

export const useSendPasswordRecoveryCode = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    step,
    setStep,
    username,
    setUsername,
    setRecoveryChannel,
    setDeliveryDetails,
  } = usePasswordRecoveryContext();
  const auth = useAuthContext();

  const sendCode = async (recoveryChannel: RECOVERY_CHANNEL) => {
    setIsLoading(true);
    try {
      setEventTracking('signin_forgotpassword_recovery_channel_selected', {
        document_number: hideDocumentNumber(username),
        recovery_channel: recoveryChannel,
      });
      const details = await auth.sendForgotPassword(username, recoveryChannel);
      setDeliveryDetails(details);
      setUsername(username);
      setRecoveryChannel(recoveryChannel);
      setStep(step + 1);
    } catch {
      dispatchToast({
        type: 'error',
        content: 'Ops! Erro ao enviar código, favor tentar novamente!',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { sendCode, isLoading };
};
