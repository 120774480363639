import { useFormik } from 'formik';
import * as yup from 'yup';
import { validateEmail, parseLogin, dispatchToast } from '@utils';
import { useState } from 'react';
import { useLoginContext } from '../../loginContext';
import { setEventTracking } from '@utils/analytics/segment';
import { hideDocumentNumber } from '@utils/masks/hideDocumentNumber';
import { trpc } from '@api/client';
import { useLogin } from '@auth/hooks/useLogin';
import { useAuthNavigation } from 'src/routes';

const validationSchema = yup.object({
  login: yup.string().required('Favor digitar o login!'),
  password: yup.string().required('Favor digitar a senha!'),
});

export const useLoginForm = () => {
  const navigate = useAuthNavigation();
  const { setStep, setAdminHasToVerifyEmail } = useLoginContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutateAsync, isLoading: isVerifyingEmail } =
    trpc.userHasToVerifyEmail.useMutation();
  const { login } = useLogin();

  const form = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validationSchema: validationSchema,

    validate: (values) => {
      const errors: any = {};
      const parsedValue = values.login.replace(/[^\d]+/g, '');
      const isPhoneValid = [11, 13].includes(parsedValue.length);
      if (!isPhoneValid && !validateEmail(values.login)) {
        errors.login = 'Favor digitar um login válido';
      }

      return errors;
    },

    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const parsedLogin = parseLogin({ value: values.login });
        setEventTracking('signin_login_submit_button_clicked', {
          document_number: hideDocumentNumber(parsedLogin),
        });
        const user = await login(values);

        setAdminHasToVerifyEmail(false);
        const userHasToVerifyData =
          !user?.attributes?.phoneNumber ||
          !user?.attributes?.phoneNumberVerified;
        if (user?.challenge?.name === 'SMS_MFA') {
          setStep('MFA');
        } else if (userHasToVerifyData) {
          setStep('VERIFY_DATA_LANDING');
        } else {
          navigate('ACCESS_SELECTION');
        }
      } catch (error) {
        // try to check if this is a specific migration error
        const userHasToVerifyEmail = await mutateAsync({
          cpf: parseLogin({ value: values.login }),
        });
        if (userHasToVerifyEmail) {
          setAdminHasToVerifyEmail(true);
          setStep('VERIFY_DATA_LANDING');
          return;
        }
        dispatchToast({
          type: 'error',
          content: error.message,
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  return { form, isLoading: isLoading || isVerifyingEmail };
};
