import styled from 'styled-components';

import {
  Typography,
  LinkButton,
  Icons,
  Modal,
} from '@flash-tecnologia/hros-web-ui-v2';

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 24px 24px 0px 0px;
`;

const ModalBoxArea = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light5};
  padding: 32px;
  border-radius: 8px;
  margin-bottom: 50px;
  width: 100%;
  .linkButton-custom-theme {
    margin-top: 12px;
  }

  @media screen and (max-width: 420px) {
    width: 100%;
    align-self: center;
    padding: 22px;
    margin-bottom: 25px;
  }
`;

const Boxtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  margin: 8px 0;
`;

const BoxIcon = styled(Icons)`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  padding: 15px;
  margin-bottom: 8px;
  border-radius: 100%;
  color: ${({ theme }) => theme.colors.primary};
  svg {
    fill: transparent;
  }
`;

const LinkButtonStyled = styled(LinkButton)`
  && {
    &.MuiButtonBase-root {
      align-self: center;
    }
  }
`;

const FlexEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 420px) {
    justify-content: center;
  }
`;

const StyledModal = styled(Modal.Root)`
  && {
    &.modal-custom-theme .modal-container {
      @media screen and (max-width: 420px) {
        max-width: 98%;
      }
    }
    &.modal-custom-theme .modal-content-area {
      padding-top: 0px;
      @media screen and (max-width: 700px) {
        padding: 35px;
      }
      @media screen and (max-width: 420px) {
        padding: 5px;
      }
    }
  }
`;

export {
  CloseContainer,
  ModalBoxArea,
  Boxtitle,
  BoxIcon,
  LinkButtonStyled,
  StyledModal,
  FlexEnd,
};
