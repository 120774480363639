import {
  Button,
  Icons,
  Modal,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { ModalContent } from './styles';
import { useAuthNavigation } from 'src/routes';

interface ModalProps {
  open: boolean;
}

export const CompanyAccountCreatedModal = ({ open }: ModalProps) => {
  const navigate = useAuthNavigation();
  const onConfirm = () => {
    navigate('ACCESS_SELECTION');
  };

  return (
    <Modal.Root open={open!} onClose={() => {}} showClose={false} size="xs">
      <>
        <ModalContent>
          <ShapeIcon
            variant="default"
            name="IconCheck"
            color="var(--color-secondary-50)"
            strokeWidth="1.5px"
            size={64}
          />
          <div>
            <Typography
              variant="headline6"
              variantColor="var(--color-neutral-20)"
            >
              Sua conta Flash foi criada!
            </Typography>
            <Typography
              variant="body3"
              variantColor="var(--color-secondary-50)"
              // TODO: it doesn't override the variant weight
              // change it after fixing on design system
              // weight={700}
              style={{ fontWeight: 700 }}
            >
              Continue para finalizar a contratação
            </Typography>
          </div>
          <Typography variant="body4" variantColor="var(--color-neutral-50)">
            Caso a contratação seja interrompida, acesse o mesmo{' '}
            <strong>link que te trouxe até aqui</strong> e na opção{' '}
            <strong>"Tenho uma conta Flash”</strong>, insira os dados
            cadastrados de CPF e senha para continuar de onde parou.
          </Typography>
        </ModalContent>
        <Modal.Footer style={{ padding: 0 }}>
          <Button
            size="large"
            variant="primary"
            onClick={onConfirm}
            style={{ margin: '0 auto' }}
          >
            Continuar contratação <Icons name="IconArrowRight" />
          </Button>
        </Modal.Footer>
      </>
    </Modal.Root>
  );
};
