import { useState, useCallback } from 'react';
import {
  OTPField,
  Icons,
  Typography,
  LinkButton,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Footer, Countdown } from '../../../../components/Common';
import { Header } from '../../../../components/Common/Header';
import {
  PageContainer,
  Wrapper,
  LeftContainer,
  RightContainer,
  ContentContainer,
  GreetingsText,
  Title,
  SubTitle,
  TextsContainer,
  OtpTitle,
  FormContainer,
  ButtonsContainer,
  StyledButton,
  SMSCodeContainer,
  SMSButtonFlex,
} from '../styles';
import CreateAccount from '../../../../assets/create_account.png';
import { useVerifyPhoneNumberForm } from './hooks';
import { useLoginContext } from '../../loginContext';

export const VerifyPhoneNumberStep = () => {
  const [showCountdown, setShowCountdown] = useState<boolean>(true);
  const { user, setStep } = useLoginContext();
  const { form, isLoading, resendCode } = useVerifyPhoneNumberForm();

  const handleResendCode = useCallback(async () => {
    resendCode();
    setShowCountdown(true);
  }, [resendCode]);

  const handleFinishCountDown = useCallback(() => {
    setShowCountdown(false);
  }, []);

  return (
    <PageContainer>
      <Header />
      <ContentContainer>
        <Wrapper>
          <LeftContainer>
            <TextsContainer>
              <GreetingsText variant="body3">Login</GreetingsText>
              <Title variant="headline5">
                Confirme o código de acesso enviado
              </Title>
              <SubTitle variant="body3">
                Antes de continuar, verifique seu número de telefone.
              </SubTitle>
            </TextsContainer>
            <form onSubmit={form.handleSubmit}>
              <FormContainer>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                  }}
                >
                  <OtpTitle variant="body3">
                    Insira o código de verificação recebido
                  </OtpTitle>
                  <OTPField
                    style={{ width: '45px' }}
                    numInputs={6}
                    value={form.values.otp}
                    onChange={(e: any) =>
                      form.handleChange({ target: { id: 'otp', value: e } })
                    }
                    error={form.touched.otp && Boolean(form.errors.otp)}
                  />
                </div>
                <SMSCodeContainer>
                  <SMSButtonFlex>
                    <Typography variant="body3">
                      Enviamos seu código para •••••••
                      {user?.attributes?.phoneNumber
                        ?.replace(/\D/g, '')
                        .slice(-4) || ''}
                    </Typography>
                  </SMSButtonFlex>
                  <SMSButtonFlex>
                    {showCountdown ? (
                      <Typography variant="body3">
                        Aguarde o recebimento • {''}
                        <Countdown
                          time={60}
                          onFinished={handleFinishCountDown}
                        />
                      </Typography>
                    ) : (
                      <Typography variant="body3">
                        Não recebeu seu código?
                      </Typography>
                    )}
                    <LinkButton
                      variant="default"
                      disabled={showCountdown || isLoading}
                      onClick={handleResendCode}
                    >
                      Reenviar <Icons name="IconArrowBack" />
                    </LinkButton>
                  </SMSButtonFlex>
                </SMSCodeContainer>
              </FormContainer>
              <ButtonsContainer>
                <LinkButton
                  variant="default"
                  onClick={() => setStep('UPDATE_PHONE_NUMBER')}
                >
                  Voltar
                </LinkButton>
                <StyledButton
                  variant="primary"
                  size="large"
                  type="submit"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Continuar <Icons name="IconArrowRight" />
                </StyledButton>
              </ButtonsContainer>
            </form>
          </LeftContainer>
          <RightContainer imageUrl={CreateAccount} />
        </Wrapper>
      </ContentContainer>
      <Footer />
    </PageContainer>
  );
};
