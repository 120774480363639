import { useFormik } from 'formik';
import * as yup from 'yup';
import { dispatchToast } from '@utils';
import { useAuthContext } from '@auth/hooks/useAuthContext';
import { useState } from 'react';
import { useAuthNavigation } from 'src/routes';
import { useLoginContext } from '../../loginContext';

export const useVerifyPhoneNumberForm = () => {
  const auth = useAuthContext();
  const { setUser } = useLoginContext();
  const navigate = useAuthNavigation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const validationSchema = yup.object({
    otp: yup.string().min(6).required('Favor preencher campos!'),
  });

  const resendCode = async () => {
    await auth.sendPhoneNumberVerificationCode();
  };

  const form = useFormik({
    initialValues: {
      otp: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await auth.verifyPhoneNumberCode(values.otp);
        await auth.setPreferredMFA('SMS');
        const authenticatedUser = await auth.currentAuthenticatedUser({
          bypassCache: true,
        });
        navigate('ACCESS_SELECTION');
        setUser(authenticatedUser);
      } catch (error) {
        dispatchToast({
          type: 'error',
          content: error.message,
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  return { form, isLoading, resendCode };
};
