import { SessionUserModel } from '@auth/models/SessionUserModel';
import { useEffect, useState } from 'react';
import { useAuthContext } from './useAuthContext';

export const useCurrentAuthenticatedUser = () => {
  const [user, setUser] = useState<SessionUserModel | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const auth = useAuthContext();

  useEffect(() => {
    const getUser = async () => {
      setIsLoading(true);
      const user = await auth.currentAuthenticatedUser();
      setUser(user);
      setIsLoading(false);
    };
    getUser();
  }, [auth]);

  return { isLoading, user };
};
