import {
  IAuthProvider,
  CONFIRM_MFA_TYPE,
  MFA_TYPE,
  RECOVERY_CHANNEL,
} from './IAuthProvider';
import { CognitoAuthImpl } from './cognito/CognitoAuthImpl';
import { CodeDeliveryDetails } from './models/CodeDeliveryDetails';
import {
  CustomMfa,
  SessionUserModel,
  UserAttributes,
} from './models/SessionUserModel';

export class AuthProviderImpl implements IAuthProvider {
  private readonly auth: IAuthProvider;

  constructor() {
    this.auth = new CognitoAuthImpl();
  }

  async signIn(input: {
    username: string;
    password: string;
  }): Promise<SessionUserModel> {
    return this.auth.signIn(input);
  }

  async confirmSignIn(input: {
    user: SessionUserModel;
    code: string;
    mfaType: CONFIRM_MFA_TYPE;
  }): Promise<SessionUserModel> {
    return this.auth.confirmSignIn(input);
  }

  async currentAuthenticatedUser(options?: {
    bypassCache: boolean;
  }): Promise<SessionUserModel> {
    return this.auth.currentAuthenticatedUser(options);
  }

  async currentSession(): Promise<{ idToken: string }> {
    return this.auth.currentSession();
  }

  async signOut(): Promise<void> {
    return this.auth.signOut();
  }

  async signUp(input: {
    username: string;
    password: string;
    name: string;
    email: string;
    phoneNumber: string;
    customMfa?: CustomMfa;
  }): Promise<SessionUserModel> {
    return this.auth.signUp(input);
  }

  async confirmSignUpCode(input: {
    username: string;
    code: string;
    preferredUsername: string;
  }): Promise<void> {
    return this.auth.confirmSignUpCode(input);
  }

  async resendSignUpConfirmationCode(username: string): Promise<void> {
    return this.auth.resendSignUpConfirmationCode(username);
  }

  async setPreferredMFA(mfaType: MFA_TYPE): Promise<void> {
    return this.auth.setPreferredMFA(mfaType);
  }

  async updateCurrentUserAttributes(
    attributes: UserAttributes,
  ): Promise<SessionUserModel> {
    return this.auth.updateCurrentUserAttributes(attributes);
  }

  async sendEmailVerificationCode(): Promise<void> {
    return this.auth.sendEmailVerificationCode();
  }

  async sendPhoneNumberVerificationCode(): Promise<void> {
    return this.auth.sendPhoneNumberVerificationCode();
  }

  async verifyEmailCode(code: string): Promise<void> {
    return this.auth.verifyEmailCode(code);
  }

  async verifyPhoneNumberCode(code: string): Promise<void> {
    return this.auth.verifyPhoneNumberCode(code);
  }

  async forgotPasswordSubmit(input: {
    username: string;
    code: string;
    newPassword: string;
  }): Promise<void> {
    return this.auth.forgotPasswordSubmit(input);
  }

  async sendForgotPassword(
    username: string,
    recoveryChannel: RECOVERY_CHANNEL,
  ): Promise<CodeDeliveryDetails> {
    return this.auth.sendForgotPassword(username, recoveryChannel);
  }
}
