import styled from 'styled-components';

import {
  Typography,
  LinkButton,
  Button,
} from '@flash-tecnologia/hros-web-ui-v2';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1366px;
  padding: 60px 136px 0 136px;
  width: 100%;
  margin: 0 auto;
  flex: 1;

  @media screen and (max-width: 992px) {
    padding: 60px 80px 0 80px;
  }
  @media screen and (max-width: 576px) {
    padding: 60px 24px 0 24px;
  }
`;

const StepWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 1200px) {
    display: block;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 535px;
  width: 100%;
  @media screen and (max-width: 1200px) {
    max-width: 800px;
  }
`;

const RightContainer = styled.div<{ imageUrl: string }>`
  background-image: url(${({ imageUrl }) => imageUrl});
  width: 420px;
  height: 421px;
  min-width: 400px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 0px 128px;
  margin-left: 30px;

  @media screen and (max-width: 1200px) {
    height: 0px;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
  gap: 24px;
`;

const CreateAccountText = styled(Typography)`
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 535px;
`;

const Title = styled(Typography)``;

const SubTitle = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
    max-width: 484px;
  }
`;

const FlexFieldsContainer = styled.span`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 82px;
`;

const LinkButtonStyled = styled(LinkButton)`
  && {
    &.MuiButtonBase-root {
      align-self: center;
    }
  }
`;

const StyledButton = styled(Button)`
  && {
    &.MuiButtonBase-root.size-large {
      padding: 19px 46px;

      @media screen and (max-width: 420px) {
        width: 198px;
      }
    }
  }
`;

const CheckBoxArea = styled.div`
  margin: 40px 0 0;
  u {
    cursor: pointer;
  }
`;

const FieldContainer = styled.div<{ fullWidth?: boolean }>`
  gap: 0px;
  display: flex;
  flex-direction: column;
  ${({ fullWidth }) => fullWidth && 'width: 100%'};
`;

const HelperText = styled(Typography)`
  margin: 6px 0 0 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  svg {
    stroke: ${({ theme }) => theme.colors.feedback.error.pure};
    fill: transparent;
    margin-right: 6px;
  }
`;

export {
  StepWrapper,
  LeftContainer,
  RightContainer,
  CreateAccountText,
  TextsContainer,
  Title,
  SubTitle,
  ContentContainer,
  FlexFieldsContainer,
  ButtonsContainer,
  LinkButtonStyled,
  StyledButton,
  CheckBoxArea,
  FieldContainer,
  HelperText,
  FormContainer,
  PageContainer,
};
