import { trpc } from '@api/client';
import { getAccessToken, getCep } from '@flash-tecnologia/hros-web-utility';
import { dispatchToast } from '@utils';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import * as yup from 'yup';
import {
  PRIVACY_POLICY_VERSION,
  TERMS_AND_CONDITIONS_VERSION,
} from './complementary-data.constants';

const validationSchema = yup.object({
  mothersName: yup.string().required('Favor digitar um nome'),
  birthDate: yup
    .date()
    .typeError('Favor selecionar uma data válida')
    .required('Favor selecionar uma data'),
  zipCode: yup
    .string()
    .matches(/^[0-9]{5}-[0-9]{3}$/)
    .required('Favor digitar um cep'),
  street: yup.string().notRequired(),
  number: yup.string().required('Favor digitar o número'),
  aditionalStreet: yup.string().notRequired(),
  district: yup.string().notRequired(),
  state: yup.string().notRequired(),
  city: yup.string().notRequired(),
  termChecked: yup.boolean().oneOf([true], 'Favor aceitar os termos'),
});

const initialValues = {
  mothersName: '',
  birthDate: '',
  zipCode: '',
  street: '',
  number: '',
  complement: '',
  district: '',
  state: '',
  city: '',
  termChecked: false,
};

export const useUpdateComplementaryDataForm = (input: {
  onSuccess: () => void;
}) => {
  const { mutate, isLoading } = trpc.updateComplementaryData.useMutation({
    onSuccess: () => {
      input.onSuccess();
    },
    onError: (error) => {
      dispatchToast({
        type: 'error',
        content: error.message,
      });
    },
  });
  const form = useFormik({
    initialValues,
    validationSchema,
    validate: async () => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (values) => {
      const accessToken = await getAccessToken();
      mutate({
        data: {
          address: {
            zipCode: values.zipCode,
            street: values.street,
            number: values.number,
            complement: values.complement,
            district: values.district,
            city: values.city,
            state: values.state,
          },
          birthDate: new Date(values.birthDate),
          mothersName: values.mothersName,
        },
        privacyPolicyVersion: PRIVACY_POLICY_VERSION,
        termsAndConditionsVersion: TERMS_AND_CONDITIONS_VERSION,
        accessToken,
      });
    },
  });

  return { form, isLoading };
};

type CepData = {
  logradouro: string;
  bairro: string;
  localidade: string;
  uf: string;
};

export const useCepQuery = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cepData, setCepData] = useState<CepData>({
    logradouro: '',
    bairro: '',
    localidade: '',
    uf: '',
  });
  const fetch = useCallback(
    async (cep: string) => {
      try {
        setIsLoading(true);
        const response = await getCep(cep);
        if (response.erro) {
          dispatchToast({
            type: 'error',
            content: 'Cep inválido. Por favor, digite outro cep!',
          });
        } else {
          setCepData(response);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading],
  );
  return { fetch, isLoading, cepData };
};
