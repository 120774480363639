import { useEffect, useState } from 'react';
import {
  Icons,
  LinkButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useResendInivationToEmployees } from '../../hooks';
import { useCurrentAuthenticatedUser } from '@/auth';
import {
  AlertWrapper,
  Container,
  ContentBox,
  TextWrapper,
  Subtitle,
  SubtitleBold,
  ResendWrapper,
  GoBackWrapper,
} from './styles';

export const EmptyState = () => {
  const [invitationSended, setInvitationsSended] = useState(false);
  const { user } = useCurrentAuthenticatedUser();
  const { data, resendInvitationsToEmployees, isLoading } =
    useResendInivationToEmployees();

  useEffect(() => {
    if (data) {
      setInvitationsSended(true);
    }
  }, [data]);

  return (
    <Container>
      <ContentBox>
        {invitationSended ? (
          <>
            <AlertWrapper>
              <Icons
                name="IconMoodSmileBeam"
                size={72}
                fill="transparent"
                color="var(--color-primary)"
              />
            </AlertWrapper>
            <TextWrapper>
              <Typography
                tag="span"
                variant="headline7"
                variantColor="var(--color-secondary-50)"
                style={{ marginBottom: '16px' }}
              >
                Seu convite de boas vindas foi reenviado!
              </Typography>
              <Subtitle>
                Realize o seu
                <SubtitleBold> primeiro acesso </SubtitleBold>
                através do convite enviado por e-mail ou SMS e selecione a opção
                <SubtitleBold> "Já tenho uma conta". </SubtitleBold>
              </Subtitle>
            </TextWrapper>
            <GoBackWrapper>
              <LinkButton
                variant="default"
                disabled={isLoading}
                onClick={() => {
                  setInvitationsSended(false);
                }}
              >
                <Icons name="IconArrowNarrowLeft" size={14}></Icons>
                Voltar e reenviar
              </LinkButton>
            </GoBackWrapper>
          </>
        ) : (
          <>
            <AlertWrapper>
              <Icons
                name="IconExclamationMark"
                size={72}
                fill="transparent"
                color="var(--color-primary)"
              />
            </AlertWrapper>
            <TextWrapper>
              <Typography
                tag="span"
                variant="headline7"
                variantColor="var(--color-secondary-50)"
                style={{ marginBottom: '16px' }}
              >
                Aceite o convite de boas-vindas antes de continuar
              </Typography>
              <Subtitle>
                Para a sua segurança, você deverá realizar o seu primeiro acesso
                através do
                <SubtitleBold> convite de boas-vindas </SubtitleBold>
                enviado por e-mail ou SMS e selecionar a opção
                <SubtitleBold> "Já tenho uma conta". </SubtitleBold>
              </Subtitle>
              <SubtitleBold>Não recebeu o convite?</SubtitleBold>
            </TextWrapper>
            <ResendWrapper>
              <LinkButton
                variant="default"
                disabled={isLoading}
                onClick={() => {
                  resendInvitationsToEmployees({
                    documentNumber: user?.username,
                  });
                }}
              >
                Reenviar convite
              </LinkButton>
            </ResendWrapper>
          </>
        )}
      </ContentBox>
    </Container>
  );
};
