import { useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { AreYouNewHereStep } from './components/AreYouNewHereStep';
import { useMobileDeepLink } from './components/AreYouNewHereStep/hooks';
import { CreateAccountStep } from './components/CreateAccountStep';
import { FirstAccessFormContext, FormData } from './firstAccessContext';
import { ConfirmAccountStep } from './components/ConfirmAccountStep';
import { Header, Footer } from '@components/Common';
import { ContentContainer, PageContainer } from './styles';
import { AreYouNewHereCompanyStep } from './components/AreYouNewHereCompanyStep';
import { CustomMfa } from '@/auth/models/SessionUserModel';

export const PageFirstAccess = () => {
  const { code } = useParams();
  const [step, setStep] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const invitationToken = searchParams.get('invitationToken') || code || null;
  const redirectUrl = searchParams.get('redirectTo');
  const variant = searchParams.get('variant');
  const mfaMethod = searchParams.get('mfaMethod') as CustomMfa | undefined;
  const [formData, setFormData] = useState<FormData>({
    username: '',
    documentNumber: '',
    phoneNumber: '',
    email: '',
    password: '',
    name: '',
  });
  useMobileDeepLink({ invitationToken, mfaMethod });

  if (!invitationToken && !redirectUrl) {
    return <Navigate to={'/authentication/login'} />;
  }

  return (
    <FirstAccessFormContext.Provider
      value={{ step, setStep, formData, setFormData, invitationToken }}
    >
      <PageContainer>
        <Header />
        <ContentContainer>{renderStep(step, variant)}</ContentContainer>
        <Footer />
      </PageContainer>
    </FirstAccessFormContext.Provider>
  );
};

const renderStep = (step, variant?) => {
  switch (step) {
    case 0:
      return variant === 'company' ? (
        <AreYouNewHereCompanyStep />
      ) : (
        <AreYouNewHereStep />
      );
    case 1:
      return <CreateAccountStep />;
    case 2:
      return <ConfirmAccountStep />;
  }
};
