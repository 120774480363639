import { useCallback, useState } from 'react';
import { Icons, Typography, OTPField } from '@flash-tecnologia/hros-web-ui-v2';
import {
  StepWrapper,
  LeftContainer,
  RightContainer,
  TextsContainer,
  CreateAccountText,
  Title,
  SubTitle,
  ContentContainer,
  SMSCodeContainer,
  SMSButtonFlex,
  ButtonsContainer,
  LinkButtonStyled,
  StyledButton,
  OtpTitle,
} from './styles';
import CreateAccount from '../../../../assets/create_account.png';
import { ConfirmationStepModal } from '@components/Modals';
import { useConfirmAccountForm } from './hooks';
import { Countdown } from '@components/Common';
import { useFirstAccessContext } from '../../firstAccessContext';
import { CompanyAccountCreatedModal } from '../CompanyAccountCreatedModal';
import { useSearchParams } from 'react-router-dom';

export const ConfirmAccountStep = () => {
  const { formData, setStep, step } = useFirstAccessContext();
  const [searchParams] = useSearchParams();
  const variant = searchParams.get('variant');
  const [open, setOpen] = useState<boolean>(false);
  const [openCompanySuccessModal, setOpenCompanySuccessModal] = useState(false);
  const [showCountdown, setShowCountdown] = useState<boolean>(true);

  const confirmAccountFormProps =
    variant === 'company' ? { disableNavigate: true, onSuccess } : {};

  const { form, isLoading, resendCode, isLoadingResendCode } =
    useConfirmAccountForm(confirmAccountFormProps);

  function onSuccess() {
    setOpenCompanySuccessModal(true);
  }

  const handleResendCode = useCallback(async () => {
    await resendCode();
    setShowCountdown(true);
  }, [resendCode]);

  const handleFinishCountDown = useCallback(() => {
    setShowCountdown(false);
  }, []);

  return (
    <StepWrapper>
      <LeftContainer>
        <TextsContainer>
          <CreateAccountText variant="body3">Criar conta</CreateAccountText>
          <Title variant="headline5">Confirme seu número de celular</Title>
          <SubTitle variant="body3">
            Cadastrar um telefone válido é importante nos casos de recuperação
            de acesso e para manter a comunicação com a empresa onde você
            trabalha.
          </SubTitle>
        </TextsContainer>
        <form onSubmit={form.handleSubmit}>
          <ContentContainer>
            <OtpTitle variant="body3">
              Insira o código de confirmação recebido
            </OtpTitle>
            <OTPField
              style={{ width: '45px' }}
              numInputs={6}
              value={form.values.otp}
              onChange={(e: any) =>
                form.handleChange({ target: { id: 'otp', value: e } })
              }
              error={form.touched.otp && Boolean(form.errors.otp)}
            />
          </ContentContainer>
          <SMSCodeContainer>
            <SMSButtonFlex>
              <Typography variant="body3">
                Enviamos seu código para •••••••
                {formData.phoneNumber.slice(-4)}
              </Typography>
              {/* <LinkButtonStyled variant="primary" onClick={() => setOpen(true)}>
                Editar <Icons name="IconPencil" />
              </LinkButtonStyled> */}
            </SMSButtonFlex>
            <SMSButtonFlex>
              {showCountdown ? (
                <Typography variant="body3">
                  Aguarde o recebimento • {''}
                  <Countdown time={60} onFinished={handleFinishCountDown} />
                </Typography>
              ) : (
                <Typography variant="body3">Não recebeu seu código?</Typography>
              )}
              <LinkButtonStyled
                variant="default"
                disabled={showCountdown || isLoadingResendCode}
                onClick={handleResendCode}
              >
                Reenviar <Icons name="IconArrowBack" />
              </LinkButtonStyled>
            </SMSButtonFlex>
          </SMSCodeContainer>
          <ButtonsContainer>
            <LinkButtonStyled
              variant="default"
              onClick={() => setStep(step - 1)}
            >
              Voltar
            </LinkButtonStyled>
            <StyledButton
              variant="primary"
              size="large"
              type="submit"
              loading={isLoading}
              disabled={isLoading}
            >
              Continuar <Icons name="IconArrowRight" />
            </StyledButton>
          </ButtonsContainer>
        </form>
      </LeftContainer>
      <RightContainer imageUrl={CreateAccount} />
      <CompanyAccountCreatedModal open={openCompanySuccessModal} />
      <ConfirmationStepModal open={open} onClose={() => setOpen(false)} />
    </StepWrapper>
  );
};
