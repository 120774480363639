import { useState, useCallback, useEffect, useMemo } from 'react';
import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { SectionLoading } from '../../../../../../components/Common';
import {
  useUserAccessesQuery,
  useHideUserAccess,
  useGetAccessTokenQuery,
} from '../../../../hooks';
import { AccessGroup, AccessOption } from '../../../../types';
import {
  CardsButtonContainer,
  CardsContainer,
  ButtonsContainer,
  LinkButtonStyled,
  AccessesLinkButtonContainer,
  ListAccessesContainer,
} from './styles';
import { AccessItem } from '../AccessItem';
import { groupBy, map } from 'lodash';
import { getFromLS, setInLS } from '@flash-tecnologia/hros-web-utility';
import { AccessSelectionHeader } from './components/Header';
import { SearchEmptyState } from './components/SearchEmptyState';
export const AccessSelectionList = ({
  preSelectedAccess,
  callback,
  onGoBack,
}: {
  preSelectedAccess?: AccessOption;
  callback: () => void;
  onGoBack?: () => void;
}) => {
  const [selectedAccess, setSelectedAccess] = useState<
    AccessOption | undefined
  >(undefined);
  const { accesses: allAccesses, isLoading: isLoadingAccesses } =
    useUserAccessesQuery();

  const { getToken } = useGetAccessTokenQuery({
    callback: () => {
      setSelectedAccess(undefined);
      callback();
    },
    onErrorCallback: useCallback(() => {
      setSelectedAccess(undefined);
    }, []),
  });

  const { hideUserAccess } = useHideUserAccess();
  const [accessGroups, setAccessGroups] = useState<AccessGroup[]>([]);
  const [showHiddenAccesses, setShowHiddenAccesses] = useState(
    getFromLS('showHiddenAccesses') ? true : false,
  );
  const [search, setSearch] = useState('');

  const shownGroupAccesses = useMemo(
    () =>
      accessGroups.filter((group) => showHiddenAccesses || !group.hidden) || [],
    [accessGroups, showHiddenAccesses],
  );
  const hiddenGroupAccesses = useMemo(
    () => accessGroups.filter((group) => group.hidden) || [],
    [accessGroups],
  );

  const handleSetShowHiddenAccesses = () => {
    setInLS({ key: 'showHiddenAccesses', value: !showHiddenAccesses });
    setShowHiddenAccesses((prev) => !prev);
  };

  const normalizeText = (input: string) => {
    return input
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '');
  };

  const filteredGroups: AccessGroup[] = useMemo(() => {
    const searchFiltered = normalizeText(search);
    return shownGroupAccesses
      .map((group) => ({
        ...group,
        accesses: group.accesses.filter(
          (access) =>
            normalizeText(access.name).includes(searchFiltered) ||
            normalizeText(access.registrationNumber).includes(searchFiltered),
        ),
      }))
      .filter((group) => group.accesses.length > 0);
  }, [search, shownGroupAccesses]);

  const onAccessSelect = useCallback((access: AccessOption) => {
    setSelectedAccess(access);
    getToken(access);
  }, []);

  const toggleHideGroup = useCallback(
    (groupAccess: AccessGroup) => {
      for (const access of groupAccess.accesses) {
        access.hidden = groupAccess.hidden;
        toggleHideCompany(access);
      }
    },
    [hideUserAccess],
  );

  const toggleHideCompany = useCallback(
    ({ hidden, employeeId }: AccessOption) => {
      hideUserAccess({ hide: !hidden, employeeId });
      setAccessGroups((prev) => {
        return prev.map((group) => {
          const accesses = group.accesses.map((access) => {
            if (access.employeeId === employeeId) {
              return { ...access, hidden: !hidden };
            }
            return access;
          });
          const groupHidden = !accesses.some((access) => !access.hidden);
          return { ...group, hidden: groupHidden, accesses };
        });
      });
    },
    [hideUserAccess],
  );
  const hiddenAccessCounter = useMemo(() => {
    let hiddenCounter = 0;
    accessGroups.forEach((group) => {
      const hiddenAccesses = group.accesses.filter((access) => access.hidden);
      hiddenCounter += hiddenAccesses.length;
    });
    return hiddenCounter;
  }, [accessGroups]);

  useEffect(() => {
    if (preSelectedAccess) {
      onAccessSelect(preSelectedAccess);
    }
  }, [preSelectedAccess]);

  useEffect(() => {
    const groupedAccesses = map(
      groupBy(allAccesses, (access) => access.economicGroupId),
      (accesses, economicGroupId) => ({
        economicGroupId,
        name: accesses[0].name,
        hidden: !accesses.some((access) => !access.hidden),
        accesses,
      }),
    );

    setAccessGroups(groupedAccesses);
  }, [allAccesses]);

  if (isLoadingAccesses || preSelectedAccess) {
    return (
      <CardsButtonContainer>
        <SectionLoading />
      </CardsButtonContainer>
    );
  }
  const isSearching = search.length > 0;
  const hasResults = filteredGroups.length > 0;

  return (
    <ListAccessesContainer>
      <AccessSelectionHeader
        handleSetShowHiddenAccesses={handleSetShowHiddenAccesses}
        hiddenAccessCounter={hiddenAccessCounter}
        search={search}
        setSearch={setSearch}
        showHiddenAccesses={showHiddenAccesses}
      />
      <CardsButtonContainer>
        <CardsContainer>
          {filteredGroups.map((accessGroup) => (
            <AccessItem
              key={accessGroup.economicGroupId}
              accessGroup={accessGroup}
              onAccessSelected={onAccessSelect}
              onToggleHideAccess={toggleHideCompany}
              onToggleHideGroup={toggleHideGroup}
              selectedAccess={selectedAccess}
              showHiddenAccesses={showHiddenAccesses}
            />
          ))}
        </CardsContainer>
        {isSearching && !hasResults && <SearchEmptyState />}

        {!!hiddenGroupAccesses?.length && (
          <AccessesLinkButtonContainer>
            <LinkButton
              variant="neutral"
              onClick={() => {
                handleSetShowHiddenAccesses();
              }}
            >
              {showHiddenAccesses
                ? 'Fechar empresas ocultas'
                : 'Mostrar empresas ocultas'}
            </LinkButton>
          </AccessesLinkButtonContainer>
        )}
        <ButtonsContainer>
          {onGoBack && (
            <LinkButtonStyled variant="default" onClick={onGoBack}>
              Sair
            </LinkButtonStyled>
          )}
        </ButtonsContainer>
      </CardsButtonContainer>
    </ListAccessesContainer>
  );
};
