import { useMemo } from 'react';
import { AccessGroup, AccessOption } from '../../../../types';
import {
  AccessContainer,
  Content,
  ItemContent,
  Title,
  TextsContainer,
  SubTitle,
} from './access-item.styles';
import { HidableIconButton, AccessButton } from './components';
import { formatRegistrationNumber } from '@/utils/masks/formatRegistrationNumber';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';

type AccessItemProps = {
  accessGroup: AccessGroup;
  selectedAccess?: AccessOption;
  onAccessSelected: (access: AccessOption) => void;
  onToggleHideAccess: (access: AccessOption) => void;
  onToggleHideGroup: (group: AccessGroup) => void;
  showHiddenAccesses: boolean;
};

export const AccessItem = ({
  accessGroup,
  onAccessSelected,
  onToggleHideAccess,
  onToggleHideGroup,
  selectedAccess,
  showHiddenAccesses,
}: AccessItemProps) => {
  const firstAccess = accessGroup.accesses[0];
  const groupViewCondition = accessGroup.accesses.length > 1;

  const hideTooltipText = useMemo(() => {
    if (groupViewCondition) {
      return accessGroup.hidden
        ? 'Voltar a mostrar grupo na lista'
        : 'Ocultar grupo da lista';
    }
    return firstAccess.hidden
      ? 'Voltar a mostrar empresa na lista'
      : 'Ocultar empresa da lista';
  }, [accessGroup]);

  const groupSubtitleText = useMemo(() => {
    if (groupViewCondition) {
      return `Acesse sua conta nas empresas desse grupo`;
    }
    return firstAccess.registrationNumber
      ? `CNPJ: ${formatRegistrationNumber(firstAccess.registrationNumber)}`
      : '';
  }, [groupViewCondition]);

  return (
    <AccessContainer
      data-onboarding="authentication-access-container"
      $hidden={accessGroup.hidden}
      {...(!groupViewCondition && {
        selected: selectedAccess?.companyId === firstAccess.companyId,
        deactivated:
          selectedAccess !== undefined &&
          selectedAccess.companyId !== firstAccess.companyId,
      })}
    >
      <ItemContent>
        <HidableIconButton
          name={accessGroup.name}
          hidden={accessGroup.hidden}
          companyId={firstAccess.companyId}
          selectedAccess={selectedAccess}
          toolTipText={hideTooltipText}
          onToggleHide={() =>
            groupViewCondition
              ? onToggleHideGroup(accessGroup)
              : onToggleHideAccess(firstAccess)
          }
          accessGroup={groupViewCondition ? accessGroup : undefined}
        />
        <Content>
          <TextsContainer>
            <Title
              deactivated={
                !!selectedAccess &&
                selectedAccess.companyId !== firstAccess.companyId
              }
              variant="headline8"
            >
              {firstAccess.name}
            </Title>
            <SubTitle variant="body4">{groupSubtitleText}</SubTitle>
          </TextsContainer>
          {groupViewCondition &&
            accessGroup.accesses.map((access) => {
              return !access.hidden || showHiddenAccesses ? (
                <AccessContainer
                  secondary
                  $hidden={access.hidden}
                  selected={selectedAccess?.companyId === access.companyId}
                  deactivated={
                    !!selectedAccess &&
                    selectedAccess.companyId !== access.companyId
                  }
                >
                  <ItemContent>
                    <HidableIconButton
                      name={access.name}
                      hidden={access.hidden}
                      companyId={access.companyId}
                      selectedAccess={selectedAccess}
                      toolTipText={hideTooltipText}
                      onToggleHide={() => onToggleHideAccess(access)}
                    />
                    <TextsContainer>
                      <Typography
                        variant="headline9"
                        variantColor="var(--color-neutral-30)"
                      >
                        {access.name}
                      </Typography>
                      <SubTitle variant="body4">
                        {access?.registrationNumber
                          ? `CNPJ: ${formatRegistrationNumber(
                              access.registrationNumber,
                            )}`
                          : ''}
                      </SubTitle>
                    </TextsContainer>
                  </ItemContent>
                  <AccessButton
                    hide={!!selectedAccess}
                    loading={selectedAccess?.companyId === access.companyId}
                    onClick={() => onAccessSelected(access)}
                  />
                </AccessContainer>
              ) : null;
            })}
        </Content>
      </ItemContent>
      {!groupViewCondition ? (
        <AccessButton
          hide={!!selectedAccess}
          loading={selectedAccess?.companyId === firstAccess.companyId}
          onClick={() => onAccessSelected(firstAccess)}
        />
      ) : null}
    </AccessContainer>
  );
};
