import { IconsProps, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Container, ShapeIcon, TextsContainer } from './styles';

interface BulletItemProps {
  title: string;
  text: string;
  iconName: IconsProps['name'];
}

export const BulletItem = ({ text, title, iconName }: BulletItemProps) => {
  return (
    <Container>
      <ShapeIcon
        name={iconName}
        variant="default"
        color="var(--color-primary)"
        size={48}
      />
      <TextsContainer>
        <Typography variant="headline8" variantColor="var(--color-primary)">
          {title}
        </Typography>
        <Typography variant="body4" variantColor="var(--color-neutral-40)">
          {text}
        </Typography>
      </TextsContainer>
    </Container>
  );
};
