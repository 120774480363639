import { useRef, useState, useEffect, useCallback } from 'react';
import {
  MenuCircle,
  MenuContainer,
  Container,
  ProgressBar,
  Content,
} from './styles';
import { Stepper, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import LogoFlash from '../../../../assets/logo_flash.svg';

interface HeaderProps {
  progress?: string | number;
  hasOptions?: boolean;
  activeStep?: number;
  steps?: string[];
  hasMenu?: boolean;
}

export const Mobile = ({
  hasMenu,
  progress = 0,
  activeStep,
  steps,
}: HeaderProps) => {
  const [active, setActive] = useState(false);
  const [menuContainerHeight, setMenuContainerHeight] = useState(0);
  const ref = useRef<HTMLInputElement>(null);

  const setMenuActive = useCallback(() => {
    setActive(!active);
  }, [active]);

  useEffect(() => {
    setMenuContainerHeight(ref.current?.clientHeight || 0);
  }, [active]);

  return (
    <Container ref={ref}>
      <Content>
        <LogoFlash />
        {hasMenu ? (
          <>
            <MenuCircle onClick={setMenuActive}>
              <Icons
                name={active ? 'IconX' : 'IconMenu2'}
                fill={'#6D5A66'}
                size={18}
                onClick={() => {}}
              />
            </MenuCircle>
            <MenuContainer menuHeight={menuContainerHeight} active={active}>
              {steps && (
                <Stepper
                  activeStep={activeStep}
                  steps={steps}
                  direction={'vertical'}
                />
              )}
            </MenuContainer>
          </>
        ) : (
          <></>
        )}
      </Content>
      <ProgressBar width={progress}></ProgressBar>
    </Container>
  );
};
