import styled from 'styled-components';
import { ShapeIcon as DsShapeIcon } from '@flash-tecnologia/hros-web-ui-v2';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 32px 20px 20px;
  gap: ${({ theme }) => theme.spacings.xs};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  background: ${({ theme }) => theme.colors.secondary[99]};
`;

const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

const ShapeIcon = styled(DsShapeIcon)`
  flex-shrink: 0;
`;

export { Container, ShapeIcon, TextsContainer };
