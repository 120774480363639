import { useFormik } from 'formik';
import * as yup from 'yup';
import { validateEmail, parseLogin } from '../../../../utils';
import { usePasswordRecoveryContext } from '../../passwordRecoveryContext';
import { setEventTracking } from '@utils/analytics/segment';
import { hideDocumentNumber } from '@utils/masks/hideDocumentNumber';

const validationSchema = yup.object({
  login: yup.string().required('Favor preencher o campo!'),
});

export const usePasswordRecoveryForm = () => {
  const { step, setStep, setUsername } = usePasswordRecoveryContext();
  const form = useFormik({
    initialValues: {
      login: '',
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: any = {};
      const parsedValue = values.login.replace(/[^\d]+/g, '');
      const isPhoneValid = [11, 13].includes(parsedValue.length);
      if (!isPhoneValid && !validateEmail(values.login)) {
        errors.login = 'Favor digitar um login válido';
      }

      return errors;
    },
    onSubmit: async (values) => {
      const validatedLogin = parseLogin({ value: values.login });
      setEventTracking('signin_forgotpassword_submit_button_clicked', {
        document_number: hideDocumentNumber(validatedLogin),
      });
      setUsername(validatedLogin);
      setStep(step + 1);
    },
  });

  return { form };
};
