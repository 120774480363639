import { Icons } from '@flash-tecnologia/hros-web-ui-v2';

import {
  Container,
  ContentContainer,
  DataContainer,
  CompanyTexts,
  DoubtsText,
  FlexAligned,
  IconTexts,
  ContactText,
} from './styles';

export const Footer = () => {
  return (
    <Container>
      <ContentContainer>
        <DataContainer>
          <CompanyTexts variant="caption">
            Flash Tecnologia e Pagamentos LTDA
          </CompanyTexts>
          <CompanyTexts variant="caption">
            CNPJ 32.223.020/0001-18 • Registrado no PAT nº 190679531
          </CompanyTexts>
        </DataContainer>
        <DataContainer>
          <DoubtsText variant="body4">
            Dúvidas? Acesse nossa Central de Ajuda ou fale com a gente!
          </DoubtsText>
          <FlexAligned>
            <IconTexts>
              <Icons name="IconHeadset" />
              <ContactText variant="body4">(11) 4118-0488</ContactText>
            </IconTexts>
            <IconTexts>
              <Icons name="IconMail" />
              <ContactText variant="body4">falecom@flashapp.com.br</ContactText>
            </IconTexts>
          </FlexAligned>
        </DataContainer>
      </ContentContainer>
    </Container>
  );
};
