import styled from 'styled-components';

import { Typography, Button } from '@flash-tecnologia/hros-web-ui-v2';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1366px;
  padding: 60px 136px 0 136px;
  width: 100%;
  margin: 0 auto;
  flex: 1;

  @media screen and (max-width: 992px) {
    padding: 60px 80px 0 80px;
  }
  @media screen and (max-width: 576px) {
    padding: 60px 24px 0 24px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  @media screen and (max-width: 1200px) {
    display: block;
    width: 100%;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
  align-items: flex-start;
`;

export const BottomContainer = styled.div<{ ssoEnabled: boolean }>`
  display: flex;
  justify-content: ${({ ssoEnabled }) =>
    ssoEnabled ? 'space-between' : 'flex-start'};
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 535px;
  width: 100%;
  margin-bottom: 40px;

  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }
`;

export const RightContainer = styled.div<{ imageUrl: string }>`
  background-image: url(${({ imageUrl }) => imageUrl});
  width: 420px;
  height: 421px;
  min-width: 400px;
  border-radius: 0px 128px;
  margin-left: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 1200px) {
    height: 0px;
  }
`;

export const GreetingsText = styled(Typography)`
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 568px;
`;

export const Title = styled(Typography)``;

export const SubTitle = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
  gap: 24px;
`;

export const FieldContainer = styled.div`
  gap: 0px;
  display: flex;
  flex-direction: column;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;

  @media screen and (max-width: 420px) {
    flex-direction: column-reverse;
    gap: 20px;
  }
`;

export const ForgotButtonsContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const StyledButton = styled(Button)`
  &&.MuiButtonBase-root.size-large {
    padding: ${({ theme }) => theme.spacings.xs2 + ' ' + theme.spacings.xs};

    @media screen and (max-width: 420px) {
      margin: 0 auto;
    }
  }
`;

export const Divider = styled.span`
  height: 1px;
  background: ${({ theme }) => theme.colors.neutral.light.light2};
  margin: 40px 0 44px 0;
`;

export const SMSCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 43px;
  width: 100%;
  gap: 10px;
`;

export const SMSButtonFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const OtpTitle = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.dark.dark4};
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
  gap: 24px;
`;

export const RequiredText = styled(Typography)`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.neutral['30']};
`;
