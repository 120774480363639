import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.feedback.info.light2};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.feedback.info.light1};
  margin-top: 24px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const Title = styled(Typography).attrs({
  variant: 'body3',
})`
  && {
    margin-left: 8px;
    font-weight: 600;
  }
`;

export const Description = styled(Typography).attrs({
  variant: 'body4',
})``;
