import { useState, useMemo, useCallback } from 'react';
import { trpc } from '@/api/client';
import { dispatchToast } from '@/utils';
import {
  getFromLS,
  setInLS,
  switchLegacyStorageData,
} from '@flash-tecnologia/hros-web-utility';
import {
  Authentication,
  usePermissionsFlags,
  useAuth,
} from '@flash-hros/auth-helper';
const { getAccessToken } = Authentication;
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AccessOption } from './types';
import { ErrorBoundary } from '@utils/ErrorBoundary';
import { useDynamicSSO } from '@flash-tecnologia/flashstage-auth';
import { ContractStatus } from 'bff/src/services';

const USER_PREFERENCES_KEY = 'userPreferences';
export const useChooseAccessesConfig = () => {
  const getCurrentVersion = () => {
    const userPreferences = getFromLS(USER_PREFERENCES_KEY);
    if (!userPreferences) return 1;
    return userPreferences?.chooseAccessesVersion;
  };
  const setCurrentVersion = (version: number) => {
    const userPreferences = getFromLS(USER_PREFERENCES_KEY) || {};
    userPreferences.chooseAccessesVersion = version;
    setInLS({ key: USER_PREFERENCES_KEY, value: userPreferences });
  };
  return {
    getCurrentVersion,
    setCurrentVersion,
  };
};

export const useRedeemInvitation = () => {
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();
  const { mutate, isLoading } = trpc.redeemInvitationToken.useMutation({
    onSuccess: (data) => {
      params.delete('invitationToken');
      data.ssoProvider?.providerName &&
        params.append('ssoProviderName', data.ssoProvider.providerName);
      params.append('employeeId', data.employeeId);
      params.append(
        'redirectTo',
        '/authentication/first-access/complementary-data',
      );
      setSearchParams(params);
    },
    onError: (error) => {
      dispatchToast({
        type: 'error',
        content: error.message,
      });
      navigate('/authentication/login');
    },
  });

  return { isLoading, redeem: mutate };
};

export const useUserAccessesQuery = () => {
  const { data, isLoading } = trpc.listAccesses.useQuery(undefined, {
    refetchOnWindowFocus: false,
    cacheTime: 0,
    onError: (e) => {
      const error = new Error('List Accesses Error');
      ErrorBoundary.captureException(error, 'error', {
        error: e,
      });
      dispatchToast({
        type: 'error',
        content: 'Erro ao buscar acessos',
      });
    },
  });

  return { accesses: data, isLoading };
};

const commutedLogin = async (input) => {
  try {
    await switchLegacyStorageData(input.registrationNumber);
  } catch (error) {
    ErrorBoundary.captureException(error, 'error', {
      registrationNumber: input.registrationNumber,
    });
  }
};

export const useResendInivationToEmployees = () => {
  const { data, isLoading, mutate } =
    trpc.resendInvitationsToEmployees.useMutation();

  const resendInvitationsToEmployees = ({
    documentNumber,
  }: {
    documentNumber?: string;
  }) => {
    if (documentNumber)
      mutate(
        { documentNumber },
        {
          onSuccess: (data) => {
            if (!data) {
              dispatchToast({
                content:
                  'Não é possível reenviar convite para sua conta, por favor entre em contato com suporte!',
                type: 'warning',
              });
            }
          },
          onError: () => {
            dispatchToast({
              content: 'Erro ao reenviar convite. Por favor, tente novamente',
              type: 'error',
            });
          },
        },
      );
  };

  return { data, resendInvitationsToEmployees, isLoading };
};

export const useGetAccessTokenQuery = ({
  callback,
  onErrorCallback,
}: {
  callback: () => void;
  onErrorCallback: () => void;
}) => {
  const {
    data,
    mutate,
    isLoading: isLoadingQuery,
  } = trpc.signInEmployee.useMutation();
  const [isLoadingSSO, setIsLoadingSSO] = useState(false);
  const sso = useDynamicSSO();
  const location = window.location;
  const params = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const permissionsFlagsLoading = usePermissionsFlags((state) => state.loading);

  const setPeopleContractModules = (
    contractModules?:
      | {
          contractId?: string | null;
          modules?: string[] | null;
          contractStatus?: ContractStatus | null;
        }
      | undefined,
  ) => {
    const contractStatus = contractModules?.contractStatus;
    useAuth.setState({ contractStatus });
    setInLS({
      key: 'people_contract_modules',
      value: contractModules,
    });
    dispatchEvent(
      new CustomEvent('people_contract_modules_updated', {
        detail: contractModules,
      }),
    );
  };

  const getToken = useCallback(
    (input: AccessOption) => {
      const currentProviderName = params.get('ssoProviderName');
      if (input.ssoProvider && !currentProviderName) {
        const redirectTo = params.get('redirectTo');
        sso.signIn('flashos', input.ssoProvider.providerName, {
          redirectTo,
        });
        setIsLoadingSSO(true);
      } else {
        mutate(
          { employeeId: input.employeeId, companyId: input.companyId },
          {
            onError: () => {
              onErrorCallback();
              dispatchToast({
                content: 'Não foi possível obter seu acesso',
                type: 'error',
              });
            },
            onSuccess: async (data) => {
              ErrorBoundary.identifyUser(data.employeeId);
              setInLS({
                key: 'selectedCompany',
                value: {
                  id: input.companyId,
                  name: input.name,
                  registrationNumber: input.registrationNumber,
                },
              });
              setInLS({
                key: 'hrosAccessToken',
                value: {
                  accessToken: data.token,
                  employeeId: data.employeeId,
                },
              });
              setInLS({
                key: 'employee_permissions',
                value: data.permissions,
              });
              dispatchEvent(
                new CustomEvent('employee_permissions_updated', {
                  detail: data.permissions,
                }),
              );
              dispatchEvent(
                new CustomEvent('employee_access_selected', {
                  detail: data.token,
                }),
              );
              setPeopleContractModules(data.contractModules);

              await Promise.all([
                commutedLogin(input),
                getAccessToken({
                  companyId: input.companyId,
                  isAdmin: data.permissions.isAdmin,
                }),
                usePermissionsFlags
                  .getState()
                  .getPermissions(input.companyId, data.employeeId),
              ]);

              callback();
            },
          },
        );
      }
    },
    [mutate, callback, onErrorCallback],
  );

  const isLoading = isLoadingQuery || permissionsFlagsLoading || isLoadingSSO;

  return { token: data?.token, getToken, isLoading };
};

export const useHideUserAccess = () => {
  const { mutate, isLoading } = trpc.hideAccess.useMutation();

  const hideUserAccess = ({
    hide,
    employeeId,
  }: {
    hide: boolean;
    employeeId: string;
  }) => {
    mutate({ employeeId, hide });
  };

  return { hideUserAccess, isLoading };
};
