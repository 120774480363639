import styled from 'styled-components';
import { Modal } from '@flash-tecnologia/hros-web-ui-v2';

export const ModalContent = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
  margin: ${({ theme }) => theme.spacings.xs};
  align-items: center;
  text-align: center;

  @media screen and (max-width: 640px) {
    margin: 0;
    padding: ${({ theme }) => theme.spacings.s};
  }
`;
