import { SessionUserModel } from '@auth/models/SessionUserModel';
import { createContext, useContext } from 'react';

type Credentials = { username: string; password: string };

export type LoginSteps =
  | 'PASSWORD'
  | 'MFA'
  | 'UPDATE_PHONE_NUMBER'
  | 'VERIFY_PHONE_NUMBER'
  | 'VERIFY_EMAIL'
  | 'VERIFY_DATA_LANDING';

export const LoginContext = createContext<{
  setCredentials: (credentials: Credentials) => void;
  credentials: Credentials;
  user: SessionUserModel | null;
  setUser: (user: SessionUserModel | null) => void;
  step: LoginSteps;
  setStep: (step: LoginSteps) => void;
  adminHasToVerifyEmail: boolean;
  setAdminHasToVerifyEmail: (value: boolean) => void;
}>({
  credentials: { username: '', password: '' },
  user: null,
  step: 'PASSWORD',
  setStep: () => {},
  setCredentials: () => {},
  setUser: () => {},
  adminHasToVerifyEmail: false,
  setAdminHasToVerifyEmail: () => {},
});

export function useLoginContext() {
  return useContext(LoginContext);
}
