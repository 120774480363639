import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import {
  PageContainer,
  ContentsContainer,
  FirstMessage,
  SubTitle,
  CardContainer,
  IconContainer,
  ButtonsContainer,
  LinkButtonStyled,
} from './styles';
import { Footer, Header } from '@components/Common';
import { useAuthNavigation } from 'src/routes';

export const ResendConfirmationSuccessMessage = () => {
  const navigate = useAuthNavigation();
  return (
    <PageContainer>
      <Header />
      <ContentsContainer>
        <CardContainer>
          <IconContainer>
            <Icons
              name={'IconMoodSmileBeam'}
              fill={'none'}
              color={'#FE2B8F'}
              size={80}
              style={{ margin: '0 auto' }}
            />
          </IconContainer>
          <FirstMessage variant="body3" style={{ marginBottom: '20px' }}>
            Seu convite foi reenviado!
          </FirstMessage>
          <SubTitle variant="body3" style={{ marginBottom: '10px' }}>
            Confira seu e-mail ou celular de cadastro.
          </SubTitle>
          <SubTitle variant="body3" style={{ marginBottom: '20px' }}>
            Você irá receber uma comunicação via e-mail ou SMS. Não esqueça de
            verificar a caixa de spam do seu e-mail.
          </SubTitle>
          <SubTitle variant="body3" style={{ marginBottom: '16px' }}>
            Caso não tenha recebido a comunicação, entre em contato com o seu
            RH.
          </SubTitle>
          <ButtonsContainer>
            <LinkButtonStyled
              variant="default"
              onClick={() => {
                navigate('LOGIN');
              }}
            >
              Voltar
            </LinkButtonStyled>
          </ButtonsContainer>
        </CardContainer>
      </ContentsContainer>
      <Footer />
    </PageContainer>
  );
};
