import { PageContainer } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Header = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.xs}`};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContentContainer = styled(PageContainer)`
  && {
    overflow: auto;
    height: 100%;
    flex-direction: row;
    align-items: center;
    gap: 140px;

    @media screen and (max-width: 1200px) {
      padding: 0 40px;
      gap: 70px;
    }

    @media screen and (max-height: 900px) {
      align-items: normal;
    }
  }
`;

export const ImageContainer = styled.div<{ imageUrl: string }>`
  background-image: url(${({ imageUrl }) => imageUrl});
  width: 420px;
  height: 565px;
  min-width: 420px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 40px;
  margin-top: ${({ theme }) => theme.spacings.s};
  cursor: pointer;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const TopicsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs};
  padding-bottom: ${({ theme }) => theme.spacings.s};

  @media screen and (min-width: 700px) {
    height: 100%;

    & > div {
      height: 100%;
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs4};
  margin-bottom: ${({ theme }) => theme.spacings.s};
  padding-top: ${({ theme }) => theme.spacings.s};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.xs}`};
`;
