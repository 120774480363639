import { useState, useCallback, useEffect, useMemo } from 'react';
import { IconButton, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import {
  CardIconOptions,
  SectionLoading,
} from '../../../../../../components/Common';

import {
  useUserAccessesQuery,
  useHideUserAccess,
  useGetAccessTokenQuery,
} from '../../../../hooks';

import { AccessOption } from '../../../../types';

import {
  CardsButtonContainer,
  CardsContainer,
  ButtonsContainer,
  LinkButtonStyled,
  DeactivatedAccessesContainer,
  AccessesLinkButtonContainer,
} from './styles';

export const AccessSelectionList = ({
  preSelectedAccess,
  callback,
  onGoBack,
}: {
  preSelectedAccess?: AccessOption;
  callback: () => void;
  onGoBack?: () => void;
}) => {
  const [selectedCard, setSelectedAccess] = useState<AccessOption | undefined>(
    undefined,
  );
  const { accesses: allAccesses, isLoading: isLoadingAccesses } =
    useUserAccessesQuery();

  const { getToken } = useGetAccessTokenQuery({
    callback: () => {
      setSelectedAccess(undefined);
      callback();
    },
    onErrorCallback: useCallback(() => {
      setSelectedAccess(undefined);
    }, []),
  });

  const { hideUserAccess } = useHideUserAccess();
  const [accesses, setAccesses] = useState<typeof allAccesses>([]);
  const [showHiddenAccesses, setShowHiddenAccesses] = useState(false);

  const shownAccesses = useMemo(
    () => accesses?.filter((access) => !access.hidden),
    [accesses],
  );
  const hiddenAccesses = useMemo(
    () => accesses?.filter((access) => access.hidden),
    [accesses],
  );

  const handleSelectedAccess = useCallback((access: AccessOption) => {
    setSelectedAccess(access);
    getToken(access);
  }, []);

  useEffect(() => {
    if (preSelectedAccess) {
      handleSelectedAccess(preSelectedAccess);
    }
  }, [preSelectedAccess]);

  useEffect(() => {
    setAccesses(allAccesses);
  }, [allAccesses]);

  const toggleHideCompany = useCallback(
    ({ hidden, employeeId }: { hidden: boolean; employeeId: string }) =>
      () => {
        hideUserAccess({ hide: !hidden, employeeId });
        setAccesses((prev) => {
          return prev?.map((access) => {
            if (access.employeeId === employeeId) {
              return { ...access, hidden: !hidden };
            }
            return access;
          });
        });
      },
    [hideUserAccess],
  );

  return (
    <CardsButtonContainer>
      <CardsContainer>
        {isLoadingAccesses || preSelectedAccess ? (
          <SectionLoading />
        ) : (
          (shownAccesses || []).map((access) => (
            <CardIconOptions
              key={access.companyId}
              iconName="IconBuildingSkyscraper"
              title={access.name}
              subTitle={`CNPJ: ${access.registrationNumber}`}
              selected={selectedCard?.companyId === access.companyId}
              loading={selectedCard?.companyId === access.companyId}
              deactivated={
                selectedCard !== undefined &&
                selectedCard?.companyId !== access.companyId
              }
              enabled={selectedCard === undefined}
              onClick={() => handleSelectedAccess(access)}
              icon={{
                tooltip: {
                  title: 'Ocultar empresa',
                },
                element: (
                  <IconButton
                    onClick={toggleHideCompany(access)}
                    size="medium"
                    icon="IconEyeOff"
                    variant="line"
                  />
                ),
              }}
            />
          ))
        )}
      </CardsContainer>
      {!!hiddenAccesses?.length && (
        <>
          <AccessesLinkButtonContainer>
            <LinkButton
              variant="neutral"
              onClick={() => {
                setShowHiddenAccesses((prev) => !prev);
              }}
            >
              {showHiddenAccesses
                ? 'Fechar empresas ocultas'
                : 'Mostrar empresas ocultas'}
            </LinkButton>
          </AccessesLinkButtonContainer>

          <DeactivatedAccessesContainer show={showHiddenAccesses}>
            {(hiddenAccesses || []).map((access) => (
              <CardIconOptions
                deactivated={selectedCard?.companyId !== access.companyId}
                key={access.companyId}
                iconName="IconBuildingSkyscraper"
                title={access.name}
                subTitle={`CNPJ: ${access.registrationNumber}`}
                selected={selectedCard?.companyId === access.companyId}
                loading={selectedCard?.companyId === access.companyId}
                enabled={selectedCard === undefined}
                onClick={() => handleSelectedAccess(access)}
                icon={{
                  tooltip: {
                    title: 'Ativar empresa',
                  },
                  element: (
                    <IconButton
                      onClick={toggleHideCompany(access)}
                      size="medium"
                      icon="IconEye"
                      variant="line"
                    />
                  ),
                }}
              />
            ))}
          </DeactivatedAccessesContainer>
        </>
      )}
      <ButtonsContainer>
        {onGoBack ? (
          <LinkButtonStyled variant="default" onClick={onGoBack}>
            Sair
          </LinkButtonStyled>
        ) : (
          <div></div>
        )}
      </ButtonsContainer>
    </CardsButtonContainer>
  );
};
