import CreateAccount from '@/assets/create_account.png';
import { Icons, Button, TextField } from '@flash-tecnologia/hros-web-ui-v2';
import { useAuthNavigation } from '@/routes';
import { setEventTracking } from '@/utils/analytics/segment';
import { Footer, Header } from '@/components/Common';
import {
  ContentContainer,
  Divider,
  GreetingsText,
  LeftContainer,
  PageContainer,
  RightContainer,
  SubTitle,
  TextsContainer,
  Title,
  Wrapper,
} from '../Login/components/styles';
import {
  BackButtonContainer,
  ButtonContainer,
  TextFieldContainer,
} from './styles';
import { useSSOForm } from './hooks';

export const PageSSO = () => {
  const navigate = useAuthNavigation();
  const { form, isLoading } = useSSOForm();

  const handleGoToLogin = () => {
    setEventTracking('signin_sso_gotologin_button_clicked');
    navigate('LOGIN');
  };

  return (
    <PageContainer>
      <Header />
      <ContentContainer>
        <Wrapper>
          <LeftContainer>
            <TextsContainer>
              <GreetingsText variant="body3">Login com SSO</GreetingsText>
              <Title variant="headline5">Acesse a sua empresa</Title>
              <SubTitle variant="body3">
                Use os dados de login único da sua empresa para acessar.
              </SubTitle>
            </TextsContainer>
            <form onSubmit={form.handleSubmit}>
              <TextFieldContainer>
                <TextField
                  label="E-mail empresarial"
                  id="email"
                  name="email"
                  autoComplete="off"
                  fullWidth={true}
                  value={form.values.email}
                  onChange={form.handleChange}
                  error={form.touched.email && Boolean(form.errors.email)}
                  helperText={form.touched.email && form.errors.email}
                />
              </TextFieldContainer>
              <ButtonContainer>
                <Button
                  variant="primary"
                  size="large"
                  type="submit"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Acessar <Icons name="IconArrowRight" />
                </Button>
              </ButtonContainer>
            </form>
            <Divider />
            <BackButtonContainer>
              <Button
                size="large"
                variant="secondary"
                type="button"
                onClick={handleGoToLogin}
              >
                <Icons name="IconArrowLeft" />
                Acessar com login e senha
              </Button>
            </BackButtonContainer>
          </LeftContainer>
          <RightContainer imageUrl={CreateAccount} />
        </Wrapper>
      </ContentContainer>
      <Footer />
    </PageContainer>
  );
};
