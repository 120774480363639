import styled from 'styled-components';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';

export const Container = styled.div`
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  text-align: center;
`;

export const SearchResultsText = styled(Typography)`
  max-width: 512px;
  text-align: center;
`;
