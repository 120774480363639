import { CognitoError } from './models/CognitoError';
import { CognitoErrorCodes } from './models/CognitoErrorCodes';
import { CognitoErrors } from './CognitoErrors';

export const cognitoErrorHandler = (e: CognitoError): Error => {
  switch (e.code) {
    case 'UserNotFoundException':
      return CognitoErrors[CognitoErrorCodes.UserNotFoundException];
    case 'CodeMismatchException':
      return CognitoErrors[CognitoErrorCodes.CodeMismatchException];
    case 'LimitExceededException':
      return CognitoErrors[CognitoErrorCodes.LimitExceededException];
    case 'NotAuthorizedException':
      if (e.message === 'Incorrect username or password.') {
        return CognitoErrors[CognitoErrorCodes.NotAuthorizedException];
      }
      if (
        e.message ===
          'Too many invalid credentials attempts. User temporarily locked. Please try again after few seconds.' ||
        e.message === 'Password attempts exceeded'
      ) {
        return CognitoErrors[CognitoErrorCodes.LimitExceededException];
      }
      return CognitoErrors[CognitoErrorCodes.ExpiredSessionException];
    case 'SmsCodeMismatchException':
      return CognitoErrors[CognitoErrorCodes.SmsCodeMismatchException];
    case 'AliasExistsException':
      return CognitoErrors[CognitoErrorCodes.AliasExistsException];
    case 'NetworkError':
      return CognitoErrors[CognitoErrorCodes.NetworkError];
    default:
      return CognitoErrors[CognitoErrorCodes.DefaultException];
  }
};
