import { useState } from 'react';
import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { CardIconOptions } from '../../../../components/Common';
import {
  GreetingsText,
  Title,
  SubTitle,
  TextsContainer,
  CardsButtonContainer,
  CardsContainer,
  ButtonsContainer,
  LinkButtonStyled,
  StyledButton,
} from './styles';
import { useSendPasswordRecoveryCode } from './hooks';
import { RECOVERY_CHANNEL } from '@auth/IAuthProvider';
import { usePasswordRecoveryContext } from '../../passwordRecoveryContext';

export const ChooseRecoveryChannelStep = () => {
  const { sendCode, isLoading } = useSendPasswordRecoveryCode();
  const { setStep, step } = usePasswordRecoveryContext();
  const [selectedCard, setSelectedCard] = useState<RECOVERY_CHANNEL>();

  return (
    <>
      <TextsContainer>
        <GreetingsText variant="body3">Olá!</GreetingsText>
        <Title variant="headline5">
          Como deseja receber seu código de recuperação de senha?
        </Title>
        <SubTitle variant="body3">
          Após acessar o link, você poderá definir uma nova senha.
        </SubTitle>
      </TextsContainer>
      <CardsButtonContainer>
        <CardsContainer>
          <CardIconOptions
            iconName={'IconMail'}
            title={'Receber por E-mail'}
            subTitle={'Receba o link de recuperação no seu e-mail de cadastro.'}
            selected={selectedCard === 'EMAIL'}
            onClick={() => setSelectedCard('EMAIL')}
          />
          <CardIconOptions
            iconName={'IconDeviceMobile'}
            title={'Receber por SMS'}
            subTitle={
              'Receba o link de recuperação no seu celular de cadastro.'
            }
            selected={selectedCard === 'SMS'}
            onClick={() => setSelectedCard('SMS')}
          />
        </CardsContainer>
        <ButtonsContainer>
          <LinkButtonStyled variant="default" onClick={() => setStep(step - 1)}>
            Voltar
          </LinkButtonStyled>
          <StyledButton
            variant="primary"
            size="large"
            disabled={isLoading || !selectedCard}
            loading={isLoading}
            onClick={() => selectedCard && sendCode(selectedCard)}
          >
            Continuar <Icons name="IconArrowRight" />
          </StyledButton>
        </ButtonsContainer>
      </CardsButtonContainer>
    </>
  );
};
