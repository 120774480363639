import {
  Icons,
  Typography,
  LinkButton,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  Wrapper,
  ButtonsContainer,
  TextContainer,
  StyledShapeIcon,
} from './styles';
import { useLoginRecoveryContext } from '../../loginRecoveryContext';
import { useResendLoginRecoveryEmail } from './hooks';
import { setEventTracking } from '@utils/analytics/segment';
import { useAuthNavigation } from 'src/routes';

export const LoginEmailSentStep = () => {
  const navigate = useAuthNavigation();
  const { email } = useLoginRecoveryContext();
  const { resendEmail } = useResendLoginRecoveryEmail();

  const handleResendEmail = async () => {
    setEventTracking('signin_forgotlogin_success_resendemail_button_clicked', {
      email,
    });
    await resendEmail();
  };

  const onBack = () => {
    setEventTracking('signin_forgotlogin_success_back_button_clicked', {
      email,
    });
    navigate('LOGIN');
  };

  return (
    <Wrapper>
      <TextContainer>
        <StyledShapeIcon
          name="IconMoodSmileBeam"
          variant="default"
          color="var(--color-primary)"
          size={84}
        />
        <Typography
          variant="headline7"
          variantColor="var(--color-secondary-50)"
        >
          Enviamos os dados de recuperação para o e-mail informado
        </Typography>
        <Typography variant="body3" variantColor="var(--color-neutral-40)">
          Caso exista uma conta associada à <b>{email}</b>, enviaremos um e-mail
          com as instruções para a recuperação das suas informações de acesso.
        </Typography>
        <Typography variant="body3" variantColor="var(--color-neutral-40)">
          Não recebeu? Verifique sua lixeira e caixa de spam ou reenvie o
          e-mail.
        </Typography>
        <ButtonsContainer>
          <LinkButton variant="default" onClick={onBack}>
            Retornar ao login
          </LinkButton>
          <LinkButton variant="default" onClick={handleResendEmail}>
            Reenviar e-mail <Icons name="IconMail" />
          </LinkButton>
        </ButtonsContainer>
      </TextContainer>
    </Wrapper>
  );
};
