import { useEffect } from 'react';
import { setPageTracking } from '../segment';

export const usePageTracking = (
  name: string,
  params?: Record<string, string>,
) => {
  useEffect(() => {
    setPageTracking(name, params);
  }, []);
};
