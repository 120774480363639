import {
  Icons,
  PillButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  PillButtonWrapper,
  LabelContainer,
  HiddenAccessesCounter,
} from './styles';

type HiddenAccessButtonProps = {
  labelText: string;
  isHovered: boolean;
  showHiddenAccesses: boolean;
  handleSetShowHiddenAccesses: () => void;
  hiddenAccessCounter: number | null;
  setIsHovered: (hover: boolean) => void;
};

export const HiddenAccessButton = ({
  labelText,
  isHovered,
  showHiddenAccesses,
  handleSetShowHiddenAccesses,
  hiddenAccessCounter,
  setIsHovered,
}: HiddenAccessButtonProps) => {
  const onHover = () => {
    setIsHovered(true);
  };

  const onLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      data-onboarding="authentication-header-hidable-icon"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      <PillButtonWrapper $selected={showHiddenAccesses} $hovered={isHovered}>
        {isHovered ? (
          <LabelContainer
            onClick={() => {
              handleSetShowHiddenAccesses();
            }}
          >
            <Typography variant="caption" weight={700}>
              {`${labelText}`}
            </Typography>
            <Icons
              name={showHiddenAccesses ? 'IconEye' : 'IconEyeOff'}
              size={16}
              fill="transparent"
            />
          </LabelContainer>
        ) : (
          <PillButton
            onClick={() => {
              handleSetShowHiddenAccesses();
            }}
            type="secondary"
            variant="default"
            size="small"
            icon={showHiddenAccesses ? 'IconEye' : 'IconEyeOff'}
          />
        )}
        {!showHiddenAccesses &&
        hiddenAccessCounter &&
        hiddenAccessCounter > 0 ? (
          <HiddenAccessesCounter $hovered={isHovered}>
            {hiddenAccessCounter}
          </HiddenAccessesCounter>
        ) : null}
      </PillButtonWrapper>
    </div>
  );
};
