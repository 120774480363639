import styled from 'styled-components';
import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';

export const AccessesLinkButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const DeactivatedAccessesContainer = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 80px;
  gap: 16px;
`;

export const CardsButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 870px;
  margin-top: 40px;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 82px;
`;

export const LinkButtonStyled = styled(LinkButton)`
  && {
    &.MuiButtonBase-root {
      align-self: center;
    }
  }
`;
