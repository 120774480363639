import { useEffect, useState } from 'react';
import { PasswordStep } from './components/PasswordStep/PasswordStep';
import { MFAStep } from './components/MFAStep/MFAStep';
import { SessionUserModel } from '@auth/models/SessionUserModel';
import { UpdatePhoneNumberStep } from './components/UpdatePhoneNumberStep/UpdatePhoneNumberStep';
import { VerifyPhoneNumberStep } from './components/VerifyPhoneNumberStep/VerifyPhoneNumberStep';
import { LoginContext, LoginSteps } from './loginContext';
import { VerifyEmailStep } from './components/VerifyEmailStep/VerifyEmailStep';
import { VerifyDataLandingStep } from './components/VerifyDataLandingStep/VerifyDataLandingStep';
import { Auth } from '@flash-tecnologia/hros-web-utility';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const PageLogin = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [user, setUser] = useState<SessionUserModel | null>(null);
  const [adminHasToVerifyEmail, setAdminHasToVerifyEmail] =
    useState<boolean>(false);
  const [credentials, setCredentials] = useState<{
    username: string;
    password: string;
  }>({ username: '', password: '' });
  const [step, setStep] = useState<LoginSteps>('PASSWORD');

  useEffect(() => {
    const checkUserAuthenticated = async () => {
      try {
        const currentSession = await Auth.currentSession();
        const redirectUrl = searchParams.get('redirectTo');
        if (currentSession) {
          if (redirectUrl) {
            return navigate(decodeURIComponent(redirectUrl));
          } else {
            return navigate('/home');
          }
        }
      } catch {}
    };
    checkUserAuthenticated();
  }, []);

  return (
    <LoginContext.Provider
      value={{
        user,
        credentials,
        setCredentials,
        setUser,
        step,
        setStep,
        adminHasToVerifyEmail,
        setAdminHasToVerifyEmail,
      }}
    >
      {renderStep(step)}
    </LoginContext.Provider>
  );
};

const renderStep = (step: LoginSteps) => {
  switch (step) {
    case 'PASSWORD':
      return <PasswordStep />;
    case 'MFA':
      return <MFAStep />;
    case 'UPDATE_PHONE_NUMBER':
      return <UpdatePhoneNumberStep />;
    case 'VERIFY_PHONE_NUMBER':
      return <VerifyPhoneNumberStep />;
    case 'VERIFY_EMAIL':
      return <VerifyEmailStep />;
    case 'VERIFY_DATA_LANDING':
      return <VerifyDataLandingStep />;
  }
};
