import { IconButton, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import * as SC from './styles';
import { useTheme } from '@mui/material';
import { useState } from 'react';

export const DataAlert = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  if (!open) return null;
  return (
    <SC.Container>
      <SC.TitleContainer>
        <Icons
          name="IconInfoCircle"
          color={theme.colors.feedback.info.dark1}
          fill="transparent"
        />
        <SC.Title>Atenção</SC.Title>
        <SC.Spacer />
        <IconButton
          icon="IconX"
          onClick={() => setOpen(false)}
          variant="line"
          color="blue"
          size="small"
        />
      </SC.TitleContainer>
      <SC.Description>
        Estes dados são disponibilizados por terceiros. A Flash não guarda as
        informações dos questionários.
      </SC.Description>
    </SC.Container>
  );
};
