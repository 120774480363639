import React from 'react';
import { CardContainer, Title, SubTitle, IconButtonStyled } from './styles';
import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2';

type CardProps = {
  iconName: IconTypes;
  title: string;
  selected?: boolean;
  subTitle: string;
  maxWidth: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  value?: string | number | readonly string[];
};

export const CardWithIcon = ({
  iconName,
  title,
  subTitle,
  maxWidth,
  selected = false,
  onClick,
  value,
}: CardProps) => {
  return (
    <CardContainer selected={selected} onClick={onClick} value={value}>
      <IconButtonStyled size="large" icon={iconName} variant="default" />
      <Title variant="headline7">{title}</Title>
      <SubTitle variant="body3" maxWidth={maxWidth}>
        {subTitle}
      </SubTitle>
    </CardContainer>
  );
};
