import { FormikErrors, useFormik } from 'formik';
import * as yup from 'yup';
import { dispatchToast } from '@utils';
import { useAuthContext } from '@auth/hooks/useAuthContext';
import { useState } from 'react';
import { useLoginContext } from '../../loginContext';
import { setEventTracking } from '@utils/analytics/segment';
import { hideDocumentNumber } from '@utils/masks/hideDocumentNumber';

const PHONE_NUMBER_LENGHT = 11;

export const useUpdatePhoneNumberForm = () => {
  const auth = useAuthContext();
  const { setUser, setStep } = useLoginContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const validationSchema = yup.object({
    phoneNumber: yup
      .string()
      .required('Favor digitar um número de celular válido'),
  });

  const form = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: FormikErrors<typeof values> = {};

      const phoneNumberWithoutSpecials = values.phoneNumber?.replace(/\D/g, '');

      if (phoneNumberWithoutSpecials.length < PHONE_NUMBER_LENGHT) {
        errors.phoneNumber = 'Favor digitar um número de celular válido';
      }

      return errors;
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const user = await auth.currentAuthenticatedUser({ bypassCache: true });
        const newPhoneNumber = `+55${values.phoneNumber.replace(/\D/g, '')}`;
        setEventTracking('signin_loginmfa_update_password_submit_clicked', {
          document_number: hideDocumentNumber(user.username),
          new_phone_number: newPhoneNumber,
        });
        if (user.attributes?.phoneNumber === newPhoneNumber) {
          await auth.sendPhoneNumberVerificationCode();
        } else {
          await auth.updateCurrentUserAttributes({
            phoneNumber: `+55${values.phoneNumber.replace(/\D/g, '')}`,
          });
        }
        setStep('VERIFY_PHONE_NUMBER');
        setUser(user);
      } catch (error) {
        dispatchToast({
          type: 'error',
          content: error.message,
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  return { form, isLoading };
};
