import { trpc } from '@api/client';
import { dispatchToast, validateCPF } from '@utils';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as yup from 'yup';

const validationSchema = yup.object({
  cpfNumber: yup.string().required('Favor digitar um CPF'),
});

export const useResendInvitationForm = () => {
  const [success, setSuccess] = useState<boolean>(false);
  const { mutate, isLoading } = trpc.resendInvitationToken.useMutation({
    onSuccess: () => {
      setSuccess(true);
    },
    onError: () => {
      dispatchToast({
        type: 'error',
        content:
          'Ops! Ocorreu um erro ao reenviar seu código. Por favor, tente novamente mais tarde.',
      });
    },
  });
  const form = useFormik({
    initialValues: {
      cpfNumber: '',
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: { cpfNumber?: string } = {};
      if (values.cpfNumber && !validateCPF(values.cpfNumber))
        errors.cpfNumber = 'Favor digitar um CPF válido.';

      return errors;
    },
    onSubmit: (values) => {
      mutate({ username: values.cpfNumber.replace(/\D/g, '') });
    },
  });

  return { form, isLoading, success };
};
