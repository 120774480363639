import styled, { css } from 'styled-components';
import { ButtonBase } from '@mui/material';

export const LabelContainer = styled(ButtonBase)`
  display: flex;
  flex-direction: row;
  width: 220px;
  height: 42px;
  border: 1px solid;
  padding: 12px;
  gap: 8px;
  background: transparent;
  border-radius: 40px;
  justify-content: space-between;
  align-items: center;
  border-color: var(--color-neutral-90);
`;

export const HiddenAccessesCounter = styled.div<{
  $hovered?: boolean;
}>`
  color: #ffffff;
  background-color: #fe2b8f;
  width: 16px;
  height: 16px;
  font-size: 8px;
  border-radius: 100px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: 15px;
`;

export const PillButtonWrapper = styled.div<{
  $hovered?: boolean;
  $selected?: boolean;
}>`
  display: flex;
  flex-direction: row;
  && > .pillButton-custom-theme-line.MuiButtonBase-root {
    ${({ $selected, theme }) =>
      $selected &&
      css`
        background-color: ${theme.colors.secondary[90]};
        border-color: ${theme.colors.secondary[90]};
      `}
  }
`;
