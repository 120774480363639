import { trpc } from '@api/client';
import * as yup from 'yup';
import { useLoginRecoveryContext } from '../../loginRecoveryContext';
import { dispatchToast } from '@utils';
import { useFormik } from 'formik';
import { setEventTracking } from '@utils/analytics/segment';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Confira o endereço de e-mail digitado e tente novamente.')
    .required('Email é obrigatório.'),
});

export const useLoginRecoveryForm = () => {
  const { setEmail, setStep } = useLoginRecoveryContext();
  const { mutate, isLoading: loading } = trpc.sendForgotLoginEmail.useMutation({
    onSuccess: () => {
      setStep('login_recovery_success');
    },
    onError: () => {
      dispatchToast({
        type: 'error',
        content: 'Ops! Erro ao recuperar seu login, favor tentar novamente.',
      });
    },
  });

  const form = useFormik({
    initialValues: { email: '' },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setEventTracking('signin_loginrecovery_submit_button_clicked', {
        email: values.email,
      });
      mutate({ email: values.email });
      setEmail(values.email);
    },
  });

  return { form, loading };
};
