import { trpc } from '@api/client';
import { useLoginRecoveryContext } from '../../loginRecoveryContext';
import { dispatchToast } from '@utils';
import { setEventTracking } from '@utils/analytics/segment';

export const useResendLoginRecoveryEmail = () => {
  const { email } = useLoginRecoveryContext();
  const { mutate, isLoading: loading } = trpc.sendForgotLoginEmail.useMutation({
    onSuccess: () => {
      dispatchToast({
        type: 'success',
        content: 'Email reenviado!',
      });
    },
    onError: () => {
      dispatchToast({
        type: 'error',
        content: 'Ops! Erro ao reenviar o email, favor tentar novamente!',
      });
    },
  });

  const resendEmail = async () => {
    setEventTracking('signin_loginrecovery_resendcode_button_cliked', {
      email,
    });
    mutate({ email });
  };

  return { resendEmail, loading };
};
