import styled from 'styled-components';
import { Typography, Button } from '@flash-tecnologia/hros-web-ui-v2';

export const GreetingsText = styled(Typography)`
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 574px;
  padding-bottom: 10px;
`;

export const Title = styled(Typography)``;

export const SubTitle = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  }
`;

export const StyledButton = styled(Button)`
  && {
    &.MuiButtonBase-root.size-large {
      padding: 19px 46px;

      @media screen and (max-width: 420px) {
        margin: 0 auto;
      }
    }
  }
`;
