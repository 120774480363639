import { CognitoErrorCodes } from './models/CognitoErrorCodes';
export const CognitoErrors: Record<CognitoErrorCodes, Error> = {
  [CognitoErrorCodes.NotAuthorizedException]: {
    name: CognitoErrorCodes.NotAuthorizedException,
    message: 'Usuário ou senha inválidos',
  },
  [CognitoErrorCodes.UserNotFoundException]: {
    name: CognitoErrorCodes.UserNotFoundException,
    message: 'Usuário ou senha inválidos',
  },
  [CognitoErrorCodes.CodeMismatchException]: {
    name: CognitoErrorCodes.CodeMismatchException,
    message: 'Código inválido',
  },
  [CognitoErrorCodes.LimitExceededException]: {
    name: CognitoErrorCodes.LimitExceededException,
    message: 'Limite de tentativas excedido. Tente novamente mais tarde.',
  },
  [CognitoErrorCodes.ExpiredSessionException]: {
    name: CognitoErrorCodes.ExpiredSessionException,
    message: 'Sessão expirada',
  },
  [CognitoErrorCodes.DefaultException]: {
    name: CognitoErrorCodes.DefaultException,
    message: 'Ocorreu um erro. Por favor, tente novamente',
  },
  [CognitoErrorCodes.SmsCodeMismatchException]: {
    name: CognitoErrorCodes.SmsCodeMismatchException,
    message: 'Código inválido',
  },
  [CognitoErrorCodes.AliasExistsException]: {
    name: CognitoErrorCodes.AliasExistsException,
    message:
      'E-mail/Telefone já vinculado a outra conta, favor contatar o atendimento.',
  },
  [CognitoErrorCodes.NetworkError]: {
    name: CognitoErrorCodes.NetworkError,
    message: 'Erro: 403. Entre em contato com nosso time de atendimento.',
  },
};
