import { useFormik } from 'formik';
import * as yup from 'yup';
import { dispatchToast } from '@utils';
import { useLoginContext } from '../../loginContext';
import { setEventTracking } from '@utils/analytics/segment';
import { ErrorBoundary } from '@utils/ErrorBoundary';
import { trpc } from '@api/client';
import { useLogin } from '@auth/hooks/useLogin';
import { useState } from 'react';
import { useAuthNavigation } from 'src/routes';

const validationSchema = yup.object({
  otp: yup.string().min(6).required('Favor preencher os campos!'),
});

export const useVerifyEmailStepForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { credentials, setStep, setAdminHasToVerifyEmail } = useLoginContext();
  const navigate = useAuthNavigation();
  const { mutateAsync: validateAdminFirstAccessToken } =
    trpc.validateAdminFirstAccessToken.useMutation();
  const { login } = useLogin();

  /**
   * Validate the informed code
   * @param code code to be validated
   * @returns true | false
   */
  const validateAdminToken = async (code: string) => {
    try {
      const codeIsValid = await validateAdminFirstAccessToken({
        cpf: credentials.username,
        code: code,
      });
      return codeIsValid;
    } catch (e) {
      ErrorBoundary.captureException(e, 'error');
      return false;
    }
  };

  /**
   * Try to login user with the credentials provided previously
   * @returns signed user
   */
  const reSignInUser = async () => {
    try {
      const user = await login({
        login: credentials.username,
        password: credentials.password,
      });
      return user;
    } catch (e) {
      ErrorBoundary.captureMessage(
        `Confirmed e-mail using wrong credentials`,
        'info',
      );
      return undefined;
    }
  };

  const form = useFormik({
    initialValues: {
      otp: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      setEventTracking('signin_confirm_email_submit_button_cliked');

      const codeValidated = await validateAdminToken(values.otp);
      if (!codeValidated) {
        dispatchToast({
          type: 'error',
          content: 'Código inválido',
        });
        setIsLoading(false);
        return;
      }

      dispatchToast({
        type: 'success',
        content: 'E-mail confirmado com sucesso!',
      });
      setAdminHasToVerifyEmail(false);

      const user = await reSignInUser();
      if (!user) {
        dispatchToast({
          type: 'error',
          content: 'Senha inválida',
        });
        setStep('PASSWORD');
        setIsLoading(false);
        return;
      }

      if (user?.challenge?.name === 'SMS_MFA') {
        setStep('MFA');
      } else if (!user?.attributes?.phoneNumber) {
        setStep('UPDATE_PHONE_NUMBER');
      } else if (!user?.attributes.phoneNumberVerified) {
        setStep('VERIFY_PHONE_NUMBER');
      } else {
        navigate('ACCESS_SELECTION');
      }
      setIsLoading(false);
    },
  });

  return { form, isLoading };
};
