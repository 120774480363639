import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { dispatchToast, validateCPF } from '../../../../utils';
import { useAuthContext } from '@auth/index';
import { useState } from 'react';
import { useFirstAccessContext } from '../../firstAccessContext';
import { useSearchParams } from 'react-router-dom';
import { CustomMfa } from '@/auth/models/SessionUserModel';

export const useCreateAccountForm = () => {
  const { setStep, step, setFormData } = useFirstAccessContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const auth = useAuthContext();
  const [searchParams] = useSearchParams();
  const mfaMethod = searchParams.get('mfaMethod') as CustomMfa | undefined;

  const form = useFormik({
    initialValues: {
      name: '',
      documentNumber: '',
      email: '',
      phoneNumber: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: any = {};
      if (values.documentNumber && !validateCPF(values.documentNumber))
        errors.documentNumber = 'Favor digitar um CPF válido.';

      if (
        values.phoneNumber &&
        values.phoneNumber.replace(/\D/g, '').length < 11
      )
        errors.phoneNumber = 'Favor digitar um Telefone válido.';

      return errors;
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const username = uuidv4();
        await auth.signUp({
          password: values.password,
          phoneNumber: `+55${values.phoneNumber.replace(/\D/g, '')}`,
          name: values.name,
          email: values.email,
          username,
          customMfa: mfaMethod,
        });
        setFormData({ username, ...values });
        setStep(step + 1);
      } catch {
        dispatchToast({
          type: 'error',
          content: 'Ops! Erro ao processar dados, favor tentar novamente!',
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  return { form, isLoading };
};

const validationSchema = yup.object({
  name: yup.string().required('Favor digitar um nome'),
  documentNumber: yup.string().required('Favor digitar um CPF'),
  email: yup.string().email('Digite um email válido').notRequired(),
  phoneNumber: yup.string().required('Favor digitar um celular válido'),
  password: yup
    .string()
    .min(8)
    .matches(RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])((?=.*\W)|(?=.*_))^[^ ]+$/))
    .required('Favor digitar uma senha'),
  passwordConfirmation: yup
    .string()
    .required('Favor confirmar a sua senha')
    .oneOf([yup.ref('password'), null], 'As senhas precisam ser idênticas'),
});
