import { Modal, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
`;

export const Title = styled(Typography)`
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.neutral.black};
    text-align: center;
    margin-top: 16px;
  }
`;

export const Subtitle = styled(Typography)`
  && {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.neutral[60]};
    text-align: center;
    margin-top: 16px;
  }
`;

export const ModalContent = styled(Modal.Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.neutral[100]};
  background-color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  cursor: pointer;
  padding: 18px 22px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    opacity: 0.8;
  }
`;

export const LinkText = styled(Typography)`
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.neutral[100]};
    text-align: center;
  }
`;
