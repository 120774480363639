import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-width: 450px;
  max-width: 700px;
  border-radius: 12px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.neutral.light.light2};
  @media screen and (max-width: 890px) {
    margin-top: 8px;
    min-width: 0px;
  }
  @media screen and (max-width: 576px) {
    padding: 0px;
    border: none;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled(Typography).attrs({
  variant: 'headline8',
})`
  && {
    font-weight: 700;
  }
`;

export const Subitle = styled(Typography).attrs({
  variant: 'body3',
})`
  && {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
    margin-top: 4px;
  }
`;

export const Image = styled.img`
  width: 186px;
  height: 246px;
  margin-top: 32px;
  align-self: center;

  @media screen and (max-width: 576px) {
    height: 0px;
  }
`;
