import {
  Button,
  IconButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  CloseContainer,
  ModalBoxArea,
  Boxtitle,
  BoxIcon,
  StyledModal,
  LinkButtonStyled,
  FlexEnd,
} from './styles';
import { useAuthNavigation } from 'src/routes';

interface ModalProps {
  open: boolean;
  onClose?: () => any;
}

export const ModalFirstAccessFlash = ({
  open,
  onClose = () => {},
}: ModalProps) => {
  const navigate = useAuthNavigation();
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      header={
        <CloseContainer>
          <IconButton
            icon="IconX"
            onClick={onClose}
            variant="line"
            size="small"
          />
        </CloseContainer>
      }
    >
      <>
        <div style={{ maxWidth: '362px', marginBottom: '8px' }}>
          <Typography variant="headline6">
            É minha primeira vez usando Flash <span>🦩</span>
          </Typography>
        </div>
        <div style={{ maxWidth: '419px', marginBottom: '24px' }}>
          <Typography variant="body3">
            É o seu primeiro acesso na Flash e está com dúvidas? Confira abaixo
            algumas dicas para te ajudar:
          </Typography>
        </div>
        <div style={{ maxWidth: '503px' }}>
          <ModalBoxArea>
            <BoxIcon name="IconUserCheck" size={22} />
            <Boxtitle
              variant="headline8"
              color="primary"
              style={{ maxWidth: '249px' }}
            >
              Primeiro, sua empresa deve adquirir a Flash
            </Boxtitle>
            <div style={{ maxWidth: '296px' }}>
              <Typography variant="body4">
                Só é possível acessar a plataforma se sua empresa oferecer
                nossos benefícios.
                <br />
                <br />
                <b>Sua empresa ainda não tem Flash? 😱</b>
              </Typography>
            </div>
            <LinkButtonStyled variant="default">
              <Typography
                variant="body3"
                color="primary"
                style={{ fontWeight: 700 }}
              >
                Peça já para o seu RH
              </Typography>
            </LinkButtonStyled>
          </ModalBoxArea>
          <ModalBoxArea>
            <BoxIcon name="IconMail" size={22} />
            <Boxtitle
              variant="headline8"
              color="primary"
              style={{ maxWidth: '287px' }}
            >
              Sua empresa já é Flash? Agora é só aguardar seu convite chegar!
            </Boxtitle>
            <div style={{ maxWidth: '336px' }}>
              <Typography variant="body4">
                Assim que sua empresa te cadastrar, você vai receber uma
                comunicação via e-mail ou SMS.
                <br />
                <br />
                Aguarde esse convite para fazer seu cadastro. Se você já recebeu
                essa comunicação, basta acessar o link e seguir as instruções
                para criar sua conta!
                <br />
                <br />
                <b>Ocorreu algum problema no recebimento da sua comunicação?</b>
              </Typography>
            </div>
            <LinkButtonStyled variant="default">
              <Typography
                variant="body3"
                color="primary"
                style={{ fontWeight: 700 }}
                onClick={() => navigate('RESEND_INVITE')}
              >
                Receba o convite novamente
              </Typography>
            </LinkButtonStyled>
          </ModalBoxArea>
        </div>
        <FlexEnd>
          <Button variant="primary" size="large" onClick={onClose}>
            Fechar
          </Button>
        </FlexEnd>
      </>
    </StyledModal>
  );
};
