import React from 'react';
import {
  CardContainer,
  Title,
  SubTitle,
  CardWrapper,
  CardContent,
  IconContainer,
  IconButtonStyled,
} from './styles';
import {
  IconTypes,
  Icons,
  Tooltip,
  TooltipProps,
  Loader,
} from '@flash-tecnologia/hros-web-ui-v2';

type CardProps = {
  iconName: IconTypes;
  title: string;
  selected?: boolean;
  subTitle: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  value?: string | number | readonly string[];
  loading?: boolean;
  deactivated?: boolean;
  enabled?: boolean;
  icon?: {
    tooltip?: Partial<TooltipProps>;
    element: React.ReactNode;
  };
};

export const CardIconOptions = ({
  iconName,
  title,
  subTitle,
  selected = false,
  onClick,
  value,
  icon,
  loading,
  deactivated,
  enabled = true,
}: CardProps) => {
  return (
    <CardWrapper>
      <CardContainer
        disabled={!enabled}
        $deactivated={deactivated}
        selected={selected}
        onClick={onClick}
        value={value}
      >
        <CardContent>
          <IconButtonStyled
            $deactivated={deactivated}
            size="large"
            icon={iconName}
            variant="default"
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              alignItems: 'start',
            }}
          >
            <Title deactivated={deactivated} variant="headline7">
              {title}
            </Title>
            <SubTitle deactivated={deactivated} variant="body3">
              {subTitle}
            </SubTitle>
          </div>
        </CardContent>

        {loading ? (
          <Loader size="small" variant="primary" />
        ) : (
          enabled && (
            <Icons
              name="IconArrowRight"
              color={
                deactivated ? 'var(--color-neutral-50)' : 'var(--color-primary)'
              }
              size={32}
            />
          )
        )}
      </CardContainer>

      {!!icon && (
        <Tooltip
          title={icon.tooltip?.title ?? ''}
          placement={icon.tooltip?.placement ?? 'right'}
          {...(icon.tooltip && icon.tooltip)}
        >
          <IconContainer>{icon.element}</IconContainer>
        </Tooltip>
      )}
    </CardWrapper>
  );
};
