import { Icons, Modal, ShapeIcon } from '@flash-tecnologia/hros-web-ui-v2';
import * as SC from './styles';
import { useTheme } from 'styled-components';

interface ChangeEmailModalProps {
  showModal: boolean;
  onClose: () => void;
}

export const ChangeEmailModal = ({
  showModal,
  onClose,
}: ChangeEmailModalProps) => {
  const theme = useTheme();
  return (
    <Modal.Root open={showModal} onClose={onClose}>
      <>
        <SC.ModalContent>
          <ShapeIcon
            name="IconAlertCircle"
            variant="default"
            color={theme.colors.primary}
            size={64}
          />
          <SC.Title variant="headline6">
            Não tenho mais acesso a este e-mail
          </SC.Title>
          <SC.Subtitle variant="body3">
            Para alterar o endereço de e-mail cadastrado em sua conta Flash, é
            necessário preencher um formulário de identificação para o suporte
            Flash. Após o envio, nosso time entrará em contato para realizar a
            edição de forma segura.
          </SC.Subtitle>
        </SC.ModalContent>

        <SC.ModalFooter>
          <SC.StyledLink
            href="https://share.hsforms.com/1ESuuTCntQ_SHTf60VOVu4g3ja87"
            target="_blank"
          >
            <SC.LinkText variant="body3">Fazer pedido de alteração</SC.LinkText>
            <Icons name="IconExternalLink" />
          </SC.StyledLink>
        </SC.ModalFooter>
      </>
    </Modal.Root>
  );
};
