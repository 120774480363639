import * as SC from './styles';
import Flashlight from '../../../assets/flashlight.png';
import { DataAlert } from './DataAlert';

export const BigDataCorpFormInfo = () => {
  return (
    <SC.Container>
      <SC.ContentContainer>
        <SC.Title>Questionário de dados pessoais</SC.Title>
        <SC.Subitle>
          Para manter a segurança da sua conta, precisamos que você responda a
          um breve questionário de confirmação de dados pessoais*. Ele tem de 3
          a 5 perguntas sobre você, e dura cerca de 5 minutos para responder.
        </SC.Subitle>
        <SC.Image src={Flashlight} />
      </SC.ContentContainer>
      <DataAlert />
    </SC.Container>
  );
};
