import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';
import { getFromLS } from '@flash-tecnologia/hros-web-utility';
import { AccessSelectionList as NewAccessSelectionList } from './pages/AccessSelection/components/ChooseAccessV2/components/AccessSelectionList';
import { AccessSelectionList as OldAccessSelectionList } from './pages/AccessSelection/components/ChooseAccess/components/AccessSelectionList';
import { QueryClientProvider } from '@tanstack/react-query';
import { trpc, trpcClient, queryClient } from './api/client';

import '../src/i18n';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
});

export const { bootstrap, mount, unmount } = lifecycles;

function AccessSelection(props: { callback: () => void }) {
  const userPreferences = getFromLS('userPreferences');
  const currentVersion = userPreferences?.chooseAccessesVersion
    ? userPreferences.chooseAccessesVersion
    : 1;
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        {currentVersion === 2 ? (
          <NewAccessSelectionList callback={props.callback} />
        ) : (
          <OldAccessSelectionList callback={props.callback} />
        )}
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export { AccessSelection };
