export enum CognitoErrorCodes {
  NotAuthorizedException = 'NotAuthorizedException',
  UserNotFoundException = 'UserNotFoundException',
  CodeMismatchException = 'CodeMismatchException',
  LimitExceededException = 'LimitExceededException',
  ExpiredSessionException = 'ExpiredSessionException',
  DefaultException = 'DefaultException',
  SmsCodeMismatchException = 'SmsCodeMismatchException',
  AliasExistsException = 'AliasExistsException',
  NetworkError = 'NetworkError',
}
