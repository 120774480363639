import { IconContainer, Container, TextButton } from './styles';
import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { setEventTracking } from '@/utils/analytics/segment';
import { useChooseAccessesConfig } from '../../hooks';

export const NewPageAvailableBanner = () => {
  const { setCurrentVersion } = useChooseAccessesConfig();
  const handleClick = () => {
    setCurrentVersion(2);
    setEventTracking('access_page_v2_clicked');
    window.location.reload();
  };
  return (
    <Container>
      <IconContainer>
        <Icons
          name="IconSparkles"
          fill="none"
          width={16}
          height={16}
          color="var(--color-secondary-50)"
        />
      </IconContainer>
      <Typography
        variant="body3"
        variantColor="var(--color-secondary-50)"
        weight={600}
      >
        Essa tela vai mudar! Melhoramos a forma de acessar sua conta nas
        empresas e grupos.{' '}
        <TextButton onClick={handleClick}>
          <b> Conheça a nova versão e deixe seu feedback!</b>
        </TextButton>
      </Typography>
    </Container>
  );
};
