import { useEffect, useCallback } from 'react';
import { removeFromLS, removeFromSS } from '@flash-tecnologia/hros-web-utility';
import { Authentication } from '@flash-hros/auth-helper';
import { Outlet, useNavigate } from 'react-router-dom';
const { CognitoSignOut } = Authentication;

export const LogoutGuard = ({ children }: { children?: JSX.Element }) => {
  const navigate = useNavigate();
  const logoutHandler = useCallback(async () => {
    await CognitoSignOut({});
    removeFromSS({ key: 'userMFA' });
    removeFromLS({ key: 'userAuth' });
    removeFromLS({ key: 'hrosAccessToken' });
    navigate('/authentication/login', { replace: true });
  }, [navigate]);
  useEffect(() => {
    window.addEventListener('logout', logoutHandler);
    return () => {
      window.removeEventListener('logout', logoutHandler);
    };
  }, [logoutHandler]);
  return children ? children : <Outlet />;
};
