import { useState } from 'react';
import {
  LinkContainer,
  SearchContainer,
  SearchInputContainer,
  SearchIcon,
  SearchInput,
} from './styles';
import { AccessNotFoundModal } from '../../../AccessNotFoundModal';
import { HiddenAccessButton } from '../HiddenAccessButton';
import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';

interface AccessSelectionHeaderProps {
  handleSetShowHiddenAccesses: () => void;
  hiddenAccessCounter: number | null;
  search: string;
  setSearch: (search: string) => void;
  showHiddenAccesses: boolean;
}

export const AccessSelectionHeader = ({
  handleSetShowHiddenAccesses,
  hiddenAccessCounter,
  search,
  setSearch,
  showHiddenAccesses,
}: AccessSelectionHeaderProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const hiddenAccessLabel = showHiddenAccesses
    ? 'Esconder organizações ocultas'
    : 'Reexibir organizações ocultas';

  return (
    <>
      <LinkContainer data-onboarding="authentication-header-modal-link">
        <LinkButton
          onClick={() => setShowModal(true)}
          variant="default"
          size="small"
          type="button"
        >
          Não encontrou alguma empresa na lista?
        </LinkButton>
      </LinkContainer>
      {showModal && (
        <AccessNotFoundModal
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      <SearchContainer>
        <SearchInputContainer data-onboarding="authentication-header-search-container">
          <SearchIcon
            name="IconSearch"
            size={16}
            strokeWidth="3"
            color="var(--color-neutral-50)"
          />
          <SearchInput
            placeholder="Buscar empresa ou grupo por nome ou CNPJ"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </SearchInputContainer>
        <HiddenAccessButton
          labelText={hiddenAccessLabel}
          isHovered={isHovered}
          handleSetShowHiddenAccesses={handleSetShowHiddenAccesses}
          hiddenAccessCounter={hiddenAccessCounter}
          setIsHovered={setIsHovered}
          showHiddenAccesses={showHiddenAccesses}
        />
      </SearchContainer>
    </>
  );
};
