import { TextField, Icons } from '@flash-tecnologia/hros-web-ui-v2';
import {
  Wrapper,
  LeftContainer,
  RightContainer,
  GreetingsText,
  Title,
  SubTitle,
  TextsContainer,
  FieldContainer,
  FieldsContainer,
  ButtonsContainer,
  LinkButtonStyled,
  StyledButton,
} from './styles';
import ForgotPassword from '../../../../assets/forgot_password.png';
import { usePasswordRecoveryForm } from './hooks';
import { useAuthNavigation } from '../../../../routes';
import { setEventTracking } from '@utils/analytics/segment';
import { hideDocumentNumber } from '@utils/masks/hideDocumentNumber';

export const UsernameInputStep = () => {
  const navigate = useAuthNavigation();
  const { form } = usePasswordRecoveryForm();

  const handleBack = () => {
    setEventTracking('signin_forgotpassword_back_button_clicked', {
      document_number: hideDocumentNumber(form.values.login),
    });
    navigate('LOGIN');
  };

  return (
    <Wrapper>
      <LeftContainer>
        <TextsContainer>
          <GreetingsText variant="body3">
            Esqueceu a senha? Tudo bem, acontece!
          </GreetingsText>
          <Title variant="headline5">Recupere sua senha</Title>
          <SubTitle variant="body3">
            Insira algum dos seus dados de cadastro e siga as instruções para
            recuperar tudo em um flash <span>⚡</span>
          </SubTitle>
        </TextsContainer>
        <form onSubmit={form.handleSubmit}>
          <FieldsContainer>
            <FieldContainer>
              <TextField
                label="Insira seu CPF"
                id={'login'}
                name={'login'}
                autoComplete="off"
                value={form.values.login}
                onChange={form.handleChange}
                error={form.touched.login && Boolean(form.errors.login)}
                helperText={form.touched.login && form.errors.login}
              />
            </FieldContainer>
          </FieldsContainer>
          <ButtonsContainer>
            <LinkButtonStyled variant="default" onClick={() => handleBack()}>
              Voltar
            </LinkButtonStyled>
            <StyledButton variant="primary" size="large" type="submit">
              Continuar <Icons name="IconArrowRight" />
            </StyledButton>
          </ButtonsContainer>
        </form>
      </LeftContainer>
      <RightContainer imageUrl={ForgotPassword} />
    </Wrapper>
  );
};
