import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1366px;
  padding: 60px 136px 0 136px;
  width: 100%;
  margin: 0 auto;
  flex: 1;
  @media screen and (max-width: 992px) {
    padding: 60px 80px 0 80px;
  }
  @media screen and (max-width: 576px) {
    padding: 60px 24px 0 24px;
  }
`;
