import {
  Button,
  IconButton,
  TextField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';

import { CloseContainer, StyledModal, FlexEnd } from './styles';

interface ModalProps {
  open: boolean;
  onClose?: () => any;
}

export const ConfirmationStepModal = ({
  open,
  onClose = () => {},
}: ModalProps) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      header={
        <CloseContainer>
          <IconButton
            icon="IconX"
            onClick={onClose}
            variant="line"
            size="small"
          />
        </CloseContainer>
      }
    >
      <>
        <div style={{ maxWidth: '456px', marginBottom: '24px' }}>
          <Typography variant="headline6">Editar número de celular</Typography>
        </div>
        <div style={{ maxWidth: '483px', marginBottom: '40px' }}>
          <Typography variant="body3">
            Cadastrar um telefone válido é importante nos casos de recuperação
            de acesso e para manter a comunicação com a empresa onde você
            trabalha.
          </Typography>
        </div>
        <div style={{ maxWidth: '503px', marginBottom: '40px' }}>
          <TextField
            fullWidth
            label="Celular"
            imaskProps={{
              mask: '(00) 00000-0000',
              definitions: {
                '#': /[1-9]/,
              },
            }}
          />
        </div>
        <FlexEnd>
          <Button variant="primary" size="large" onClick={onClose}>
            Atualizar número
          </Button>
        </FlexEnd>
      </>
    </StyledModal>
  );
};
