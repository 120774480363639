import { Icons, Modal, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { ModalDescription, ModalImage, ModalTitle } from './styles';
import { useTheme } from 'styled-components';

interface AccessNotFoundModalProps {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}
export const AccessNotFoundModal = ({
  showModal,
  setShowModal,
}: AccessNotFoundModalProps) => {
  const theme = useTheme();
  return (
    <Modal.Root open={showModal} onClose={() => setShowModal(false)}>
      <>
        <ModalTitle>
          <Icons
            name="IconBuildingCommunity"
            size={12}
            color={theme.colors.secondary[50]}
          />
          <Typography
            variant="body4"
            variantColor={theme.colors.secondary[50]}
            weight={700}
          >
            Não encontrou alguma empresa?
          </Typography>
        </ModalTitle>
        <ModalDescription>
          <Typography variant="headline8">
            Confira o seletor no topo da plataforma
          </Typography>
          <Typography variant="body3">
            Caso esteja procurando as empresas que gerencia dentro de um grupo,
            acesse o seletor no canto superior esquerdo da plataforma para
            visualizar a lista completa dessas filiais.
          </Typography>
        </ModalDescription>
        <ModalImage imageUrl="https://images.flashapp.com.br/flash-os/authentication/find-company-instructions-modal.png" />
        <ModalDescription>
          <Typography variant="body3">
            Se mesmo assim você não achar alguma empresa específica, fale com
            nossa equipe de atendimento.
          </Typography>
        </ModalDescription>
      </>
    </Modal.Root>
  );
};
