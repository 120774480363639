import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
`;

const Content = styled.div`
  padding: 28px 136px;
  max-width: 1366px;
  margin: 0 auto;
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 992px) {
    padding: 28px 80px;
  }
`;

const ProgressBar = styled.div<{ width: string | number }>`
  position: absolute;
  bottom: 0;
  border-bottom: 3px solid ${({ theme }) => theme.colors.primary};
  width: ${({ width }) => width};
  transition: width 0.2s ease-in;
`;

export { Container, Content, ProgressBar };
