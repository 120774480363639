import { useState, useCallback } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Icons } from '@flash-tecnologia/hros-web-ui-v2';
import { CardWithIcon } from '../../../../components/Common';
import {
  GreetingsText,
  Title,
  SubTitle,
  TextsContainer,
  CardsButtonContainer,
  CardsContainer,
  ButtonsContainer,
  StyledButton,
  LinkButtonStyled,
} from './styles';
import { useValidateInvitationToken } from './hooks';
import { useFirstAccessContext } from '../../firstAccessContext';
import { useAuthNavigation } from 'src/routes';
import { Auth } from '@flash-tecnologia/hros-web-utility';

type accessType = 'oldAccess' | 'firstAccess' | null;

export const AreYouNewHereStep = () => {
  const [accessType, setAccessType] = useState<accessType>(null);
  const { step, setStep, invitationToken } = useFirstAccessContext();
  const navigate = useAuthNavigation();
  const { isLoading } = useValidateInvitationToken(invitationToken);

  const onConfirm = useCallback(async () => {
    if (accessType === 'oldAccess') {
      await Auth.signOut();
      navigate('LOGIN', { replace: false });
    } else {
      setStep(step + 1);
    }
  }, [accessType, navigate, step, setStep]);

  return (
    <>
      <TextsContainer>
        <GreetingsText variant="body3">Olá!</GreetingsText>
        <Title variant="headline5">
          Escolha uma das opções abaixo para acessar sua empresa
        </Title>
        <SubTitle variant="body3">
          Sua rotina mais cor de rosa está a poucos passos de você.
        </SubTitle>
      </TextsContainer>
      <CardsButtonContainer>
        <CardsContainer>
          <CardWithIcon
            iconName={'IconStar'}
            title={'É minha primeira vez usando Flash'}
            subTitle={
              'Caso você nunca tenha adquirido um cartão da Flash, escolha essa opção para criar sua conta! Esse cadastro será usado para acessar a conta de todas as empresas em que você trabalhar.'
            }
            maxWidth={'716px'}
            selected={accessType === 'firstAccess'}
            onClick={() => setAccessType('firstAccess')}
          />
          <CardWithIcon
            iconName={'IconThumbUp'}
            title={'Já tenho uma conta Flash'}
            subTitle={
              'Caso você já tenha usado Flash em outras empresas, acesse sua conta usando o mesmo e-mail e senha de sempre.'
            }
            maxWidth={'566px'}
            selected={accessType === 'oldAccess'}
            onClick={() => setAccessType('oldAccess')}
          />
        </CardsContainer>
        <ButtonsContainer>
          <LinkButtonStyled variant="default" onClick={() => setStep(step - 1)}>
            Voltar
          </LinkButtonStyled>
          <StyledButton
            variant="primary"
            size="large"
            disabled={!accessType || isLoading}
            loading={isLoading}
            onClick={onConfirm}
          >
            Continuar <Icons name="IconArrowRight" />
          </StyledButton>
        </ButtonsContainer>
      </CardsButtonContainer>
    </>
  );
};

export const AreYouNewHereStepOld = () => {
  const { code } = useParams();
  return (
    <Navigate
      to={`/authentication/first-access?invitationToken=${code}`}
      replace
    />
  );
};
