import { CustomMfa } from '@/auth/models/SessionUserModel';
import { trpc } from '@api/client';
import { dispatchToast } from '@utils/dispatchToast';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useValidateInvitationToken = (invitationToken: string | null) => {
  const navigate = useNavigate();
  const { mutate, isLoading } = trpc.validateInvitationToken.useMutation({
    onSuccess: (data) => {
      if (!data.isValid) {
        dispatchToast({
          content: 'Ops! Convite de acesso a plataforma inválido ou expirado!',
          type: 'error',
        });
        navigate('/authentication/login');
      }
    },
    onError: () => {
      dispatchToast({
        content: 'Ops! Ocorreu um erro. Tente novamente mais tarde!',
        type: 'error',
      });
      navigate('/authentication/login');
    },
  });

  useEffect(() => {
    if (invitationToken) {
      mutate({ invitationToken });
    }
  }, [invitationToken, mutate]);

  return { isLoading };
};

export const useMobileDeepLink = ({
  invitationToken,
  mfaMethod,
}: {
  invitationToken: string | null;
  mfaMethod?: CustomMfa;
}) => {
  useEffect(() => {
    const isMobileDevice =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent,
      );
    if (isMobileDevice && invitationToken) {
      try {
        const isProduction = process.env.BUILD_ENV === 'production';
        window.location.href = isProduction
          ? `br.com.flashapp://deeplink/signup?code=${invitationToken}&mfaMethod=${mfaMethod}`
          : `br.com.flashapp.staging://deeplink/signup?code=${invitationToken}&mfaMethod=${mfaMethod}`;
      } catch {}
    }
  }, [invitationToken, mfaMethod]);
};
