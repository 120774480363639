import { useState } from 'react';
import { Header, Footer } from '@components/Common';
import { ContentContainer, PageContainer } from './styles';
import {
  LoginRecoveryContext,
  LoginRecoverySteps,
} from './loginRecoveryContext';
import { EmailInputStep } from './components/EmailInputStep';
import { LoginEmailSentStep } from './components/LoginEmailSentStep';

export const PageLoginRecovery = () => {
  const [step, setStep] = useState<LoginRecoverySteps>('login_recovery_form');
  const [email, setEmail] = useState<string>('');

  return (
    <LoginRecoveryContext.Provider value={{ step, setStep, email, setEmail }}>
      <PageContainer>
        <Header />
        <ContentContainer>
          {step === 'login_recovery_form' && <EmailInputStep />}
          {step === 'login_recovery_success' && <LoginEmailSentStep />}
        </ContentContainer>
        <Footer />
      </PageContainer>
    </LoginRecoveryContext.Provider>
  );
};
