import styled from 'styled-components';

export const Container = styled.div`
  opacity: 0.5;
  position: relative;
  z-index: 900;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  opacity: 1;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 901;
`;
