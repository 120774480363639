import styled from 'styled-components';

import { Typography, Modal } from '@flash-tecnologia/hros-web-ui-v2';

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 24px 24px 0px 0px;
`;

const Boxtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
`;

const FlexEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledModal = styled(Modal.Root)`
  && {
    .modal-content-area {
      padding-top: 0px;
    }
    @media screen and (max-width: 420px) {
      &.modal-custom-theme .modal-content-area {
        padding: 5px 25px;
      }
    }
  }
`;

const TextModal = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export { CloseContainer, Boxtitle, StyledModal, FlexEnd, TextModal };
