import {
  IconButton,
  LinkButton,
  PDFViewer,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { CloseContainer, StyledModal, FlexRigth } from './styles';

interface ModalProps {
  title: string;
  src: string;
  open: boolean;
  onClose?: () => any;
}

export const PDFModal = ({
  src,
  title,
  open,
  onClose = () => {},
}: ModalProps) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      header={
        <CloseContainer>
          <IconButton
            icon="IconX"
            onClick={onClose}
            variant="line"
            size="small"
          />
        </CloseContainer>
      }
    >
      <>
        <div style={{ maxWidth: '456px', marginBottom: '24px' }}>
          <Typography variant="headline6">{title}</Typography>
        </div>
        <div style={{ width: '100%', maxWidth: '687px', marginBottom: '40px' }}>
          <PDFViewer
            width="100%"
            height="400px"
            src={src}
            options={{ showToolbar: true, fit: 'height', page: 1 }}
          />
        </div>
        <FlexRigth>
          <LinkButton
            variant="default"
            onClick={onClose}
            style={{ alignSelf: 'center' }}
          >
            Fechar
          </LinkButton>
        </FlexRigth>
      </>
    </StyledModal>
  );
};
