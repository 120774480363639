import { Icons, LinkButton, Spinner } from '@flash-tecnologia/hros-web-ui-v2';

type AccessButtonProps = {
  hide?: boolean;
  loading: boolean;
  onClick: () => void;
};

export const AccessButton = ({ hide, loading, onClick }: AccessButtonProps) => {
  if (loading) return <Spinner variant="primary" size={32} />;
  if (hide) return null;

  return (
    <LinkButton variant="default" onClick={onClick}>
      Acessar <Icons name="IconArrowRight" size={16} />
    </LinkButton>
  );
};
