import {
  Button,
  Divider,
  Icons,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import * as SC from './styles';
import { BulletItem } from './BulletItem';
import { topicItems } from './topic-items';
import { useAuthNavigation } from 'src/routes';
import LogoFlash from '../../assets/logo_flash.svg';
import { VideoPlayerModal } from '@components/Modals/VideoPlayerModal';
import { usePageTracking } from '@utils/analytics/hooks/usePageTracking';
import { useState } from 'react';
import { setEventTracking } from '@utils/analytics/segment';

export const PageMigration = () => {
  usePageTracking('signin_onboarding_migration_page');

  const navigate = useAuthNavigation();
  const [videoPlayerOpen, setVideoPlayerOpen] = useState(false);

  const onNext = () => {
    navigate('LOGIN');
  };

  const onOpenVideoPlayer = () => {
    setVideoPlayerOpen(true);
    setEventTracking('signin_onboarding_migration_video_clicked');
  };

  return (
    <>
      <SC.Container>
        <SC.Header>
          <LogoFlash />
        </SC.Header>
        <SC.ContentContainer>
          <div>
            <SC.TextContainer>
              <Typography
                variant="headline8"
                variantColor="var(--color-secondary-50)"
              >
                Atenção!
              </Typography>
              <Typography
                variant="headline6"
                variantColor="var(--color-neutral-20)"
              >
                Saiba como fazer o acesso na nova plataforma Flash
              </Typography>
              <Typography
                variant="body3"
                variantColor="var(--color-neutral-50)"
              >
                Fique por dentro dos principais pontos de mudança no acesso à
                nova plataforma! Se preferir, assista ao vídeo tutorial e
                aprenda em menos de 2 minutos como concluir sua migração
                rapidamente.
              </Typography>
            </SC.TextContainer>
            <SC.TopicsContainer>
              {topicItems.map((topic, i) => (
                <BulletItem
                  key={`topic-item-${i}`}
                  iconName={topic.iconName}
                  title={topic.title}
                  text={topic.text}
                />
              ))}
            </SC.TopicsContainer>
          </div>
          <SC.ImageContainer
            imageUrl="https://images.flashapp.com.br/flash-os/authentication/migration-onboarding.png"
            onClick={onOpenVideoPlayer}
          />
        </SC.ContentContainer>
        <Divider orientation="horizontal" />
        <SC.Footer>
          <Button size="medium" variant="primary" onClick={onNext}>
            Entendi <Icons name="IconArrowRight" />
          </Button>
        </SC.Footer>
      </SC.Container>
      <VideoPlayerModal
        open={videoPlayerOpen}
        onClose={() => setVideoPlayerOpen(false)}
        src="https://images.flashapp.com.br/flash-os/authentication/flash_platform_access_and_login.mp4"
      />
    </>
  );
};
