import { useState, useCallback } from 'react';
import {
  Icons,
  OTPField,
  Typography,
  PasswordField,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Countdown } from '../../../../components/Common';
import {
  Wrapper,
  LeftContainer,
  RightContainer,
  GreetingsText,
  Title,
  SubTitle,
  TextsContainer,
  FieldsContainer,
  ButtonsContainer,
  LinkButtonStyled,
  StyledButton,
  SMSButtonFlex,
  SMSCodeContainer,
  OtpTitle,
} from './styles';
import Forgot_Password from '../../../../assets/forgot_password.png';
import { useNewPasswordForm, useResendPasswordRecoveryCode } from './hooks';
import { usePasswordRecoveryContext } from '../../passwordRecoveryContext';
import { useFlag } from '@flash-tecnologia/feature-flags';

export const NewPasswordStep = () => {
  const [showCountdown, setShowCountdown] = useState<boolean>(true);
  const { step, setStep, deliveryDetails, recoveryChannel } =
    usePasswordRecoveryContext();
  const { resendCode, isLoading: isLoadingResendCode } =
    useResendPasswordRecoveryCode();
  const { form, isLoading } = useNewPasswordForm();
  const resendWhatsappFlag = useFlag({
    flagName: 'FLASHOS_RESEND_CODE_WHATSAPP',
  });
  const resendWhatsappEnabled = resendWhatsappFlag && recoveryChannel === 'SMS';

  const handleFinishCountDown = useCallback(() => {
    setShowCountdown(false);
  }, [setShowCountdown]);

  const handleResendCode = useCallback(
    async (mfaMethod?: 'sms' | 'whatsapp') => {
      await resendCode(mfaMethod);
      setShowCountdown(true);
    },
    [resendCode, setShowCountdown],
  );

  return (
    <Wrapper>
      <LeftContainer>
        <TextsContainer>
          <GreetingsText variant="body3">Recuperar senha</GreetingsText>
          <Title variant="headline5">O código de recuperação foi enviado</Title>
          <SubTitle variant="body3">
            Verifique seu celular ou e-mail de cadastro.
          </SubTitle>
        </TextsContainer>
        <form onSubmit={form.handleSubmit}>
          <FieldsContainer>
            <OtpTitle variant="body3">
              Insira o código de confirmação recebido
            </OtpTitle>
            <OTPField
              numInputs={6}
              value={form.values.otp}
              onChange={(e: any) =>
                form.handleChange({ target: { id: 'otp', value: e } })
              }
              error={form.touched.otp && Boolean(form.errors.otp)}
            />
          </FieldsContainer>
          <SMSCodeContainer>
            {deliveryDetails?.destination && (
              <Typography variant="body3">
                Código enviado para {deliveryDetails?.destination}
              </Typography>
            )}
            <SMSButtonFlex>
              {showCountdown ? (
                <Typography variant="body3">
                  Aguarde o recebimento • {''}
                  <Countdown time={60} onFinished={handleFinishCountDown} />
                </Typography>
              ) : (
                <Typography variant="body3">Não recebeu seu código?</Typography>
              )}
              <LinkButtonStyled
                variant="default"
                disabled={showCountdown || isLoadingResendCode}
                onClick={() =>
                  handleResendCode(
                    recoveryChannel === 'SMS' ? 'sms' : undefined,
                  )
                }
              >
                Reenviar <Icons name="IconArrowBack" />
              </LinkButtonStyled>

              {!showCountdown && resendWhatsappEnabled && (
                <LinkButtonStyled
                  variant="default"
                  disabled={showCountdown || isLoadingResendCode}
                  onClick={() => handleResendCode('whatsapp')}
                >
                  {' '}
                  Reenviar por Whatsapp
                  <Icons name="IconBrandWhatsapp" />
                </LinkButtonStyled>
              )}
            </SMSButtonFlex>
          </SMSCodeContainer>
          <FieldsContainer>
            <PasswordField
              id={'password'}
              name={'password'}
              label="Crie sua senha"
              isCreatePassword
              autoComplete="off"
              value={form.values.password}
              onChange={form.handleChange}
              error={form.touched.password && Boolean(form.errors.password)}
            />
            <PasswordField
              style={{ marginTop: '24px' }}
              id={'confirmPassword'}
              name={'confirmPassword'}
              label="Confirme sua senha"
              autoComplete="off"
              value={form.values.confirmPassword}
              onChange={form.handleChange}
              helperText={
                form.touched.confirmPassword && form.errors.confirmPassword
              }
              error={
                form.touched.confirmPassword &&
                Boolean(form.errors.confirmPassword)
              }
            />
          </FieldsContainer>
          <ButtonsContainer>
            <LinkButtonStyled
              variant="default"
              onClick={() => setStep(step - 1)}
            >
              Voltar
            </LinkButtonStyled>

            <StyledButton
              variant="primary"
              size="large"
              type="submit"
              loading={isLoading}
              disabled={isLoading}
            >
              Continuar <Icons name="IconArrowRight" />
            </StyledButton>
          </ButtonsContainer>
        </form>
      </LeftContainer>
      <RightContainer imageUrl={Forgot_Password} />
    </Wrapper>
  );
};
