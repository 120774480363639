import { TextField, Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  Wrapper,
  LeftContainer,
  RightContainer,
  GreetingsText,
  TextsContainer,
  FieldsContainer,
  ButtonsContainer,
  LinkButtonStyled,
  StyledButton,
} from './styles';
import ForgotPassword from '../../../../assets/forgot_password.png';
import { useAuthNavigation } from '../../../../routes';
import { setEventTracking } from '@utils/analytics/segment';
import { useLoginRecoveryForm } from './hooks';

export const EmailInputStep = () => {
  const navigate = useAuthNavigation();
  const { form } = useLoginRecoveryForm();

  const handleBack = () => {
    setEventTracking('signin_forgotlogin_back_button_clicked', {
      email: form.values.email,
    });
    navigate('LOGIN');
  };

  return (
    <Wrapper>
      <LeftContainer>
        <TextsContainer>
          <GreetingsText variant="body3">
            Esqueceu o seu login? Tudo bem, acontece!
          </GreetingsText>
          <Typography variant="headline5">Recupere seu login</Typography>
          <Typography
            variant="body3"
            weight={600}
            variantColor="var(--color-neutral-50)"
          >
            Insira o e-mail que você utilizava para logar na plataforma antiga,
            e siga as instruções para recuperar tudo em um flash <span>⚡</span>
          </Typography>
        </TextsContainer>
        <form onSubmit={form.handleSubmit}>
          <FieldsContainer>
            <TextField
              label="Insira seu e-mail de login"
              id="email"
              name="email"
              autoComplete="off"
              value={form.values.email}
              onChange={form.handleChange}
              error={form.touched.email && Boolean(form.errors.email)}
              helperText={form.touched.email && form.errors.email}
            />
          </FieldsContainer>
          <ButtonsContainer>
            <LinkButtonStyled variant="default" onClick={() => handleBack()}>
              Voltar
            </LinkButtonStyled>
            <StyledButton variant="primary" size="large" type="submit">
              Continuar <Icons name="IconArrowRight" />
            </StyledButton>
          </ButtonsContainer>
        </form>
      </LeftContainer>
      <RightContainer imageUrl={ForgotPassword} />
    </Wrapper>
  );
};
