import { createContext, useContext } from 'react';

export type FormData = {
  username: string;
  documentNumber: string;
  phoneNumber: string;
  email: string;
  name: string;
  password: string;
};
export const FirstAccessFormContext = createContext<{
  step: number;
  formData: FormData;
  setFormData: (data: FormData) => void;
  setStep: (step: number) => void;
  invitationToken: string | null;
}>({
  step: 0,
  formData: {
    username: '',
    documentNumber: '',
    phoneNumber: '',
    email: '',
    password: '',
    name: '',
  },
  invitationToken: null,
  setStep: () => {},
  setFormData: () => {},
});

export function useFirstAccessContext() {
  return useContext(FirstAccessFormContext);
}
