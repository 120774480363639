import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarCircle = styled.div<{ logoPath?: string }>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
  background-image: url(${({ logoPath }) => logoPath});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const CompanyName = styled.p`
  padding: 0;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #3c2d37;
  margin: 0 12px;
  display: block;
  display: -webkit-box;
  line-height: 2;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MenuCircle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid #ebe5e9;
  padding: 10px;
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background-color: rgba(74, 78, 87, 0.1);
`;

export const StepArea = styled.ul`
  margin-top: 10px;
  position: relative;
  padding-left: 45px;
  list-style: none;
  -webkit-tap-highlight-color: transparent;
  &:before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
  }
  div:last-child {
    &:before {
      height: 0;
    }
  }
`;

export const MenuContainer = styled.div<{
  active: boolean;
  menuHeight: number;
}>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${({ menuHeight }) => menuHeight}px;
  right: ${({ active }) => (active ? 0 : '-100%')};
  height: ${({ menuHeight }) => `calc(100vh - ${menuHeight}px)`};
  background-color: #ffffff;
  width: 100%;
  z-index: 998;
  transition: right 0.3s ease-in;
  padding: 50px 24px;
`;

export const ProgressBar = styled.div<{ width: string | number }>`
  position: absolute;
  bottom: 0;
  border-bottom: 3px solid ${({ theme }) => theme.colors.primary};
  width: ${({ width }) => width};
  transition: width 0.2s ease-in;
`;

export const Content = styled.div`
  padding: 28px 136px;
  max-width: 1366px;
  margin: 0 auto;
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 992px) {
    padding: 28px 80px;
  }
  @media screen and (max-width: 576px) {
    padding: 28px 24px;
  }
`;
