import { useChooseAccessesConfig } from '../../hooks';
import { Container, IconContainer, InfoContainer, TextButton } from './styles';
import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { setEventTracking } from '@/utils/analytics/segment';

export const NewPageFeedbackBanner = () => {
  const { setCurrentVersion } = useChooseAccessesConfig();
  const goBackToOldVersion = () => {
    setCurrentVersion(1);
    setEventTracking('access_page_return_to_v1_clicked');
    window.location.reload();
  };
  return (
    <Container>
      <InfoContainer>
        <IconContainer>
          <Icons
            name="IconSparkles"
            fill="none"
            width={16}
            height={16}
            color="var(--color-secondary-50)"
          />
        </IconContainer>
        <Typography
          variant="body3"
          variantColor="var(--color-secondary-50)"
          weight={600}
        >
          Você está visualizando a nova versão da página de seleção de conta.{' '}
          <TextButton
            href="https://surveys.hotjar.com/efbeb4c6-80f7-45ad-8ec7-0a8a09346949"
            target="_blank"
          >
            <b>Deixe seu feedback</b>
          </TextButton>{' '}
          ou{' '}
          <TextButton onClick={goBackToOldVersion}>
            <b>volte para a versão anterior</b>
          </TextButton>
          .
        </Typography>
      </InfoContainer>
    </Container>
  );
};
