import { Modal, VideoPlayer } from '@flash-tecnologia/hros-web-ui-v2';

interface ModalProps {
  src: string;
  open: boolean;
  onClose?: () => any;
}

export const VideoPlayerModal = ({
  src,
  open,
  onClose = () => {},
}: ModalProps) => {
  return (
    <Modal.Root
      open={open}
      showClose={true}
      onClose={onClose}
      size="full"
      style={{ padding: '3rem' }}
    >
      <>
        <Modal.Header title="" />
        <Modal.Content>
          <VideoPlayer
            options={{
              autoplay: true,
              controls: true,
              responsive: true,
              fluid: true,
              src: src,
            }}
          />
        </Modal.Content>
      </>
    </Modal.Root>
  );
};
