import { Button } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 12px 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};

  @media screen and (max-width: 576px) {
    border-top: none;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
  @media screen and (max-width: 576px) {
    justify-content: space-between;
  }
`;

export const StyledButton = styled(Button).attrs({
  size: 'medium',
  type: 'submit',
})`
  && {
    min-width: 150px;

    @media screen and (max-width: 576px) {
      flex: 1;
    }
  }
`;
