import styled from 'styled-components';

import { Typography, LinkButton } from '@flash-tecnologia/hros-web-ui-v2';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  flex: 1;
`;

const FirstMessage = styled(Typography)`
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 24px;
  }
`;

const SubTitle = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
    max-width: 384px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 640px;

  border: 1px solid #ebe6e9;
  border-radius: 8px;

  padding: 0px 150px 52px 150px;
  margin: 150px 0 120px 0px;

  @media screen and (max-width: 992px) {
    padding: 0px 100px 52px 100px;
  }
  @media screen and (max-width: 576px) {
    padding: 0px 20px 52px 20px;
  }
`;

const IconContainer = styled.div`
  position: relative;
  top: -40px;
  background-color: #ffe0ef;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
`;

const LinkButtonStyled = styled(LinkButton)`
  && {
    &.MuiButtonBase-root {
      align-self: center;

      svg {
        fill: transparent;
      }
    }
  }
`;

export {
  PageContainer,
  ContentsContainer,
  FirstMessage,
  SubTitle,
  CardContainer,
  IconContainer,
  ButtonsContainer,
  LinkButtonStyled,
};
