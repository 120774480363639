import { UserAttributes } from '@auth/models/SessionUserModel';
import { CognitoUserAttributes } from '../cognito/models/CognitoUser';

export class SessionUserAttributesMapper {
  static toDomain(attributes: CognitoUserAttributes): UserAttributes {
    return {
      email: attributes.email,
      emailVerified: attributes.email_verified,
      name: attributes.name,
      phoneNumber: attributes.phone_number,
      phoneNumberVerified: attributes.phone_number_verified,
      preferredUsername: attributes.preferred_username,
    };
  }

  static toCognito(attributes: UserAttributes): CognitoUserAttributes {
    return {
      ...(attributes.email && { email: attributes.email }),
      ...(attributes.emailVerified && {
        email_verified: attributes.emailVerified,
      }),
      ...(attributes.name && { name: attributes.name }),
      ...(attributes.phoneNumber && { phone_number: attributes.phoneNumber }),
      ...(attributes.phoneNumberVerified && {
        phone_number_verified: attributes.phoneNumberVerified,
      }),
      ...(attributes.preferredUsername && {
        preferred_username: attributes.preferredUsername,
      }),
    };
  }
}
