import { Navigate, useSearchParams } from 'react-router-dom';
import { ChooseAccessPage } from './components/ChooseAccess';
import { ChooseAccessPageV2 } from './components/ChooseAccessV2';
import { useFlag } from '@flash-tecnologia/feature-flags';
import {
  ContentContainer,
  LeftContainer,
  PageContainer,
  RightContainer,
  Wrapper,
} from './styles';
import { Footer, Header } from '@components/Common';
import { useChooseAccessesConfig, useRedeemInvitation } from './hooks';
import { useEffect } from 'react';
import { NewPageAvailableBanner } from './components/NewPageAvailableBanner';
import { NewPageFeedbackBanner } from './components/NewPageFeedbackBanner';

export interface AccessOption {
  id: string;
  name: string;
  registrationNumber: string;
}

export const PageAccessSelection = () => {
  const [params] = useSearchParams();
  const { isLoading, redeem } = useRedeemInvitation();
  const invitationToken = params.get('invitationToken');
  const { getCurrentVersion } = useChooseAccessesConfig();
  const currentVersion = getCurrentVersion();
  const showChooseAccessesBanner = useFlag({
    flagName: 'FLASH_OS_SHOW_CHOOSE_ACCESSES_BANNER',
  });

  useEffect(() => {
    if (invitationToken) {
      redeem({ invitationToken });
    }
  }, [invitationToken]);

  const redirectUrl = params.get('redirectTo');

  if (redirectUrl && decodeURIComponent(redirectUrl).includes('signup')) {
    return <Navigate replace={true} to={decodeURIComponent(redirectUrl)} />;
  }

  const showNewerVersion = showChooseAccessesBanner && currentVersion == 2;

  const showAccessPage = () => {
    return <>{invitationToken || isLoading ? <></> : <ChooseAccessPage />}</>;
  };

  const showAccessPageV2 = () => {
    return (
      <Wrapper>
        <LeftContainer>
          {invitationToken || isLoading ? <></> : <ChooseAccessPageV2 />}
        </LeftContainer>
        <RightContainer imageUrl="https://images.flashapp.com.br/flash-os/authentication/list-accesses-page.png" />
      </Wrapper>
    );
  };

  return (
    <PageContainer>
      <Header />
      {showChooseAccessesBanner && currentVersion == 1 ? (
        <NewPageAvailableBanner />
      ) : null}
      {showNewerVersion ? <NewPageFeedbackBanner /> : null}
      <ContentContainer>
        {showNewerVersion ? showAccessPageV2() : showAccessPage()}
      </ContentContainer>
      <Footer />
    </PageContainer>
  );
};
