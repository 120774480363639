import { Auth, Amplify } from 'aws-amplify';
import { IAuthProvider, CONFIRM_MFA_TYPE, MFA_TYPE } from '../IAuthProvider';
import {
  CustomMfa,
  SessionUserModel,
  UserAttributes,
} from '../models/SessionUserModel';
import { cognitoErrorHandler } from './CognitoErrorHandler';
import { CognitoError } from './models/CognitoError';
import { cognitoConfig } from './CognitoConfig';
import { SessionUserAttributesMapper, SessionUserMapper } from '@auth/mappers';
import { CodeDeliveryDetails } from '../models/CodeDeliveryDetails';

export class CognitoAuthImpl implements IAuthProvider {
  constructor() {
    Amplify.configure(cognitoConfig[process.env.BUILD_ENV || 'staging'].Auth);
  }

  async signIn(input: {
    username: string;
    password: string;
  }): Promise<SessionUserModel> {
    const validator = {
      preferredMfa: 'SMS_MFA',
    };
    let user: SessionUserModel;
    try {
      user = await Auth.signIn(input.username, input.password, validator);
    } catch (e) {
      const cognitoError = cognitoErrorHandler(e as CognitoError);
      const error = Error(cognitoError.message);
      error.name = cognitoError.name;
      throw error;
    }
    return SessionUserMapper.toDomain(user);
  }

  async confirmSignIn(input: {
    user: SessionUserModel;
    code: string;
    mfaType: CONFIRM_MFA_TYPE;
  }): Promise<SessionUserModel> {
    try {
      const cognitoUser = SessionUserMapper.toCognito(input.user);
      await Auth.confirmSignIn(cognitoUser, input.code, input.mfaType);
      const authenticatedUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      return SessionUserMapper.toDomain(authenticatedUser);
    } catch (e) {
      const cognitoError = cognitoErrorHandler(e as CognitoError);
      const error = Error(cognitoError.message);
      error.name = cognitoError.name;
      throw error;
    }
  }

  async currentAuthenticatedUser(options?: {
    bypassCache: boolean;
  }): Promise<SessionUserModel> {
    try {
      const user = await Auth.currentAuthenticatedUser(options);
      return SessionUserMapper.toDomain(user);
    } catch (e) {
      const cognitoError = cognitoErrorHandler(e as CognitoError);
      const error = Error(cognitoError.message);
      error.name = cognitoError.name;
      throw error;
    }
  }

  async currentSession() {
    const session = await Auth.currentSession();
    return { idToken: session.getIdToken().getJwtToken() };
  }

  async signOut(): Promise<void> {
    try {
      await Auth.signOut();
    } catch (e) {
      const cognitoError = cognitoErrorHandler(e as CognitoError);
      const error = Error(cognitoError.message);
      error.name = cognitoError.name;
      throw error;
    }
  }

  async signUp(input: {
    username: string;
    password: string;
    name: string;
    email: string;
    phoneNumber: string;
    customMfa?: CustomMfa;
  }): Promise<SessionUserModel> {
    try {
      const attributes = {
        name: input.name,
        email: input.email,
        phone_number: input.phoneNumber,
      };
      if (input.customMfa) {
        attributes['custom:mfa_method'] = input.customMfa;
      }
      const { user } = await Auth.signUp({
        username: input.username,
        password: input.password,
        attributes,
        autoSignIn: { enabled: true },
      });
      return SessionUserMapper.toDomain(user);
    } catch (e) {
      const cognitoError = cognitoErrorHandler(e as CognitoError);
      const error = Error(cognitoError.message);
      error.name = cognitoError.name;
      throw error;
    }
  }

  async confirmSignUpCode(input: {
    username: string;
    code: string;
    preferredUsername: string;
  }): Promise<void> {
    try {
      await Auth.confirmSignUp(input.username, input.code, {
        forceAliasCreation: false,
        clientMetadata: {
          preferredUsername: input.preferredUsername,
        },
      });
    } catch (e) {
      const cognitoError = cognitoErrorHandler(e as CognitoError);
      const error = Error(cognitoError.message);
      error.name = cognitoError.name;
      throw error;
    }
  }

  async resendSignUpConfirmationCode(username: string): Promise<void> {
    try {
      await Auth.resendSignUp(username);
    } catch (e) {
      const cognitoError = cognitoErrorHandler(e as CognitoError);
      const error = Error(cognitoError.message);
      error.name = cognitoError.name;
      throw error;
    }
  }

  async setPreferredMFA(mfaType: MFA_TYPE): Promise<void> {
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      await Auth.setPreferredMFA(user, mfaType);
    } catch (e) {
      const cognitoError = cognitoErrorHandler(e as CognitoError);
      const error = Error(cognitoError.message);
      error.name = cognitoError.name;
      throw error;
    }
  }

  async updateCurrentUserAttributes(
    attributes: UserAttributes,
  ): Promise<SessionUserModel> {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      const cognitoAttributes =
        SessionUserAttributesMapper.toCognito(attributes);
      await Auth.updateUserAttributes(user, cognitoAttributes);
      const updatedUser = await Auth.currentAuthenticatedUser();
      return SessionUserMapper.toDomain(updatedUser);
    } catch (e) {
      const cognitoError = cognitoErrorHandler(e as CognitoError);
      const error = Error(cognitoError.message);
      error.name = cognitoError.name;
      throw error;
    }
  }

  async sendEmailVerificationCode(): Promise<void> {
    try {
      await Auth.verifyCurrentUserAttribute('email');
    } catch (e) {
      const cognitoError = cognitoErrorHandler(e as CognitoError);
      const error = Error(cognitoError.message);
      error.name = cognitoError.name;
      throw error;
    }
  }

  async sendPhoneNumberVerificationCode(): Promise<void> {
    try {
      await Auth.verifyCurrentUserAttribute('phone_number');
    } catch (e) {
      const cognitoError = cognitoErrorHandler(e as CognitoError);
      const error = Error(cognitoError.message);
      error.name = cognitoError.name;
      throw error;
    }
  }

  async verifyEmailCode(code: string): Promise<void> {
    try {
      await Auth.verifyCurrentUserAttributeSubmit('email', code);
    } catch (e) {
      const cognitoError = cognitoErrorHandler(e as CognitoError);
      const error = Error(cognitoError.message);
      error.name = cognitoError.name;
      throw error;
    }
  }

  async verifyPhoneNumberCode(code: string): Promise<void> {
    try {
      await Auth.verifyCurrentUserAttributeSubmit('phone_number', code);
    } catch (e) {
      const cognitoError = cognitoErrorHandler(e as CognitoError);
      const error = Error(cognitoError.message);
      error.name = cognitoError.name;
      throw error;
    }
  }

  async sendForgotPassword(
    username: string,
    recoveryChannel: 'SMS' | 'EMAIL',
  ): Promise<CodeDeliveryDetails> {
    try {
      const details = await Auth.forgotPassword(username, {
        recoveryChannel: recoveryChannel.toLowerCase(),
      });
      return {
        destination: details?.CodeDeliveryDetails?.Destination,
        deliveryChannel: details?.CodeDeliveryDetails?.DeliveryMedium,
      };
    } catch (e) {
      const cognitoError = cognitoErrorHandler(e as CognitoError);
      const error = Error(cognitoError.message);
      error.name = cognitoError.name;
      throw error;
    }
  }
  async forgotPasswordSubmit(input: {
    username: string;
    code: string;
    newPassword: string;
  }): Promise<void> {
    try {
      await Auth.forgotPasswordSubmit(
        input.username,
        input.code,
        input.newPassword,
      );
    } catch (e) {
      const cognitoError = cognitoErrorHandler(e as CognitoError);
      const error = Error(cognitoError.message);
      error.name = cognitoError.name;
      throw error;
    }
  }
}
