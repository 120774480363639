import { Button, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1366px;
  padding: 60px 136px 0 136px;
  width: 100%;
  margin: 0 auto;
  flex: 1;

  @media screen and (max-width: 992px) {
    padding: 60px 80px 0 80px;
  }
  @media screen and (max-width: 576px) {
    padding: 60px 24px;
  }
  @media screen and (max-width: 1200px) {
    display: block;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin-right: 30px;

  @media screen and (max-width: 576px) {
    justify-content: center;
    margin-right: 0px;
  }
`;

export const Image = styled.img`
  width: 420px;
  height: 421px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 0px 128px;

  @media screen and (max-width: 1200px) {
    height: 0px;
  }
`;

export const TextsContainer = styled.div`
  gap: 8px;
`;

export const CreateAccountText = styled(Typography).attrs({ variant: 'body3' })`
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const Title = styled(Typography).attrs({ variant: 'headline5' })``;

export const SubTitle = styled(Typography).attrs({ variant: 'body3' })`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  }
`;

export const StyledButton = styled(Button)`
  && {
    &.MuiButtonBase-root.size-large {
      align-self: center;
      margin-top: 40px;

      @media screen and (max-width: 576px) {
        width: 100%;
      }
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CaptchaContainer = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CaptchaErrorText = styled(Typography).attrs({
  variant: 'caption',
})`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.feedback.error[50]};
  }
`;
