import { createContext, useContext } from 'react';

export type LoginRecoverySteps =
  | 'login_recovery_form'
  | 'login_recovery_success';

export const LoginRecoveryContext = createContext<{
  step: LoginRecoverySteps;
  email: string;
  setEmail: (username: string) => void;
  setStep: (step: LoginRecoverySteps) => void;
}>({
  step: 'login_recovery_form',
  email: '',
  setEmail: () => {},
  setStep: () => {},
});

export function useLoginRecoveryContext() {
  return useContext(LoginRecoveryContext);
}
