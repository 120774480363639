import styled from 'styled-components';

import {
  Typography,
  LinkButton,
  Button,
} from '@flash-tecnologia/hros-web-ui-v2';

const StepWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 535px;
  width: 100%;
`;

const RightContainer = styled.div<{ imageUrl: string }>`
  background-image: url(${({ imageUrl }) => imageUrl});
  width: 420px;
  height: 421px;
  min-width: 400px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 0px 128px;
  margin-left: 30px;

  @media screen and (max-width: 1200px) {
    height: 0px;
  }
`;

const CreateAccountText = styled(Typography)`
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 379px;
  @media screen and (max-width: 992px) {
    max-width: 800px;
  }
`;

const Title = styled(Typography)``;

const OtpTitle = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.dark.dark4};
  }
`;

const SubTitle = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
  gap: 24px;
`;

const SMSCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 43px;
  width: 100%;
  gap: 10px;
`;

const SMSButtonFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 82px;
`;

const LinkButtonStyled = styled(LinkButton)`
  && {
    &.MuiButtonBase-root {
      align-self: center;

      svg {
        fill: transparent;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  && {
    &.MuiButtonBase-root.size-large {
      padding: 19px 46px;
    }
  }
`;

export {
  StepWrapper,
  LeftContainer,
  RightContainer,
  CreateAccountText,
  TextsContainer,
  Title,
  SubTitle,
  ContentContainer,
  SMSCodeContainer,
  SMSButtonFlex,
  ButtonsContainer,
  LinkButtonStyled,
  StyledButton,
  OtpTitle,
};
