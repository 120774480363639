import {
  Button,
  IconButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';

import {
  CloseContainer,
  Boxtitle,
  StyledModal,
  FlexEnd,
  TextModal,
} from './styles';

interface ModalProps {
  open: boolean;
  onClose?: () => any;
}

export const AdditionalDataAccepted = ({
  open,
  onClose = () => {},
}: ModalProps) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      header={
        <CloseContainer>
          <IconButton
            icon="IconX"
            onClick={onClose}
            variant="line"
            size="small"
          />
        </CloseContainer>
      }
    >
      <>
        <div style={{ maxWidth: '315px', marginBottom: '8px' }}>
          <Boxtitle variant="body3" style={{ fontWeight: 700 }}>
            Sua conta foi criada com sucesso!
          </Boxtitle>
        </div>
        <div style={{ maxWidth: '456px', marginBottom: '24px' }}>
          <Typography variant="headline6">
            Agora você pode aproveitar toda a liberdade que só a Flash te dá 🚀
          </Typography>
        </div>
        <div style={{ maxWidth: '483px', marginBottom: '40px' }}>
          <TextModal variant="body3">
            Estamos super felizes em ter você aqui. Preparamos tudo para que
            você tenha uma experiência incrível e sem burocracias.
          </TextModal>
          <br />
          <TextModal variant="body3">
            <b>De um jeito bem Flash</b> <span>🦩</span>.
          </TextModal>
          <br />
          <TextModal variant="body3">
            Agora é só clicar em começar para conhecer nossa plataforma.
            Aproveite!
          </TextModal>
        </div>
        <FlexEnd>
          <Button variant="primary" size="large" onClick={() => onClose()}>
            Começar
          </Button>
        </FlexEnd>
      </>
    </StyledModal>
  );
};
