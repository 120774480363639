import { useState } from 'react';
import { dispatchToast, validateCPF } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikErrors, useFormik } from 'formik';
import * as yup from 'yup';
import { trpc } from '@api/client';
import { useFlag } from '@flash-tecnologia/feature-flags';

export const usePageCustomFirstAccess = () => {
  const customMfaEnabled = useFlag({
    flagName: 'FLASH_OS_CUSTOM_MFA_ENABLED',
  });
  const { companyName } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object({
    cpf: yup.string().required('Você deve preencher o CPF'),
    captchaToken: yup.string().required('Você deve marcar o captcha'),
  });

  const formik = useFormik({
    initialValues: {
      cpf: '',
      captchaToken: '',
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: FormikErrors<typeof values> = {};

      if (values.cpf && !validateCPF(values.cpf))
        errors.cpf = 'Preencha com um número de CPF válido';

      return errors;
    },
    onSubmit: (values) => handleSubmit(values.cpf, values.captchaToken),
  });

  const { mutateAsync: generateCustomSignUpQuiz } =
    trpc.generateCustomSignUpQuiz.useMutation({
      onError: () => {
        dispatchToast({
          type: 'error',
          content:
            'Ocorreu um erro, por favor, tente novamente mais tarde ou entre em contato com o suporte.',
        });
      },
    });

  const { mutateAsync: getInvitationByCpfAndCompany } =
    trpc.getInvitationByCpfAndCompany.useMutation();

  async function handleSubmit(cpf: string, captchaToken: string) {
    setIsLoading(true);

    const onlyNumbersCpf = cpf.replace(/\D/g, '');

    try {
      const invitationCode = await getInvitationByCpfAndCompany({
        cpf: onlyNumbersCpf,
        companyName: companyName!,
      });
      if (customMfaEnabled) {
        navigate(
          `/authentication/first-access?invitationToken=${invitationCode}&mfaMethod=whatsapp`,
        );
      } else {
        navigate(
          `/authentication/first-access?invitationToken=${invitationCode}`,
        );
      }
    } catch {
      const quiz = await generateCustomSignUpQuiz({
        cpf: onlyNumbersCpf,
        captchaToken,
      });
      if (quiz.ResultCode < 0) {
        dispatchToast({
          type: 'error',
          content:
            'Ocorreu um erro, por favor, tente novamente mais tarde ou entre em contato com o suporte.',
        });
        setIsLoading(false);
        return;
      }
      navigate(
        `/authentication/firstAccess/custom/quiz?quizId=${quiz.TicketId}&cpf=${onlyNumbersCpf}&companyName=${companyName}`,
        {
          state: { quiz },
        },
      );
    } finally {
      setIsLoading(false);
    }
  }

  return {
    formik,
    isLoading,
    handleSubmit,
  };
};
