import styled from 'styled-components';

import {
  Typography,
  LinkButton,
  Button,
} from '@flash-tecnologia/hros-web-ui-v2';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 1200px) {
    display: block;
    width: 100%;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 535px;
  width: 100%;
  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }
`;

export const RightContainer = styled.div<{ imageUrl: string }>`
  background-image: url(${({ imageUrl }) => imageUrl});
  width: 420px;
  height: 421px;
  min-width: 400px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 0px 128px;
  margin-left: 30px;

  @media screen and (max-width: 1200px) {
    height: 0px;
  }
`;

export const GreetingsText = styled(Typography)`
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 568px;
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  @media screen and (max-width: 420px) {
    flex-direction: column-reverse;
    gap: 20px;
  }
`;

export const LinkButtonStyled = styled(LinkButton)`
  && {
    &.MuiButtonBase-root {
      align-self: center;

      svg {
        fill: transparent;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  &&.MuiButtonBase-root.size-large {
    padding: 19px 53px;

    @media screen and (max-width: 420px) {
      margin: 0 auto;
    }
  }
`;
