import { useAuthContext } from '@auth/index';
import { useState } from 'react';
import * as yup from 'yup';
import { usePasswordRecoveryContext } from '../../passwordRecoveryContext';
import { dispatchToast } from '@utils';
import { useFormik } from 'formik';
import { useAuthNavigation } from 'src/routes';
import { setEventTracking } from '@utils/analytics/segment';
import { hideDocumentNumber } from '@utils/masks/hideDocumentNumber';
import { ErrorBoundary } from '@utils/ErrorBoundary';
import { trpc } from '@/api/client';

const validationSchema = yup.object({
  otp: yup.string().min(6).required('Favor preencher campos!'),
  password: yup
    .string()
    .min(8, '')
    .matches(
      RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])((?=.*\W)|(?=.*_))^[^ ]+$/),
      'AA',
    )
    .required('Favor digitar uma senha'),
  confirmPassword: yup.string().min(8, '').required('Favor digitar uma senha'),
});

export const useNewPasswordForm = () => {
  const navigate = useAuthNavigation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const auth = useAuthContext();
  const { username } = usePasswordRecoveryContext();
  const form = useFormik({
    initialValues: {
      otp: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: any = {};

      if (
        values.password?.length === 8 &&
        values.confirmPassword != values.password
      )
        errors.confirmPassword = 'Senhas não conferem';
      return errors;
    },
    onSubmit: async (values) => {
      setEventTracking('signin_newpassword_submit_button_clicked', {
        document_number: hideDocumentNumber(username),
      });
      setIsLoading(true);
      try {
        await auth.forgotPasswordSubmit({
          username,
          code: values.otp,
          newPassword: values.password,
        });
        dispatchToast({
          type: 'success',
          content: 'Senha redefinida com sucesso!',
        });
        navigate('LOGIN');
      } catch (error) {
        ErrorBoundary.captureException(error, 'error', {
          document_number: hideDocumentNumber(username),
        });
        dispatchToast({
          type: 'error',
          content: 'Ops! Erro ao redefinir senha, favor tentar novamente.',
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  return { form, isLoading };
};

export const useResendPasswordRecoveryCode = () => {
  const updateMfaMethod = trpc.updateMfaMethod.useMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { username, recoveryChannel, setDeliveryDetails } =
    usePasswordRecoveryContext();
  const auth = useAuthContext();

  const resendCode = async (mfaMethod?: 'sms' | 'whatsapp') => {
    setIsLoading(true);
    setEventTracking('signin_newpassword_resendcode_button_cliked', {
      document_number: hideDocumentNumber(username),
      recovery_channel: recoveryChannel,
    });
    try {
      if (mfaMethod) {
        await updateMfaMethod.mutateAsync({
          mfaMethod,
          userIdentifier: username,
        });
      }
      const details = await auth.sendForgotPassword(username, recoveryChannel);
      setDeliveryDetails(details);
    } catch {
      dispatchToast({
        type: 'error',
        content: 'Ops! Erro ao reenviar código, favor tentar novamente!',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { resendCode, isLoading };
};
