import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { PageSignOut } from '../components';
import { LogoutGuard } from './logoutGuard';
import {
  PageLogin,
  PageAccessSelection,
  PageFirstAccess,
  PagePasswordRecovery,
  PageResendInvite,
  PageLoginRecovery,
  PageCustomFirstAccess,
  PageCustomFirstAccessQuiz,
} from '../pages';
import { AreYouNewHereStepOld } from 'src/pages/FirstAccess/components/AreYouNewHereStep';
import { PageMigration } from 'src/pages/Migration';
import { PageSSO } from '@/pages/SSO';
import { PageExchangeSSOCode } from '@/pages/SSO/exchange-sso-code.page';
import { ComplementaryDataPage } from '@/pages/ComplementaryData/complementary-data.page';
import { MobileCaptchaPage } from '@/pages/Captcha/MobileCaptchaPage';

const authRoutes = {
  MIGRATION: 'migration',
  LOGIN: 'login',
  FIRST_ACCESS: 'first-access',
  ACCESS_SELECTION: 'access-selection',
  PASSWORD_RECOVERY: 'password-recovery',
  LOGIN_RECOVERY: 'login-recovery',
  RESEND_INVITE: 'resend-invite',
  SSO: 'sso',
} as const;

type AuthRoute = keyof typeof authRoutes;

export const useAuthNavigation = () => {
  const _navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invitationToken = searchParams.get('invitationToken');
  const redirectUrl = searchParams.get('redirectTo');

  const navigate = (
    route: AuthRoute,
    options?: { replace?: boolean; keepParams?: boolean },
  ) => {
    let url = `/authentication/${authRoutes[route]}`;
    if (options?.keepParams === false) {
      _navigate(url, { replace: options?.replace !== false });
    } else {
      if (invitationToken) {
        url = url + `?invitationToken=${encodeURIComponent(invitationToken)}`;
      } else if (redirectUrl) {
        url = url + `?redirectTo=${encodeURIComponent(redirectUrl)}`;
      }
      _navigate(url, { replace: options?.replace !== false });
    }
  };

  return navigate;
};

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="authentication" element={<LogoutGuard />}>
          <Route path="access-selection" element={<PageAccessSelection />} />
          <Route path="first-access" element={<PageFirstAccess />} />
          <Route
            path="first-access/complementary-data"
            element={<ComplementaryDataPage />}
          />
          <Route
            path="firstAccess/custom/:companyName"
            element={<PageCustomFirstAccess />}
          />
          <Route
            path="firstAccess/custom/quiz"
            element={<PageCustomFirstAccessQuiz />}
          />
          <Route path="firstAccess/:code" element={<AreYouNewHereStepOld />} />
          <Route path="login" element={<PageLogin />} />
          <Route path="login-recovery" element={<PageLoginRecovery />} />
          <Route path="migration" element={<PageMigration />} />
          <Route path="password-recovery" element={<PagePasswordRecovery />} />
          <Route path="resend-invite" element={<PageResendInvite />} />
          <Route path="signOut" element={<PageSignOut />} />
          <Route path="sso">
            <Route index element={<PageSSO />} />
            <Route path="exchange-code" element={<PageExchangeSSOCode />} />
          </Route>
          <Route path="mobile-captcha" element={<MobileCaptchaPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
