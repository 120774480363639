export const cognitoConfig = {
  staging: {
    Auth: {
      endpoint: 'https://hros-auth.flashapp.dev/',
      region: 'us-east-1',
      userPoolId: 'us-east-2_lDhHlHCgu',
      userPoolWebClientId: '36603hvuv4730jglqu479hqhbd',
      oauth: {},
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
  },
  production: {
    Auth: {
      endpoint: 'https://hros-auth.flashapp.services/',
      region: 'us-east-1',
      userPoolId: 'us-east-2_OwzwM6gCp',
      userPoolWebClientId: '4r4ki1jqohppg2dko3uf7rvq13',
      oauth: {},
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
  },
};
