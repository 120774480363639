import { CodeDeliveryDetails } from '@/auth/models/CodeDeliveryDetails';
import { RECOVERY_CHANNEL } from '@auth/IAuthProvider';
import { createContext, useContext } from 'react';

export const PasswordRecoveryContext = createContext<{
  step: number;
  username: string;
  setUsername: (username: string) => void;
  setStep: (step: number) => void;
  recoveryChannel: RECOVERY_CHANNEL;
  setRecoveryChannel: (channel: RECOVERY_CHANNEL) => void;
  deliveryDetails: CodeDeliveryDetails;
  setDeliveryDetails: (details: CodeDeliveryDetails) => void;
}>({
  step: 0,
  username: '',
  recoveryChannel: 'EMAIL',
  setUsername: () => {},
  setStep: () => {},
  setRecoveryChannel: () => {},
  deliveryDetails: { deliveryChannel: undefined, destination: undefined },
  setDeliveryDetails: () => {},
});

export function usePasswordRecoveryContext() {
  return useContext(PasswordRecoveryContext);
}
